import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { ChangeEvent } from "react";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import { IBlock } from "../../../framework/src/IBlock";
export interface MyComponentState {
    checkboxes: { id: number; name: string; active: boolean,icon:string }[];
}

export interface VendorData {
    data: {
        catalogue: {
            data: {
                id: string;
                type: string;
                attributes: {
                    vendor: {
                        name: string;
                        country: string;
                        founded: string;
                        vendorURL: string;
                        platform: string[]
                    };
                    progress_percentage: number
                };
            };
        };
    };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    incrementProgress: (value: number) => void;
    openSaveModal:boolean;
    handleDataChanged:()=>void;
    handleOpenSaveModal:()=>void;
    handleDataSaved:()=>void;
    handleBack:()=>void;
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    isChecked: MyComponentState;
    vendorData: {
        name: string;
        country: string;
        founded: string;
        vendorURL: string;
        platform: string[];
    };
    updatedVendorData: {
        name: string;
        country: string;
        founded: string;
        vendorURL: string;
        platform: string[];
    };
    isSucess: boolean;
    isFeedback: boolean;
    feedbackEmail: string;
    feedbackMessage: string;
    isValidEmail: { valid: boolean, message: string },
    isLoad: boolean,
    progressPercentage: number
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class VendorsPageController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    updateVendorListingDataAPICallId: string = "";
    getCatlougeListAPICalledId: string = "";
    getGoodCompanysCallId: string = "";
    feedbackVendorId: string = "";
    emailReg: RegExp;


    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];
        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            isChecked: {
                checkboxes: [
                    { id: 1, name: 'Charity and Community', active: false,icon:"test" },
                ],
            },
            vendorData: {
                name: '',
                country: '',
                founded: '',
                vendorURL: '',
                platform: []
            },
            updatedVendorData: {
                name: '',
                country: '',
                founded: '',
                vendorURL: '',
                platform: []
            },
            isSucess: false,
            isFeedback: false,
            feedbackEmail: "",
            feedbackMessage: "",
            isValidEmail: { valid: false, message: "" },
            isLoad: false,
            progressPercentage: 0
            // Customizable Area End
        };

        // Customizable Area Start
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.emailReg = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.getCatlougeList();
        this.getGoodCompanys();
    }

    async receive(from: string, message: Message) {

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {
                switch (apiRequestCallId) {
                    case this.getCatlougeListAPICalledId:
                        this.handleVendorsLitsResponse(responseJson);
                        break;
                    case this.updateVendorListingDataAPICallId:
                        this.handleUpdateVendorListResponse(responseJson);
                        break;
                    case this.feedbackVendorId:
                        this.setState({ isFeedback: false });
                        break;
                    case this.getGoodCompanysCallId:
                        this.handleGoodCompanyRes(responseJson)
                        break;
                }
            }
        }
    }


    goToVendorAccount = () => {
        if(this.props.openSaveModal){
            this.props.handleOpenSaveModal()
        }
        else{
          this.props.handleBack();
        }
       
    }

    handleGoodCompanyRes = (response: any) => {
        this.setState({isChecked:{checkboxes:response.in_good_companies}})
    }
    handleCheckboxChange = (id: number) => (event: ChangeEvent<HTMLInputElement>) => {
        const updatedCheckboxes = this.state.isChecked.checkboxes.map((checkbox) =>
            checkbox.id === id ? { ...checkbox, active: event.target.checked } : checkbox
        );
        this.setState({
            isChecked: { checkboxes: updatedCheckboxes }
        });
        this.props.handleDataChanged();
    };

    handleUpdatedVendorData = () => {
        this.setState({ updatedVendorData: { ...this.state.vendorData } });
    };

    handleChangeName = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            updatedVendorData: { ...this.state.updatedVendorData, name: event.target.value }
        })
        this.props.handleDataChanged()
    }

    handleChangevendorURL = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            updatedVendorData: { ...this.state.updatedVendorData, vendorURL: event.target.value }
        })
        this.props.handleDataChanged()

    }

    handleChangeCountry = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            updatedVendorData: { ...this.state.updatedVendorData, country: event.target.value }
        })
        this.props.handleDataChanged()

    }

    handleChangeFoundedDate = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            updatedVendorData: { ...this.state.updatedVendorData, founded: event.target.value }
        })
        this.props.handleDataChanged()

    }
    getCatlougeList = async () => {
        this.setState({ isLoad: true })
        const categoryId = await getStorageData("category_id")
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCatlougeListAPICalledId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.catalogueAPiEndPoint}/${categoryId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    getGoodCompanys = async () => {
        const categoryId = await getStorageData("category_id")
        const headers = {
            token: await getStorageData("loginToken"),
            "Content-Type": configJSON.validationApiContentType,
        };

        const requestMessages = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getGoodCompanysCallId = requestMessages.messageId;

        requestMessages.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.content_goodcompany}?catalogue_id=${categoryId}`
        );
        requestMessages.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessages.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessages.id, requestMessages);
    };

    updateVendorListingData = async () => {
        this.props.handleDataSaved()
        const categoryId = await getStorageData("category_id")
        const header = {
            "Content-Type": configJSON.contentTypeApiAddDetail,
            token: await getStorageData("loginToken"),
        };

        let formdata : any = new FormData();
        formdata.append("[vendor][name]", this.state.updatedVendorData?.name);
        formdata.append("[vendor][vendorURL]", this.state.updatedVendorData?.vendorURL)
        formdata.append("[vendor][country]", this.state.updatedVendorData?.country)
        formdata.append("[vendor][founded]", this.state.updatedVendorData?.founded)
        let flag=true
        this.state.isChecked.checkboxes.forEach((checkbox) => {
            if (checkbox.active) {
                formdata.append("good_company_ids[]", checkbox.id);
                flag=false
            }
        });

        if(flag){
            formdata.append("good_company_ids[]",[]);
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.updateVendorListingDataAPICallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.vendorUpadteAPIEndpoint}/${categoryId}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchAPIMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    handleSubmitFeedbackVendors = async (event: any) => {

        let formdata = new FormData();
        if (!this.state.feedbackEmail) {
            this.setState({ isValidEmail: { valid: true, message: "required" } })
            return
        }
        else if (!this.validateEmailVendor(this.state.feedbackEmail)) {
            this.setState({ isValidEmail: { valid: true, message: "Email not valid" } })
            return
        } else {
            this.setState({ isValidEmail: { valid: false, message: "" } })
        }

        formdata.append("email", this.state.feedbackEmail);
        formdata.append("message", this.state.feedbackMessage);

        const header = {
            token: localStorage.getItem("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.feedbackVendorId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.feedbackAPI}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    // API Response
    handleVendorsLitsResponse = (responseJson: VendorData) => {
        const apiresponse = responseJson?.data?.catalogue?.data?.attributes?.vendor
        let platformData = apiresponse?.platform
        const updatedCheckboxes = this.state.isChecked.checkboxes.map((checkbox) => {
            if (platformData?.includes(checkbox.name)) {
                return { ...checkbox, active: true }
            } else {
                return checkbox
            }
        })
        this.setState({
            isChecked: { checkboxes: updatedCheckboxes }
        });
        if (responseJson.data.catalogue.data.attributes.progress_percentage) {
            this.setState({ progressPercentage: responseJson.data.catalogue.data.attributes.progress_percentage }, () => this.props.incrementProgress(this.state.progressPercentage))
        }
        if (apiresponse) {
            this.setState({
                isLoad: false,
                vendorData: {
                    ...this.state.vendorData,
                    name: apiresponse?.name,
                    country: apiresponse?.country,
                    founded: apiresponse?.founded,
                    vendorURL: apiresponse?.vendorURL,
                    platform: apiresponse?.platform

                }
            }, () => this.handleUpdatedVendorData()
            )
        }
    }

    handleUpdateVendorListResponse = (responseJson: any) => {
        const apiresponse = responseJson
        if (apiresponse) {
            this.setState({ isSucess: true })
            this.getCatlougeList()
        }
    }

    handleCloseSuccessDialog = () => {
        this.setState({ isSucess: false });
    }

    handleOpenFeedbackModalVendor = () => {
        this.setState({ isFeedback: true })
    }

    handleCloseFeedbackModalVendor = () => {
        this.setState({ isFeedback: false, isValidEmail: { valid: false, message: "" } })
    }

    handleFeedbackEmailChange = (event: any) => {
        const email = event.target.value;
        this.setState({ feedbackEmail: email })
    }

    handleFeedbackMessageChange = (event: any) => {
        const email = event.target.value;
        this.setState({ feedbackMessage: email })
    }

    validateEmailVendor = (email: string) => {

        const emailRegex = this.emailReg;

        return emailRegex.test(email);
    }

    // Customizable Area End
}
