import React from "react";

// Customizable Area Start
import {
    Grid, Typography, Divider, FormControlLabel, TextField,
    Radio, Button, Box, Dialog, Theme
} from "@material-ui/core";
import { ThemeProvider, createTheme, styled, withStyles } from '@material-ui/core/styles';
// Customizable Area End

// Customizable Area Start
import { closeIcon, rightCheck, cardIcon2, logoImage, searchIcon, personImage, downArrow } from './assets';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import PlanDetailController, {
    Props, configJSON
} from "./PlanDetailController";
import { loadStripe } from '@stripe/stripe-js';
import {
    Elements,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
    ElementsConsumer
} from '@stripe/react-stripe-js';
import AppBarPage from "../../../components/src/AppBar.web";
// Customizable Area End

// Customizable Area Start
const planTheme = createTheme({
    typography: {
        fontFamily: "Poppins",
        subtitle1: {
            margin: "20px 0px",
        },
        h6: {
            fontWeight: 500,
        },
        fontSize: 18
    },
    palette: {
        primary: {
            contrastText: "#fff",
            main: "#fff",
        },
    },
    overrides: {
        MuiSwitch: {
            colorSecondary: {
                "&$checked": {
                    color: "#FFF"
                }
            },
            root: {
                padding: 6
            },
            track: {
                opacity: 1,
                backgroundColor: "#8833FF",
                borderRadius: 40,
                "$checked$checked + &": {
                    opacity: 1,
                    backgroundColor: "#8833FF",
                }
            }
        }
    }
});

const newTextFont: any = {
    color: "black",
    fontWeight: 400,
    fontFamily: '"Poppins","Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '16px',
    fontSmoothing: 'antialiased',
    '::placeholder': {
        color: '#9B9B9B'
    }
}

const cardNumberStyle: any = {
    "&input": {
        fontWeight: 400,
        fontFamily: "Poppins",
        fontSize: 14, // Set your desired font size
        borderBottom: "1px solid grey",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#DDDDDD",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#DE76FF",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#DE76FF"
    },
    "&  .MuiFormHelperText-root.Mui-error": {
        backgroundColor: "#FFFFFF",
        margin: 0,
        paddingLeft: 10,
    },
    "& .MuiOutlinedInput-input": {
        padding: '14px'
    }
    // },
}
const text: any = {
    width: "100%",
    height: "1.19em",
    margin: 0,
    padding: "10px 0 7px",
    background: "none",
    boxSizing: "content-box",
    animationDuration: "10ms",
    borderBottom: "2px solid gray",
}
const stripePromise = loadStripe('pk_test_51Nmze6IePQ7KZBtF4T1TWd9AuveLbIuDY8is1lYOT3plAU7GTLlNOmJYQtZbVHq5tcfvoM7Js8MuBoCqBNCvURI700hqKvZoKX');
// Customizable Area End
export class PlanDetail extends PlanDetailController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes }: any = this.props;
        const { cardNumberErrorText, cardNameErrorText, cvvErrorText, expNumberErrorText, toastErrMsg } = this.state;
        const { passnotmatch, passmatch } = configJSON;
        const errMappings: { [key: string]: { stateProp: string; toastCheck: string | null } } = {
            cardNumberErrMsg: { stateProp: cardNumberErrorText, toastCheck: passnotmatch },
            cardNameErrMsg: { stateProp: cardNameErrorText, toastCheck: passmatch },
            cvvErrMsg: { stateProp: cvvErrorText, toastCheck: passmatch },
            expNumberErrMsg: { stateProp: expNumberErrorText, toastCheck: null },
        };

        const messages: { [key: string]: any } = {};
        for (const key in errMappings) {
            const { stateProp, toastCheck } = errMappings[key];
            messages[key] = stateProp || (toastCheck && toastErrMsg === configJSON[toastCheck]);
        }

        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <AppBarPage
                    // handleClick={this.goToLoginPage}
                    logoImg={logoImage}
                    serachIcon={searchIcon}
                    username={this.state.Username}
                    dummyicon={this.state.Username?.profile_image || personImage}
                    downarrow={downArrow}
                    data-testId={"signInBtn"}
                    goToHomePage={this.navigateToHomeScreen}
                    handleHeaderNavigation={this.navigateToVendor}
                    handleUserSignout={this.handleUserSignout}
                    showDirectoryPopup={this.state.showDirectoryPopup}
                    searchDirectoryData={this.state.searchDirectoryData}
                    handleSearchInput={this.handleSearchInput}
                    handleAdvancedSearch={this.handleAdvancedSearchNavigation}
                    handleSubmit={this.handleSubmitSearch}
                    handleDirectoryPopupClose={this.handleDirectoryPopupClose}
                />
                <ThemeProvider theme={planTheme}>
                    <Box className={classes.root}>
                        <Box className={classes.detailsBoxStyles}>
                            <Typography className={classes.detailsTextStyle}>Plan Details</Typography>
                            <Grid container justifyContent="center" alignItems="center" className={classes.gridContainerStyle} >
                                <Grid item xs={11} md={6} lg={6} >
                                    {this.state.isFeature ? <Box className={classes.boxStyle1}>
                                        <Typography className={classes.headingStyle}>Order Add On</Typography>
                                        <Divider style={{ backgroundColor: "#D9D9D9" }} />
                                        <Box className={classes.contentBoxStyle}>
                                            <Typography className={classes.headingStyle1}>
                                                {this.state.addonsData?.description}
                                            </Typography>
                                            {/* <Typography className={classes.headingStyle1}>{configJSON.mysoftware}</Typography> */}
                                        </Box>
                                    </Box> :
                                        <Box className={classes.boxStyle1}>
                                            <Typography className={classes.headingStyle}>{this.state?.selectedPlan?.selectedType} {this.state?.selectedPlan?.name}</Typography>
                                            <Divider style={{ backgroundColor: "#D9D9D9" }} />
                                            <Box className={classes.contentBoxStyle}>
                                                <Typography className={classes.headingStyle1}>
                                                    {this.state?.selectedPlan?.description}
                                                </Typography>
                                                {/* <Typography className={classes.headingStyle1}>{configJSON.mysoftware}</Typography> */}
                                            </Box>
                                        </Box>}
                                </Grid>
                                <Grid item xs={11} md={4} lg={4}>
                                    <Box className={classes.boxStyle2}>
                                        <Box className={classes.totalBoxStyle}>
                                            <Typography className={classes.totalPriceTextStyle}>
                                                Sub-Total:
                                            </Typography>
                                            {this.state.isFeature ? <Typography className={classes.totalPriceTextStyle}>$ {this.state.addonsData?.amount}</Typography>
                                                :
                                                <Typography className={classes.totalPriceTextStyle}>
                                                    {this.state?.selectedPlan?.price != "0.0" ? `$ ${this.state?.selectedPlan?.price}` : "FREE"}</Typography>}
                                        </Box>
                                        <Box className={classes.totalBoxStyle2}>
                                            <Typography className={classes.totalPriceTextStyle2}>Tax:</Typography>
                                            <Typography className={classes.totalPriceTextStyle2}>${this.state.taxValue}</Typography>
                                        </Box>

                                        {this.state.isCouponCode ?
                                    <>
                                        <Box className={classes.cuponRemoveBoxStyle}>
                                            <Typography className={classes.cuponTextStyle}>{this.state.couponText}</Typography>
                                            <Typography className={classes.removeTextStyle} onClick={this.handleRemoveCoupon}>Remove</Typography>
                                        </Box>

                                        <Box className={classes.cuponDiscpuntBox}>
                                            <Typography className={classes.cuponTextStyle2}>Coupon Discount</Typography>
                                            <Typography className={classes.cuponTextStyle2}>-${this.state.currentDiscount}</Typography>
                                        </Box>
                                    </>
                                    :
                                    <>
                                     <Box className={classes.cuponBoxStyle} style={{border:"unset"}}>
                                        <TextField
                                            placeholder={"Enter coupon code"}
                                            data-testid={"coup-code"}
                                            onChange={this.handleChangeCouponCode}
                                            value={this.state.couponText}
                                            style={{width:"100%"}}
                                            helperText={<Typography className={classes.errorStyle}>{this.state.errorCouponCode}</Typography>}
                                            InputLabelProps={{
                                                shrink: false,
                                            }}
                                            InputProps={{
                                                style: {
                                                    borderBottom: '1px solid #9B9B9B',
                                                    fontSize: '15px',
                                                    fontWeight: 400,
                                                    fontFamily: 'Poppins',
                                                    width: "100%",
                                                },
                                                endAdornment: (
                                                    <Button className={classes.buttonStyle} variant="contained" color="primary" onClick={this.handleApplyCoupon}>
                                                        Apply
                                                    </Button>
                                                ),
                                            }}
                                            className={classes.margin}
                                        />
                                        </Box>
                                    </>
                                }

                                        <Box className={classes.totalBoxStyle3}>
                                            <Typography className={classes.totalPriceTextStyle3}>
                                                Total:
                                            </Typography>
                                            <Typography className={classes.totalPriceTextStyle3}>
                                                {`$ ${this.state.grandTotal?.total}`}
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Grid>

                                <Grid item xs={11} md={11} lg={11} className={classes.gridItemStyle} >

                                    <Box className={classes.paymentBoxStyle}>
                                        <Elements stripe={stripePromise}  >
                                            <ElementsConsumer>
                                                {({ elements, stripe }: any) => (
                                                    <form  onSubmit={(e) => this.handleClickPaymentButton(e, elements, stripe)} className={classes.formDiv}   >
                                                        <>
                                                            <Box className={classes.boxStyle}>
                                                                <Typography className={classes.paymentText}> 4. Payment Option </Typography>
                                                                <Divider className={classes.dividerStyle} />

                                                                <Box>
                                                                    <Box className={classes.cardBoxStyle}>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Radio
                                                                                    checkedIcon={<span className={classes.checkedIcon} />}
                                                                                    disableRipple
                                                                                    className={classes.root1}
                                                                                    icon={<span className={classes.icon} />}
                                                                                    color="default"
                                                                                    value="card"
                                                                                    checked={this.state.isCardCheck === 'card'}
                                                                                    onChange={() => this.handleCardCheck("card")}
                                                                                />
                                                                            }
                                                                            style={{ flex: 1 }}
                                                                            label={
                                                                                <Box className={classes.labelBoxStyle}>
                                                                                    <span className={classes.label3Font} style={{ marginRight: "5px" }}> Card</span>
                                                                                    <img src={cardIcon2} alt="Card" className={classes.cardIconStyle2} style={{ marginRight: "5px", }} />
                                                                                </Box>
                                                                            }
                                                                        />
                                                                    </Box>
                                                                    {this.state.isCardCheck == "card" &&
                                                                        <Box className={classes.textfeildBox}>
                                                                            <CardTextField
                                                                                
                                                                                data-testid={"text-cardName"}
                                                                                type="text"
                                                                                placeholder={configJSON.textCardName}
                                                                                value={this.state.cardName}
                                                                                error={messages.cardNameErrMsg}
                                                                                className={classes.nameTextfeild}
                                                                                onChange={this.ChangeCardName}
                                                                                fullWidth
                                                                                helperText={
                                                                                    (messages.cardNameErrMsg) &&
                                                                                    <Box className={classes.errBoxStyle}>
                                                                                        {<ErrorOutlineOutlinedIcon className={classes.errIconStyle} color="error" />}
                                                                                        <Typography style={{
                                                                                            fontFamily: "Poppins",
                                                                                            fontSize: "14px",
                                                                                            color: "#D53333",
                                                                                            fontWeight: 400,
                                                                                        }}>
                                                                                            {messages.cardNameErrMsg}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                }
                                                                                InputProps={{
                                                                    
                                                                                    disableUnderline: true,
                                                                                    style: {
                                                                                        borderBottom: '1px solid #DDDDDD',
                                                                                        fontSize: '15px',
                                                                                        fontWeight: 400,
                                                                                        fontFamily: 'Poppins',
                                                                                        width:"94%"
                                                                                    },
                                                                                }}
                                                                            />
                                                                            <LandingPageStyle >
                                                                                <CardNumberElement options={{ style: { base: newTextFont, } }} />
                                                                            </LandingPageStyle>
                                                                            <Box className={classes.cvvBoxStyle}>
                                                                                <LandingPageStyle style={{ width: "49%", marginRight: "2%" }}>
                                                                                    <CardCvcElement options={{ style: { base: newTextFont, } }} />
                                                                                </LandingPageStyle >
                                                                                <LandingPageStyle style={{ width: "49%", }}>
                                                                                    <CardExpiryElement options={{ style: { base: newTextFont, } }} />
                                                                                </LandingPageStyle>
                                                                            </Box>
                                                                        </Box>}
                                                                </Box>
                                                            </Box>
                                                            <Box className={classes.boxStyle} style={{ display: "flex", alignItems: "flex-end" }} >
                                                                <Button
                                                                    style={{ height: "45px" }}
                                                                    type="submit"
                                                                    variant="contained"
                                                                    fullWidth
                                                                    className={classes.buttonStyle}
                                                                    data-testId={"proceedBtn"}
                                                                >
                                                                    {this.state?.selectedPlan?.price != "0.0" ? `Pay $ ${this.state.grandTotal?.total}` : "Pay"}
                                                                </Button>
                                                            </Box>
                                                        </>
                                                    </form>
                                                )}
                                            </ElementsConsumer>
                                        </Elements>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Dialog open={this.state.isModalOpen}
                                maxWidth="md"
                                PaperProps={{ className: classes.dialogStyles }}
                                // onClose={this.handleModalClose}
                                data-testId={"openModal"}
                            >
                                <Grid container>
                                    <Grid item xs={12} className={classes.cancelGridStyle}>
                                        <img
                                            data-testid={"closeinstruction"}
                                            src={closeIcon}
                                            onClick={this.handleModalClose}
                                            className={classes.closeIconStyle}
                                        />
                                    </Grid>
                                </Grid>

                                <Box className={classes.dialogBoxStyle}>
                                    <img
                                        src={rightCheck}
                                        className={classes.rightTickIcon}
                                    />
                                    <Typography className={classes.welcomeFontStyle}>Congratulations !</Typography>
                                    {!this.state.isFeature ? <Typography className={classes.welcomeFontStyle2}>Your plan has been updated to {this.state.selectedPlan?.name}</Typography>
                                    : 
                                    <Typography className={classes.welcomeFontStyleAdd}>You have successfully subscribed</Typography>}
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        className={classes.buttonStyle}
                                        onClick={this.handleGoBack}
                                    >
                                        Back to accounts
                                    </Button>

                                </Box>

                            </Dialog>
                            <Dialog
                                open={this.state.isAddons}
                                data-testid={"openSucessModal"}
                                PaperProps={{ className: classes.dialogStyle2 }}
                            >
                                <Grid container justifyContent="center" alignItems="center" >
                                    <Grid className={classes.addonHeader}>
                                        <Typography className={classes.headerTitle}>Order Add On</Typography>
                                        <Grid item xs={12} className={classes.closeIconGridStyle}>
                                            <img src={closeIcon}
                                                className={classes.closeIconStyleC}
                                                onClick={this.handleCancelAddon}
                                                data-testid={"closeIcon"}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid className={classes.addonContain}>
                                        <Box className={classes.rightScreen}>
                                            <Typography className={classes.leftText}>$ {this.state.addonsData?.amount}</Typography>

                                            <div style={{width:'470px',height:'130px',marginLeft:'20px',marginTop:'10px'}}>
                                            <Typography className={classes.rightText}>{this.state.addonsData?.description}</Typography>
                                                </div>
                                        </Box>
                                    </Grid>
                                    <Box className={classes.buttonBoxStyleMobile}>
                                        <Button variant="contained"
                                            className={classes.updateButton}
                                            onClick={this.handleSelectAddons}
                                            data-testid={"updateAddons"}
                                        >Buy Now</Button>

                                        <Button variant="contained"
                                            className={classes.cancelButton}
                                            onClick={this.handleCancelAddon}
                                            data-testid={"cancelAddons"}
                                        >{configJSON.cancelBtn}</Button>
                                    </Box>
                                </Grid>
                            </Dialog>
                        </Box>
                    </Box>
                </ThemeProvider>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const planStyles: any = (theme: Theme) => ({
    billingBlock: {
        background: "#F8F2FF",
        fontFamily: "Poppins",
        paddingBottom: 60
    },
    root: {
        flexGrow: 1,
        backgroundColor: '#f8f2ff',
        marginTop:"8%"
    },
    detailsBoxStyles: {
        paddingTop: '5%',
        paddingBottom: '5%'
    },
    detailsTextStyle: {
        marginLeft: '4%',
        fontSize: '24px',
        fontWeight: 400,
        color: '#262424',
        fontFamily: 'Poppins',
        padding: '10px'
    },
    cuponDiscpuntBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '15px 20px 10px 20px',
    },
    featureBlock: {
        background: "#FFF",
        marginTop: "-140px",
        padding: "80px 50px 15px",
    },
    featureItem: {
        display: "flex",
        padding: "0.5rem 0",
        gap: "1rem"
    },
    totalBoxStyle3: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10% 20px 10px 20px',
    },
    extraBlock: {
        padding: "15px 0 15px 110px",
    },
    cvvBoxStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    errorStyle: {
        fontSize: '14px',
        fontWeight: 400,
        color: 'red',
        fontFamily: 'Poppins',
    },
    cuponBoxStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '15px',
        margin: '15px 27px 20px 27px',
        border: '2px dashed #1B6BCC'
    },
    cuponRemoveBoxStyle:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '15px',
        margin: '15px 27px 20px 27px',
        border: '2px dashed #1B6BCC',
        borderRadius:'10px'
    },
    cuponTextStyle: {
        fontSize: '16px',
        fontWeight: 400,
        color: '#1B6BCC',
        fontFamily: 'Poppins',
        "@media (max-width: 900px )": {
            fontSize: '16px',
        }
    },

    removeTextStyle: {
        fontSize: '18px',
        fontWeight: 400,
        color: '#FF0000',
        fontFamily: 'Poppins',
    },
    buttonBlock: {
        padding: "60px 120px",
    },
    selectButton: {
        width: "100%",
        color: "#FFF",
        textTransform: "none" as "none",
        fontWeight: 400
    },
    cuponTextStyle2: {
        fontSize: '20px',
        fontWeight: 500,
        color: '#999999',
        fontFamily: 'Poppins',
        "@media (max-width: 900px )": {
            fontSize: '16px',
        }
    },
    paths: {
        fontSize: "24px",
        color: "#475569",
        cursor: "pointer"
    },
    planMainBlock: {
        background: "#F8F2FF",
        fontFamily: "Poppins",
        paddingBottom: 60
    },
    gridContainerStyle: {
        gap: '7%',
        width: 'auto'
    },
    boxStyle1: {
        background: '#FFFFFF',
        height: '389px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: "20px",
        },

    },
    headingStyle: {
        fontSize: '22px',
        fontWeight: 600,
        color: '#000000',
        fontFamily: 'Poppins',
        padding: '20px'
    },
    contentBoxStyle: {
        padding: '25px',
        marginTop: '2%'
    },
    headingStyle1: {
        fontSize: '18px',
        fontWeight: 400,
        color: '#000000',
        fontFamily: 'Poppins',
        lineHeight: '26px'
    },
    boxStyle2: {
        background: '#FFFFFF',
        height: '389px',
    },
    totalBoxStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '25px 20px 10px 20px',
    },
    totalPriceTextStyle: {
        fontSize: '24px',
        fontWeight: 700,
        color: '#000000',
        fontFamily: 'Poppins',
        "@media (max-width: 900px )": {
            fontSize: '18px',
        }
    },

    totalPriceTextStyle3: {
        fontSize: '23px',
        fontWeight: 700,
        color: '#000000',
        fontFamily: 'Poppins',
        "@media (max-width: 900px )": {
            fontSize: '18px',
        }
    },
    totalPriceTextStyle2: {
        fontSize: '24px',
        fontWeight: 400,
        color: '#8B8B8B',
        fontFamily: 'Poppins',
        "@media (max-width: 900px )": {
            fontSize: '18px',
        }
    },
    gridItemStyle: {
        marginTop: '15px',
        padding: '8px',
        [theme.breakpoints.down('md')]: {
            padding: "0",
        },
    },
    paymentBoxStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: '#FFFFFF'
    },
    boxStyle: {
        padding: '30px',
    },
    formDiv: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        [theme.breakpoints.down('sm')]: {
            display: "block",
        },

    },
    paymentText: {
        fontSize: '20px',
        fontWeight: 700,
        color: '#000000',
        fontFamily: 'Poppins',
    },

    dividerStyle: {
        backgroundColor: "#707070",
        width: '500px',
        marginTop: '15px',
        opacity: "10%",
        [theme.breakpoints.down('xs')]: {
            width: "unset",
        },
    },

    cardBoxStyle: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '10px',
        width: '100%'
    },
    icon: {
        borderRadius: "50%",
        width: 20,
        height: 20,
        boxShadow:
            "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
        backgroundColor: "#f5f8fa",
        backgroundImage:
            "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
        "$root.Mui-focusVisible &": {
            outline: "2px auto rgba(19,124,189,.6)",
            outlineOffset: 2
        },
        "input:hover ~ &": {
            backgroundColor: "#ebf1f5"
        },
        "input:disabled ~ &": {
            boxShadow: "none",
            background: "rgba(206,217,224,.5)"
        }
    },
    root1: {
        "&:hover": {
            backgroundColor: "transparent"
        }
    },
    checkedIcon: {
        borderRadius: "50%",
        backgroundColor: "#8833FF",
        backgroundImage:
            "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
        "&:before": {
            display: "block",
            width: 20,
            height: 20,
            backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
            content: '""'
        },
        "input:hover ~ &": {
            backgroundColor: "#8833FF"
        }
    },
    labelBoxStyle: {
        display: 'grid',
        gridTemplateColumns: '10fr 1fr',
        alignItems: 'center'
    },
    labelBoxStyle2: {
        display: 'grid',
        gridTemplateColumns: '1fr 2fr 1fr',
        alignItems: 'center',

        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            flexDirection: "column",
            alignItems: "start"
        },


    },
    buttonStyle: {
        fontFamily: 'Poppins',
        color: '#FFFFFF',fontSize: '16px' as const,fontWeight: 400,
        backgroundColor: '#B300ED',
        textTransform: 'initial' as const,borderRadius: '8px',
        padding: '8px 32px 8px 32px',

        '&:hover': {
            backgroundColor: "#B300ED",
        }
    },
    dialogStyles: {
        justifyContent: 'center',
        width: '529px',
        overflow: 'hidden',
        borderRadius: '8px 8px 32px 8px',
        backgroundColor: ' #FFFFFF',
        display: 'flex',
        alignItems: 'center',
    },

    label3Font: {
        fontSize: '21px' as const,
        fontWeight: 400,
        fontFamily: 'Poppins',
        color: '#3A3A3A',
    },
    totalBoxStyle2: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0px 20px 10px 20px',
    },

    dialogBoxStyle: {
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center',alignItems: 'center',
        gap: '20px',marginBottom: '40px'
    },

    rightTickIcon: {
        height: '75px',width: '75px'
    },
    cancelGridStyle: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
   
    welcomeFontStyle: {
        fontSize: '24px',
        fontWeight: 700,
        fontFamily: 'Inter',
        lineHeight: '32px',
        textAlign: 'center' as const,
        color: '#0F172A'
    },


    closeIconStyle: {
        cursor: 'pointer',
        height: '32px',
        width: '32px',
        padding: '10px'
    },
    closeIconStyleC: {
        cursor: 'pointer',
        width: "40",
        height: "60%",
        padding: '5px'
    },
    welcomeFontStyle2: {
        fontSize: '18px',
        fontWeight: 400,
        fontFamily: 'Inter',
        lineHeight: '26px',
        textAlign: 'center' as const,
        color: '#0F172A'
    },
    welcomeFontStyleAdd:{
        fontSize: '18px',
        fontWeight: 400,
        fontFamily: 'Inter',
        lineHeight: '26px',
        textAlign: 'center' as const,
        color: '#0F172A'
    },
    errBoxStyle: {
        display: "flex",
        alignItems: "center" as const,
        fontSize: "14px"
    },
    errIconStyle: {
        width: "20px",
        height: "20px",
    },
    dialogStyle2: {
        overflow: 'hidden',
        width: '700px',
        borderRadius: '8px 8px 32px 8px',
        backgroundColor: ' #FFFFFF',
    },
    addonContain: {
        width: "80%",
        marginLeft: "10%",
        display: "flex",
        marginTop: "30px",
        background: "#B300ED",
        borderRadius:"16px",
        justifyContent: "center" as const
    },

    leftScreen: {
        alignItems: "center" as const,
        width: "15%",
        display: "flex",
        background: "#FFF",

    },
    addonHeader: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between" as const,
        alignItems: "center" as const,
        padding: "15px 20px 10px 40px",
        borderBottom: "1px solid #D9D9D9",
    },
    headerTitle: {
        margin: 0,
        lineHeight: "25px",
        fontSize: "20px",
        fontWeight: 600,
        display: "flex",
        width: "80%",
        maxWidth:"80%",
        alignItems: "center" as const,
    },
    closeIconGridStyle: {
        width: "10%",
        maxWidth:"10%",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    leftText: {
        borderRadius: "50%",
        border: "1px solid #B300ED",
        height: "90%",
        display: "flex",
        alignItems: "center" as const,
        width: "38%",
        justifyContent: "center" as const,
        fontSize: "36px",
        fontWeight: "600",
        color: "#B300ED",
        marginLeft: "-25%",
        zIndex: 4,
        background: "#fff",
    },
    rightScreen: {
        display: "flex",
        width: "100%",
        maxWidth: "100%",
        background: "#B300ED",
        color: "#FFFFFF",
        fontSize: "13px",
        padding: "40px 35px",
        borderRadius: "15px",
    },
    rightText: {
        fontSize: "16px",textAlign: "center" as const,
        borderWidth:'10px',lineHeight:"34px"
    },
    buttonBoxStyleMobile: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center' as const,
        width: "65%",
        marginBottom: "30px",},
    updateButton: {
        marginTop: '3vw',
        fontSize: '18px' as const,fontWeight: 400,
        backgroundColor: '#B300ED',
        textTransform: 'initial' as const,
        fontFamily: 'Poppins',color: '#FFFFFF',
        borderRadius: '8px',
        minWidth: '44%',
        '&:hover': {
            backgroundColor: "#B300ED",
        },
        [theme.breakpoints.down('sm')]: {
            padding: "10px 20px",
        },
    },

    cancelButton: {
        marginTop: '3vw',
        fontSize: '18px' as const,
        fontWeight: 400,
        fontFamily: 'Poppins',
        color: '#B300ED',
        backgroundColor: '#FFFFFF',
        textTransform: 'initial' as const,
        border: '1px solid #B300ED',
        borderRadius: '8px',
        minWidth: '44%',
        '&:hover': {
            backgroundColor: "#FFFFFF",
        },
        [theme.breakpoints.down('sm')]: {
            padding: "10px 20px",
        },
    },

})

export const CardTextField = withStyles({
    root: {
        borderRadius: 8,
        marginLeft:"25px",
        marginTop:"10px",
        "& .MuiInputBase-input-283:focus": {
            backgroundColor: "transparent",
            outline: 'none',
        },
        "& input::placeholder": {
            fontWeight: 400,
            fontFamily: "Poppins",
            fontSize: "14",
            color: 'black'

        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#DDDDDD",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#DE76FF",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#DE76FF"
        },
        "&  .MuiFormHelperText-root.Mui-error": {
            backgroundColor: "#FFFFFF",
            margin: 0,
            paddingLeft: 10,
        },
        "& .MuiOutlinedInput-input": {
            padding: '14px'
        }
    },
})((props: any) => <TextField {...props} />);
export const LandingPageStyle = styled(Box)({
    outline: "none",
    borderBottom: "1px solid #DDDDDD",
    borderRadius: "4px",
    padding: "14px 0px",
    color: "#DDDDDD",
    fontWeight: 400,
    marginTop: "10px",
    marginLeft:"21px",
    '&:hover': {
        border: "unset",
    },
    '&:active': {
        border: "unset",
    },
    '&:focus': {
        border: "unset",
    }

})

// Customizable Area End

export default withStyles(planStyles)(PlanDetail);