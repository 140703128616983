import React from "react";

// Customizable Area Start
import {
    Box,
    Typography,
    Button,
    FormControl,
    MenuItem,
    Select,
    OutlinedInput,
    Theme,
    Backdrop,
    CircularProgress,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
// Customizable Area End

// Customizable Area Start
import EventsPageController, {
    Props,
    configJSON,
} from "./EventsPageController";
import { addmoreIcon, CloseICon, deleteIcon } from "./assets";
import CustomFormFeedback from "../../../components/src/CustomFormFeedback.web";
import CustomToast from "../../../components/src/CustomToast.web";
import CustomSaveButton from '../../../components/src/CustomSaveButton.web';
import CustomBackButton from '../../../components/src/CustomBackButton.web';


// Customizable Area End

export class EventsPage extends EventsPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes }: any = this.props
        // Customizable Area End
        return (
            // Customizable Area Start
            // Required for all blocks
            <Box className={classes.boxWrapperStyle}>
                <Backdrop className={classes.backdropStyle2} open={this.state.isLoadVideoData}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Box className={classes.textFeildBoxStyle}>
                    <Typography className={classes.fontStyle_1} data-testid={"events"}>{configJSON.events}</Typography>
                    <Box className={classes.boxWidth}>
                        {this.state.selectedEvent.map((item: any, index: number) => (
                            <Box key={index} className={classes.contentBoxStyle}>
                                <Box className={classes.categorylabelContainerSupplier}>
                                    <Typography data-testid={`eventlabel-${index + 1}`} className={classes.fontStyle2}>{`${configJSON.event_2} ${index + 1}`}</Typography>
                                    <Button disableRipple className={classes.categoryRemoveBtnSupplier} onClick={() => this.removeCategory(index)} data-test-id={`removeCategory-${index + 1}`}><img src={deleteIcon} alt="deleteicon" /></Button>
                                </Box>

                                <FormControl variant="outlined" className={classes.formcontrol}>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        fullWidth
                                        data-test-id={`selectEvent-${index + 1}`}
                                        value={item.id}
                                        placeholder={"select Event"}
                                        onChange={(event) => this.handleEventChange(event, index)}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: '300px', width: '250px',

                                                }
                                            },
                                            getContentAnchorEl: null,
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left",
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left",
                                            },
                                        }}
                                    >
                                        {this.state.getEvent?.map((event: any) => {
                                            return <MenuItem key={event.id} value={event.id}>
                                                {event.name}
                                            </MenuItem>
                                        })

                                        }
                                    </Select>
                                </FormControl>

                                <OutlinedInput
                                    id="outlined-adornment-weight"
                                    value={item.boothNumber}
                                    data-test-id={`handlebooth-${index + 1}`}
                                    onChange={(event) => this.handleEventChangeBoothNumber(event, item.id)}
                                    type="text"
                                    inputProps={{
                                        'aria-label': 'address',
                                        placeholder: configJSON.enterBoothNo,
                                        className: classes.placeholder,
                                    }}
                                    classes={{
                                        root: classes.outlinedInput,
                                        focused: classes.focused,
                                        notchedOutline: classes.notchedOutline,
                                        input: classes.inputText
                                    }}
                                />
                            </Box>
                        ))}

                        <Box
                            className={classes.addMoreBoxStyle}
                            data-testid={'add_more'}
                            onClick={this.handleAddMore}>
                            <img src={addmoreIcon} className={classes.iconStyle} />
                            <Typography className={classes.fontStyle3}>{configJSON.addMore}</Typography>
                        </Box>
                    </Box>
                </Box>

                <Box className={classes.boxFeild_3}>
                    <Box className={classes.buttonBox}>
                        <Typography className={classes.fontStyle5}>{configJSON.needHelp}</Typography>
                        <Typography className={classes.fontStyle4}>{configJSON.no}</Typography>
                        <Typography className={classes.fontStyle4} onClick={this.handleOpenFeedbackModal} data-test-id={'openFeedbackModal'}>{configJSON.yes}</Typography>
                    </Box>

                    <Box className={classes.buttonBoxStyle}>
                        <CustomBackButton data-testid="navigateToAccount" label={configJSON.backBtn} handleClick={this.goToVendorAccount} />
                        <CustomSaveButton label={configJSON.saveBtn} data-testid={"save_button"} handleClick={this.updateEventsListingData} />


                    </Box>
                </Box>
                <CustomToast open={this.state.isSucess} onClose={this.handleCloseSuccessDialog} />



                {/* Feedback--- */}
                <CustomFormFeedback data-test-id="closefeedback" isFeedback={this.state.isFeedback} handleCloseFeedbackModal={this.handleCloseFeedbackModal} handleSubmitFeedback={this.handleSubmitFeedbackEvent} handleFeedbackEmailChange={this.handleFeedbackEmailChange} handleFeedbackMessageChange={this.handleFeedbackMessageChange} closeIcon={CloseICon} />

            </Box>
        );
        // Customizable Area End
    }
}
// Customizable Area Start

export const userStyles: any = (theme: Theme) => ({
    boxWrapperStyle: {
        margin: '33px 46px 50px 45px',
        border: '2px solid #C8C9CA',
        padding: '40px 5% 40px 5%',
        borderRadius: '6px',
        [theme.breakpoints.down('md')]: {
            margin: "0",
        },
    },

    backdropStyle2: {
        zIndex: 1200,
        color: '#fff',
    },
    fontStyle_1: {
        fontSize: '20px',
        fontWeight: 700,
        color: '#2D2E2F',
        fontFamily: 'Poppins'
    },

    textFeildBoxStyle: {

        height: '400px',
        overflowY: "auto",
        paddingRight: '30px',


        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    categoryRemoveBtnSupplier: {
        cursor: "pointer",
        all: "unset",
    },
    categorylabelContainerSupplier: {
        display: "flex",
        alignItems: "center",
        marginBottom: "12px",
        justifyContent: "space-between",
    },
    boxWidth: {
        marginTop: '20px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        gap: '30px'
    },
    contentBoxStyle: {
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        gap: '10px'
    },

    fontStyle2: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#737373',
        fontFamily: 'Poppins'
    },

    fontStyle_4: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#000000',
        fontFamily: 'Poppins'
    },

    iconStyle: {
        height: '15px',
        width: '15px'
    },
    fontStyle3: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#6200EA',
        fontFamily: 'Poppins'
    },
    formcontrol: {
        width: "100%",
        borderRadius: '3px',
        backgroundColor: '#FAF5FF',
        '&.Mui-focused': {
            borderColor: '#DE76FF',
            outline: 'none'
        },
        '&.MuiOutlinedInput-notchedOutline': {
            borderColor: 'DDDDDD',
        },
    },

    addMoreBoxStyle: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: '5px',
        cursor: 'pointer'
    },
    boxFeild_3: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '15%',
        [theme.breakpoints.down('md')]: {
            flexDirection: "column",
            gap: "20px",
        }
    },
    buttonBox: {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        width: '45%',
        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
    },
    fontStyle4: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#6200EA',
        fontFamily: 'Poppins',
        cursor: "pointer",
    },
    fontStyle5: {
        fontSize: '16px',
        fontWeight: 500,
        color: '#262424',
        fontFamily: 'Poppins'
    },
    saveChangesBtn: {
        backgroundColor: "#B300ED",
        fontSize: "18px",
        fontWeight: 400,
        fontFamily: "Poppins",
        color: "#FFFFFF",
        width: '241px',
        height: "55px",
        textWrap: "nowrap",
        padding: '10px 0px',
        borderRadius: '8px',
        textTransform: 'initial' as const,
        '&:hover': {
            backgroundColor: "#B300ED",
        },
        [theme.breakpoints.down('md')]: {
            width: "100%",
        }
    },

    cancelBtn: {
        backgroundColor: "#FFFFFF",
        fontSize: "18px",
        fontWeight: 400,
        fontFamily: "Poppins",
        color: "#B300ED",
        width: '30%',
        borderColor: '#B300ED',
        borderRadius: '8px',
        padding: '10px 0px',
        textTransform: 'initial' as const,
        '&:hover': {
            backgroundColor: "#FFFFFF",
        },
        [theme.breakpoints.down('md')]: {
            width: "100%",
        }
    },

    buttonBoxStyle: {
        justifyContent: 'flex-end',
        display: 'flex',
        gap: '10px',

        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            width: "100%",
        }
    },

    outlinedInput: {
        '&$focused $notchedOutline': {
            borderColor: '#DE76FF',
        },
        '&:hover $notchedOutline': {
            borderColor: '#DE76FF',
        },
        backgroundColor: '#FAF5FF',
        borderRadius: '8px',
    },
    focused: {},
    notchedOutline: {
        borderWidth: '1px',
    },
    placeholder: {
        fontWeight: 400,
        fontSize: "14px",
        borderRadius: '8px',
        fontFamily: "Poppins",
    },

    inputText: {
        fontWeight: 400,
        fontSize: "16px",
        borderRadius: '8px',
        fontFamily: "Poppins",
        color: '#000000'
    },

    dialogStyles: {
        overflow: 'hidden',
        justifyContent: 'center',
        width: '529px',
        borderRadius: '8px 8px 32px 8px',
        backgroundColor: ' #FFFFFF',
        display: 'flex',
        alignItems: 'center',
    },

    outlinedInput_1: {
        '&$focused $notchedOutline': {
            borderColor: '#DE76FF',
        },
        '&:hover $notchedOutline': {
            borderColor: '#DE76FF',
        },
        borderRadius: '8px',
    },

    sendButton: {
        backgroundColor: "#B300ED",
        fontSize: "18px",
        fontWeight: 400,
        color: "#FFFFFF",
        fontFamily: "Poppins",
        width: '65%',
        padding: '5px 0px',
        borderRadius: '8px',
        textTransform: 'initial' as const,
        '&:hover': {
            backgroundColor: "#B300ED",
        }
    },

    dialogBoxStyle: {
        display: 'flex',
        flexDirection: 'column' as const,
        marginBottom: '40px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
        paddingTop: '30px'
    },

    rightTickIcon_6: {
        height: '65px',
        width: '65px',
    },

    buttonStyle_6: {
        fontSize: '16px' as const,
        fontWeight: 400,
        fontFamily: 'Poppins',
        color: '#FFFFFF',
        backgroundColor: '#B300ED',
        textTransform: 'initial' as const,
        borderRadius: '8px',
        padding: '8px 32px 8px 32px',
        '&:hover': {
            backgroundColor: "#B300ED",
        },
        [theme.breakpoints.down('sm')]: {
            width: "unset"
        },
    },

    welcomeFontStyle_6: {
        fontSize: '24px',
        fontWeight: 700,
        fontFamily: 'Poppins',
        lineHeight: '32px',
        textAlign: 'center' as const,
        color: '#0F172A'
    },

    contactUsDialog: {
        overflow: 'hidden',
        justifyContent: 'center',
        width: '600px',
        borderRadius: '8px 8px 32px 8px',
        backgroundColor: ' #FFFFFF',
        display: 'flex',
        marginTop: '5%',
        padding: '10px',
        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
    },

    cancelGridStyle: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end'
    },

    closeIconStyle: {
        cursor: 'pointer',
        height: '32px',
        width: '32px',
        padding: '5px'
    },

    contactUsFont: {
        fontWeight: 700,
        fontSize: "28px",
        fontFamily: "Poppins",
        color: '#0F172A',
        textAlign: 'center' as const
    },

    BoxScrollbar_1: {
        height: '100%',
        overflowX: 'hidden' as const,
        overflowY: 'scroll' as const,
        scrollbarColor: "#D6D6D6 transparent",
        "&::-webkit-scrollbar": {
            width: 4,
            height: 6,
            backgroundColor: "#D6D6D6",
            padding: '30px',
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#B300ED",
            minHeight: 24,
            minWidth: 14,
            borderRadius: 6,
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "#D6D6D6",
        },
        "&::-webkit-scrollbar-thumb:active": {
            background: "#B300ED",
        },
        "&::-webkit-scrollbar-thumb:focus": {
            background: "#B300ED",
        },

        "&::-webkit-scrollbar-thumb:hover": {
            background: "#B300ED",
        },
        "&::-webkit-scrollbar-corner": {
            backgroundColor: "#B300ED",
        },
    },

    contentBoxStyle_1: {
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        gap: '15px',
        padding: '30px 10% 30px 10%'
    },

    sendBtnBox_1: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '8%',
        marginBottom: '8%'
    },
});

export default withStyles(userStyles)(EventsPage)
// Customizable Area End