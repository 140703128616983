import React, { Component } from "react";
import {
    Box,
    Typography,
    Popover,
    Button,
    TextareaAutosize,
    Avatar,
    Divider,
    Theme
} from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { daysHelperText } from "../../blocks/customform4/src/PremiumListing.web";
import { downArrow, } from "../../blocks/customform4/src/assets";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Rating from '@material-ui/lab/Rating';
import moment from "moment";
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { borderRadius } from "react-select/src/theme";


type MyProps = {
    hideRatingForm?: any,
    isEdit?: any, handleReviewUpdate?: any,
    handleEditBtn?: any, userdetails?: any, anchorEl?: any,
    handleClose?: any, openFilter?: any, ratingVal?: any,
    reviewComment?: any, loadmore?: boolean, handleAccordionToggle?: any,
    classes?: any, reviewData?: any, handleRating?: any,
    handleReviewComment?: any, handleReviewSubmit?: any,
    accordionState?: string, handleLoadMore?: any,
    catalogue_name?: string
    handleClick?: any,
    handleFilterClick?: any
    filterValue?: string,
    id?:any,
    expanded?:boolean
};

export class Reviews extends Component<MyProps> {
    constructor(props: any) {
        super(props);
    }
    renderTopReview = (classes: any, data: any) => {
        return <>
            {data && data?.map((item: any) => {
                const { rating, created_at, comment, user_name, profile_image, user_email, review_reply } = item?.attributes
                const otherDate = moment(created_at);
                const currentDate = moment()
                const differenceInDays = currentDate.diff(otherDate, 'days');
                const dayText = daysHelperText(differenceInDays)
                const canEdit = this.props.userdetails?.email != null && this.props.userdetails?.email == user_email
                return (
                    <Box key={item?.id} className={`${classes.ratingWrapper}`} style={{borderRadius:'8px'}}>
                        <Box className={`${classes.ratingHeader} ${classes.marginTop20}`}>
                            <Box className={`${classes.ratingItem}`}>
                                <Rating
                                    className={`${classes.mr5}}`}
                                    name="simple-controlled"
                                    value={rating}
                                    data-testid={"starrating"}
                                    readOnly={true}
                                    size="small"
                                    classes={{
                                        iconFilled: `${classes.iconFilled}`,
                                    }}
                                />
                                <Typography className={classes.ratingScore}>{rating.toFixed(1)}</Typography>
                            </Box>
                            <Box>
                                <Typography className={`${classes.heading400} ${classes.ratingDaysColor}`}>{dayText}</Typography>
                            </Box>
                        </Box>
                        <Box className={classes.ratingItem}>
                            <Avatar
                                alt="Profile Picture"
                                src={profile_image}
                                className={classes.mr5}
                            />
                            <Typography className={classes.heading16}>{user_name}</Typography>
                        </Box>
                        <Box>
                            <Typography className={`${classes.heading400}`}>{comment}</Typography>
                        </Box>
                        {canEdit && <Box className={`${classes.marginTop30}`}>
                            <Button onClick={() => this.props.handleEditBtn(item)} data-testid="loadmore" variant="text" className={classes.categoryBtn}>
                                <Typography variant="body1">Edit Review</Typography>
                            </Button>
                        </Box>}
                        {review_reply?.data?.length > 0 ? review_reply?.data?.map((reply: any) => {
                            const { created_at, user_name } = reply?.attributes
                            const replyDate = moment(created_at);
                            const difference = currentDate.diff(replyDate, 'days');
                            const daystext = daysHelperText(difference)

                            return (<Box key={reply?.id} >
                                <div style={{
                                    borderLeft: "5px solid #B300ED",
                                    marginLeft: "40px",
                                    paddingLeft: "20px"
                                }}>
                                    <Box className={`${classes.ratingHeader} ${classes.marginTop20}`}>
                                        <Box className={`${classes.ratingItem}`}>
                                            <Typography className={classes.heading16}>{user_name}</Typography>
                                        </Box>
                                        <Box>
                                            <Typography className={`${classes.heading400} ${classes.ratingDaysColor}`}>{daystext}</Typography>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Typography className={`${classes.heading400}`}>{reply?.attributes?.comment}</Typography>

                                    </Box>
                                </div>

                            </Box>
                            )
                        }


                        ) : ""}
                        { }


                        <Box className={classes.marginTop20}>
                            <Divider />
                        </Box>
                    </Box>
                )
            })}
        </>
    }

    renderRatingFormItems = (classes: any) => {
        return (
            <Box>
                <Typography className={classes.reviewTitle2}>Overall Rating</Typography>

                <Rating
                    className={`${classes.marginTop20}`}
                    name="simple-controlled"
                    value={this.props.ratingVal}
                    data-testid={"starrating"}
                    onChange={(event, val) => this.props.handleRating(event, val)}
                    classes={{
                        iconFilled: `${classes.icon} ${classes.iconFilled}`,
                        iconEmpty: classes.icon,
                    }}
                />

                <TextareaAutosize
                    aria-label="empty textarea"
                    placeholder="Write Review"
                    className={`${classes.textArea} ${classes.marginTop30}`}
                    value={this.props.reviewComment}
                    onChange={this.props.handleReviewComment}
                    data-testid={"reviewComment"}
                />
            </Box>
        )
    }
    renderRatingForm = (classes: any, isEdit: any, hideForm: any) => {

        if (isEdit) {
            return <Box>
                {this.renderRatingFormItems(classes)}
                <Box className={`${classes.btnContainer} ${classes.marginTop10}`}>
                    <Button onClick={this.props.handleReviewUpdate} data-testid="postRating" className={classes.submitBtn} >Update</Button>
                </Box>
            </Box>
        }
        else if (hideForm) {
            return <div> </div>
        }
        else {
            return (<Box>
                <Typography className={classes.reviewTitle2}>Overall Rating</Typography>

                <Rating
                    className={`${classes.marginTop20}`}
                    name="simple-controlled"
                    value={this.props.ratingVal}
                    data-testid={"starrating"}
                    onChange={(event, val) => this.props.handleRating(event, val)}
                    classes={{
                        iconFilled: `${classes.icon} ${classes.iconFilled}`,
                        iconEmpty: classes.icon,
                    }}
                />

                <TextareaAutosize
                    aria-label="empty textarea"
                    placeholder="Write Review"
                    className={`${classes.textArea} ${classes.marginTop30}`}
                    value={this.props.reviewComment}
                    onChange={this.props.handleReviewComment}
                    data-testid={"reviewComment"}
                />
                <Box className={`${classes.btnContainer} ${classes.marginTop10}`}>
                    <Button onClick={this.props.handleReviewSubmit} data-testid="postRating" className={classes.submitBtn} >Submit</Button>
                </Box>
            </Box>)
        }

    }

    render() {
        const { classes, handleLoadMore, accordionState }: any = this.props
        return (
            <>
                <Box id={this.props.id} className={classes.accordionContainer}>

                    <Accordion onChange={() => this.props.handleAccordionToggle(accordionState)} expanded={this.props.expanded} style={{borderRadius:"8px"}}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.accordionHeading}>Reviews</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box className={classes.reviewSection}>
                                {this.renderRatingForm(classes, this.props.isEdit, this.props.hideRatingForm)}

                                <Box className={`${classes.marginTop30} ${classes.recentContainer}`}>
                                    <Box>
                                        <Typography className={`${classes.heading400} ${classes.reviewTitle3}`}>{this.props.reviewData?.length} Reviews</Typography>
                                    </Box>
                                    <Box>
                                        <Button disabled={!this.props.reviewData?.length} onClick={this.props.handleClick} data-testid="recentFilter" >
                                            <Typography style={{ textTransform: "capitalize" }} className={`${classes.recentText} ${classes.mr20} ${classes.reviewTitle3}`}>{this.props.filterValue}</Typography>
                                            <img height={9} src={downArrow} alt="downArrow" />
                                        </Button>
                                        <Popover
                                            id={"test"}
                                            data-testid="recentpopover"
                                            open={this.props.openFilter}
                                            anchorEl={this.props.anchorEl}
                                            onClose={this.props.handleClose}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                            }}
                                        >
                                            <Box style={{
                                                display: "flex",
                                                flexDirection: "column"
                                            }} >
                                                <Button style={{
                                                    margin: 5,
                                                    textTransform: "none"
                                                }} onClick={(e) => this.props.handleFilterClick(e, "recent")}   >Recent </Button>
                                                <Button style={{
                                                    margin: 6,
                                                    textTransform: "none"
                                                }} onClick={(e) => this.props.handleFilterClick(e, "ascending")} >Ascending </Button>
                                                <Button style={{
                                                    margin: 5,
                                                    textTransform: "none"
                                                }} onClick={(e) => this.props.handleFilterClick(e, "descending")} >Descending </Button>
                                            </Box>



                                        </Popover>
                                    </Box>
                                </Box>
                                <Divider />

                                <Box className={`${classes.marginTop30}`}>
                                    {this.props.reviewData && this.renderTopReview(classes, this.props.reviewData?.slice(0, 4))}
                                    { this.props.loadmore ?this.renderTopReview(classes, this.props.reviewData?.length>0 && this.props.reviewData?.slice(4)):""}

                                    <Box className={`${classes.marginTop30}`}>
                                        <Button onClick={handleLoadMore} data-testid="loadmore" variant="text" className={classes.categoryBtn}>
                                        <Typography variant="body1">Load more</Typography>
                                        </Button>
                                    </Box>
                                </Box>


                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </>
        );
    }
}
export const userStyles: any = (theme: Theme) => ({
    ratingHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
       
    },
    replyContainer: {
        borderLeft: "5px solid #B300ED",
        marginLeft: "40px",
        paddingLeft: "20px"
    },

    accordionContainer: {
        margin: "10px 0px"

    },
    accordionHeading: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: 18,
        fontStyle: "normal",
        fontWeight: 700
    },
    reviewSection: {
        padding: "20px 60px",
        width: "100%",
        [theme.breakpoints.down('sm')]: {
            display:"block",
            padding:"10px 0px"
         },
    },
    reviewTitle2: {
        fontFamily: "Poppins, sans-serif",
        fontSize: 18,
        fontWeight: 500,
    },
    marginTop20: {
        marginTop: 20
    },
    icon: {
        fontSize: 32,
        marginRight: 30,
        [theme.breakpoints.down('sm')]: {
            fontSize: "22px !important",
            marginRight: "15px !important",
    
         },
    },
    iconFilled: {
        color: "#6200EA"
    },
    textArea: {
        width: '100%',
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        padding: "8px 9px 10px 8px",
        color: "#000",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        minHeight: "144px",
        fontFamily: "Poppins",
        '&:hover': {
            borderColor: '#CBD5E1',
        },
        '&:focus': {
            borderColor: '#CBD5E1',
        },
    },

    marginTop30: {
        marginTop: 30
    },
    btnContainer: {
        display: "flex",
        justifyContent: "end"
    },
    marginTop10: {
        marginTop: 10
    },
    submitBtn: {
        display: "flex",
        fontFamily: "Poppins, sans-serif",
        width: '89px',
        height: "32px",
        padding: "6px 10px",
        justifyContent: "center",
        alignItems: "center",
        gap: "6px",
        flexShrink: 0,
        borderRadius: "8px",
        backgroundColor: "#B300ED",
        color: "white",
        fontWeight:400,
        fontSize:14,
        textTransform: "initial",
        '&:hover': {
            backgroundColor: "#B300ED",
        }
    },
    recentContainer: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 24
    },
    reviewTitle3: {
        color: "#334155",
        textTransform: "capitalize"
    },
    recentText: {
        fontFamily: "Poppins, sans-serif",
        fontSize: 14,
        fontWeight: 700,
        textTransform: "capitalize !important"
    },
    ratingScore: {
        fontFamily: 'Inter, sans-serif',
        fontSize: 14,
        fontStyle: "normal",
        fontWeight: 700,
    },
    ratingItem: {
        display: "flex",
        alignItems: "center",
        gap: 5
    },
    heading400: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: 14,
        fontStyle: "normal",
        fontWeight: 400
    },
    mr20: {
        marginRight: 20
    },
    heading16: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: 16,
        fontStyle: "normal",
        fontWeight: 700
    },
    ratingDaysColor: {
        color: "#64748B"
    },
    mr5: {
        marginRight: 5
    },
    categoryBtn: {
        padding: '0px',
        margin: '0px',
        justifyContent: 'flex-start',
        marginTop: '10px',
        display: 'flex',
        '& span p': {
            fontSize: '14px',
            fontWeight: 400,
            color: '#6200EA',
            fontFamily: 'Poppins, sans-serif',
            textTransform: 'initial',
        },
    },

})

export const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
})(MuiAccordion);

export const AccordionSummary = withStyles({
    root: {
        paddingLeft: 56,
        borderRadius: 6,
        border: "1px solid #D9D9D9",
        backgroundColor: '#F1E8FF',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },

    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
})(MuiAccordionSummary);



export const AccordionDetails = withStyles((theme:any) => ({
    root: {
        padding: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            padding:10
         },
    },
}))(MuiAccordionDetails);


export default withStyles(userStyles)(Reviews)