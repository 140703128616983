import React, { Component } from "react";
import {
    Box,
    Grid,
    Typography,
} from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

type MyProps = { classes?: any, handleAccordionToggle?: any, description?: string, markets?: any, accordionState?: string,expanded?:boolean };

export class ListingAbout extends Component<MyProps> {
    constructor(props: any) {
        super(props);
    }

    render() {
        const { classes, description, markets, accordionState }: any = this.props
        return (
            <>
                <Box className={classes.accordionContainer}>
                    <Accordion data-testid="servicesaboutOpen" onChange={() => this.props.handleAccordionToggle(accordionState)} expanded={this.props.expanded}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.accordionHeading}>About</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{marginBottom:"-2rem"}}>
                            <Box className={classes.integrationContainer}>
                                <Box>
                                    <Typography className={`${classes.heading400}`} style={{marginBottom:"0.5rem"}}>
                                        {description}
                                    </Typography>
                                </Box>

                                <Box>
                                    <Typography className={`${classes.heading16} `}>
                                        Markets
                                    </Typography>
                                    <Box style={{marginLeft:"12px"}}>
                                     
                                        <ul className={classes.marketUl}>
                                            {this.props.markets && this.props.markets.map((market: any) => (
                                             
                                                <li key={market?.country_name}>
                                                    <Typography className={`${classes.heading400}`} style={{marginLeft:"12px",
                                                     maxWidth: "100%", /* Prevents overflow */
                                                    //  overflowWrap:" break-word",
                                                     wordWrap: "break-word",

                                                    }}>
                                                        {market?.country_name}
                                                    </Typography>
                                                </li>
                                               
                                            ))}

                                        </ul>
                                      



                                    </Box>
                                </Box>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </>
        );
    }
}



export const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
})(MuiAccordion);

export const AccordionSummary = withStyles({
    root: {
        paddingLeft: 56,
        borderRadius: 6,
        border: "1px solid #D9D9D9",
        backgroundColor: '#F1E8FF',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },

    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
})(MuiAccordionSummary);



export const AccordionDetails = withStyles((theme:any) => ({
    root: {
        padding: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            padding:10
         },
    },
}))(MuiAccordionDetails);

export const userStyles: any = (theme: any) => ({

    accordionContainer: {
        margin: "10px 0px"

    },
    accordionHeading: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: 18,
        fontStyle: "normal",
        fontWeight: 700
    },
    heading400: {
        wordBreak: 'break-word', 
        overflowWrap: 'break-word',
        fontFamily: 'Poppins, sans-serif',
        fontSize: 14,
        fontStyle: "normal",
        fontWeight: 400,
        
    },
    integrationContainer: {
        padding: "20 32",
        marginBottom: 10,
    },
    heading16: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: 16,
        fontStyle: "normal",
        fontWeight: 700
    },
    marketUl: {
       
        display: "flex",
        alignItems: "baseline",
        paddingLeft: 0,
        marginTop: "5px",
        gap:"2rem",
        "& li": {
            whiteSpace:"nowrap"
        },
        [theme.breakpoints.down('sm')]: {
           display:"block"
         },
    },
    
        
    

})

export default withStyles(userStyles)(ListingAbout)