import React from "react";

import {
  Tabs,
  Tab,
  Avatar,
  // Customizable Area Start
  Box,
  withStyles,
  Theme,
  LinearProgress,
  Typography,
  Dialog,
  OutlinedInput,
  Button,
  Backdrop,
  CircularProgress,
  Grid,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VendorsPage from "./VendorsPage.web";
import ServicesPage from "./ServicesPage.web";
import IntegrationsPage from "./IntegrationsPage.web";
import VideosPage from "./VideosPage.web";
import EventsPage from "./EventsPage.web";
import ContactsPage from "./ContactsPage.web";
import AppBarPage from "../../../components/src/AppBar.web"
import { CloseICon, camerIconEdit, logoImage, searchIcon, dummyIcon, downArrow, errorIcon } from "./assets";



const styles1: any = (theme: Theme) => ({
  labelPosition: {
    position: 'absolute',
    top: '-24px',
    [theme.breakpoints.down('md')]: {
      transform: "translate(-40px,0)"
    },
  },
  progressBarDummy: {
    backgroundColor: "#B300ED"
  },
  remainingProgressBar: {
    backgroundColor: "#A09B9B33"
  }
});

const LinearProgressWithLabel = (({ classes, value }: { value: number; classes: Record<string, string> }) => (
  <Box sx={{ position: 'relative' }}>
    <Box className={classes.labelPosition} style={{ left: `${value}%` }}>
      <Typography variant="body2">{`${Math.round(value)}%`}</Typography>
    </Box>
    <Box sx={{ width: '100%' }}>
      <LinearProgress variant="determinate" value={value} classes={{
        root: classes.remainingProgressBar,
        bar: classes.progressBarDummy,
      }} />
    </Box>
  </Box>
));

const StyledLinearProgressWithLabel = withStyles(styles1)(LinearProgressWithLabel);



const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Poppins",
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

export const styles: any = (theme: Theme) => ({
  mainWapper: {
    maxWidth: "100vw",
    flexShrink: 0,
    background: "#F8F2FF",
    display: "flex",
    justifyContent: "center",
    fontFamily: "Poppins",
    paddingTop: "24px",
    paddingBottom: "83px",
    marginTop: "8.5%",
  },
  editText: {
    position: 'absolute' as const,
    top: "50%",
    color: "#fff",
    left: "24%",
    textWrap: "noWrap",
    fontWeight: 700
  },
  subWapper: {
    flexShrink: 0,
    borderRadius: "6px",
    background: "#FFF",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",

    [theme.breakpoints.up('md')]: {
      width: "1309px",
    },

    [theme.breakpoints.down('md')]: {
      width: "100%",
    },

    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },


  },
  progressBar: {
    // width: "100%",
    margin: "35px auto 0",
    // width: "1220px",

    [theme.breakpoints.up('md')]: {
      width: "1220px",
    },

    [theme.breakpoints.down('md')]: {
      width: "100%",
    },

    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },


  },
  tabBarDesign: {
    width: "93%",
    flexShrink: 0,
    borderRadius: "6px",
    border: "1px solid #D9D9D9",
    background: "#FFF",
    margin: "35px auto 0",
    marginTop: "0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "37px",

    [theme.breakpoints.up('md')]: {
      width: "1220px",
    },

    [theme.breakpoints.down('md')]: {
      width: "100%",
    },

    [theme.breakpoints.down('sm')]: {
      width: "100%",
      paddingTop: "10px",

    },
  },
  tabLogo: {
    marginLeft: "42px",
    marginRight: "36px",
    [theme.breakpoints.down('md')]: {
      marginLeft: "10px",
      marginRight: "26px",

    },

  },
  tabHeader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    position: "relative"
  },
  ProfileDesign: {
    position: "absolute",
    right: "24px",
    top: "-12px",
    cursor: "pointer",
    [theme.breakpoints.down('sm')]: {
      right: "12px",
      top: "-4px",
    },
  },
  tabItems: {
    width: "100%",
    height: "95px",
    display: "flex",
    alignItems: "end",
    [theme.breakpoints.down('md')]: {
      height: "unset",
    },
  },
  tab: {
    textTransform: 'none',
    margin: "0",
    fontFamily: "Poppins",
    fontSize: "18px",
    lineHeight: "26px",
    color: "#000000",
    fontWeight: 400,
    height: "50px",
    minWidth: "unset",
    // padding: "0 20px",
    flex: "1",
  },
  tabs: {
    margin: "0 35px",
    width: "100%",
    justifyContent: "space-between",

    '& .MuiTabs-flexContainer': {
      justifyContent: "space-between",
    },
    '& .Mui-selected': {
      color: "#B300ED",
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "24px",

    },
    [theme.breakpoints.down('md')]: {
      '&  > div': {
        display: 'flex',
        overflowX: 'scroll !important',
      },
      '&  > div::-webkit-scrollbar': {
        display: "none",
      },
      margin: "0 10px",


    }
  },
  avatarImage: {
    display: "flex",
    width: "110px",
    height: "110px",
    justifyContent: "center",
    alignItems: "center",
    border: "4px solid #F0E5FF",
    '& img': {
      objectFit: "fill" as const
    }
  },
  tabBarHeader: {
    color: "#000",
    fontFamily: "Poppins",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "24px"
  },
  aboutContainer: {
    width: "1287px",
    margin: "20px auto",
    padding: "0px 100px"
  },
  progressBarDummy: {
    backgroundColor: "#B300ED"
  },
  remainingProgressBar: {
    backgroundColor: "#A09B9B33"
  },
  contactUsDialog: {
    overflow: 'hidden',
    justifyContent: 'center',
    width: '500px',
    borderRadius: '8px 8px 32px 8px',
    backgroundColor: ' #FFFFFF',
    display: 'flex',
    marginTop: '5%',
    padding: '10px',
    [theme.breakpoints.down('md')]: {
      width: "100%",
    },
  },

  cancelGridStyle: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },

  closeIconStyle: {
    cursor: 'pointer',
    height: '32px',
    width: '32px',
    padding: '5px'
  },


  avatarWrapper: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginRight: '30px',
    padding: '10px'
  },
  avatar: {
    height: '156px',
    width: '156px',
    opacity: "0.7",
  },
  backdrop: {
    zIndex: 1200,
    color: '#fff',
  },
  contactUsFont: {
    fontWeight: 700,
    fontSize: "28px",
    fontFamily: "Poppins",
    color: '#0F172A',
    textAlign: 'center' as const
  },

  BoxScrollbar_1: {
    height: '100%',
    overflowX: 'hidden' as const,
    overflowY: 'auto' as const,
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'center',
    alignItems: 'center',
    gap: '30px',
    scrollbarColor: "#D6D6D6 transparent",

    "&::-webkit-scrollbar-thumb:hover": {
      background: "#B300ED",
    },

    "&::-webkit-scrollbar-thumb:active": {
      background: "#B300ED",
    },
    "&::-webkit-scrollbar-thumb:focus": {
      background: "#B300ED",
    },

    "&::-webkit-scrollbar-corner": {
      backgroundColor: "#B300ED",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#D6D6D6",
    },
    "&::-webkit-scrollbar": {
      height: 6,
      width: 4,
      padding: '30px',
      backgroundColor: "#D6D6D6",
    },
    "&::-webkit-scrollbar-thumb": {
      minHeight: 24,
      minWidth: 14,
      borderRadius: 6,
      background: "#B300ED",
    },
  },

  contentBoxStyle_1: {
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'center',
    gap: '15px',
    padding: '30px 10% 30px 10%'
  },

  outlinedInput: {
    '&$focused $notchedOutline': {
      borderColor: '#DE76FF',
    },
    '&:hover $notchedOutline': {
      borderColor: '#DE76FF',
    },
    backgroundColor: '#FAF5FF',
    borderRadius: '8px',
  },
  focused: {},
  notchedOutline: {
    borderWidth: '1px',
  },
  placeholder: {
    fontWeight: 400,
    fontSize: "14px",
    borderRadius: '8px',
    fontFamily: "Poppins",
  },

  inputText: {
    fontWeight: 400,
    fontSize: "16px",
    borderRadius: '8px',
    fontFamily: "Poppins",
    color: '#000000'
  },

  buttonBoxStyle: {
    width: '70%',
    marginBottom: '30px'
  },

  wrapper: {
    position: 'relative' as const,
  },

  camera: {
    position: 'absolute' as const,
    top: "40%",
    left: "50%",
    transform: 'translate(-50%,-50%)',

  },

  acceptBtn: {
    backgroundColor: "#B300ED",
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: "Poppins",
    color: "#FFFFFF",
    padding: '10px 0px',
    borderRadius: '8px',
    textTransform: 'initial' as const,
    '&:hover': {
      backgroundColor: "#B300ED",
    }
  },

  custom_subWapper_2_Customform: {

    [theme.breakpoints.down('md')]: {
      padding: '1% 4%',
      borderRadius: '6px',
    },
  },
  closeIconStyleSupplier: {
    height: '32px',
    padding: '5px',
    width: '32px',
    cursor: 'pointer',
  },

  rightTickIcon: {
    height: '75px',
    width: '75px'
  },
  welcomeFontStyle: {
    fontSize: '24px',
    fontWeight: 700,
    fontFamily: 'Inter',
    lineHeight: '32px',
    textAlign: 'center' as const,
    color: '#0F172A'
  },

  buttonStyle: {
    fontSize: '16px' as const,
    fontWeight: 400,
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    backgroundColor: '#B300ED',
    textTransform: 'initial' as const,
    borderRadius: '8px',
    padding: '8px 32px 8px 32px',
    '&:hover': {
      backgroundColor: "#B300ED",
    }
  },

  dialogStyles: {
    overflow: 'hidden',
    justifyContent: 'center',
    width: '529px',
    borderRadius: '8px 8px 32px 8px',
    backgroundColor: ' #FFFFFF',
    display: 'flex',
    alignItems: 'center',
  },

  dialogBoxStyle_2: {
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px',
    marginBottom: '40px',
    width: '70%'
  },

  cancelGridStyleSupplier: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
})


import Customform4Controller, {
  Props,
} from "./Customform4Controller";
import About from "./About.web";
import Feature from "./Feature.web";
import { CustomToast } from "../../../components/src/CustomToast.web";

// Customizable Area End

class Customform4 extends Customform4Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme} >
        <Backdrop className={classes.backdrop} open={this.state.isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <AppBarPage
          logoImg={logoImage}
          serachIcon={searchIcon}
          username={this.state.Username}
          dummyicon={dummyIcon}
          downarrow={downArrow}
          goToHomePage={this.homePageNavigation}
          handleHeaderNavigation={this.headerNavigation}
          handleUserSignout={this.signoutUser}
          data-testId={"signInBtn"}
          searchText={this.state.searchTermProp}
          showDirectoryPopup={this.state.showDirectoryPopup}
          searchDirectoryData={this.state.searchDirectoryData}
          handleAdvancedSearch={this.handleAdvancedSearch}
          handleSubmit={this.handleSubmitSearch}
          handleSearchInput={this.handleSearchInput}
          handleDirectoryPopupClose={this.handleDirectoryPopupClose}
        />

        <Box className={classes.mainWapper}>
          <Box className={classes.subWapper}>

            <Box className={classes.custom_subWapper_2_Customform}>

              <Box className={classes.progressBar}>
                <StyledLinearProgressWithLabel value={this.state.progressVal} />
              </Box>

              <Box className={classes.tabBarDesign}>
                <Box className={classes.tabHeader}>
                  <Box className={classes.ProfileDesign} data-test-id={'openeditmodal'} onClick={this.handleOpenEditModal}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M20.7085 5.63249C21.0985 6.02249 21.0985 6.65249 20.7085 7.04249L18.8785 8.87249L15.1285 5.12249L16.9585 3.29249C17.1454 3.10523 17.399 3 17.6635 3C17.9281 3 18.1817 3.10523 18.3685 3.29249L20.7085 5.63249ZM2.99854 20.5025V17.4625C2.99854 17.3225 3.04854 17.2025 3.14854 17.1025L14.0585 6.19249L17.8085 9.94249L6.88854 20.8525C6.79854 20.9525 6.66854 21.0025 6.53854 21.0025H3.49854C3.21854 21.0025 2.99854 20.7825 2.99854 20.5025Z" fill="#0F172A" />
                    </svg>
                  </Box>

                  <Box className={classes.tabLogo}>
                    <Avatar alt="A&J" src={this.state.imagePreview ? this.state.imagePreview : "/static/images/avatar/1.jpg"} className={classes.avatarImage} />
                  </Box>
                  <Box>
                    <h1 className={classes.tabBarHeader}>{this.state.title}</h1>
                  </Box>
                </Box>
                <Box className={classes.tabItems}>
                  <Tabs
                    value={this.state.value}
                    onChange={this.handleChange}
                    data-test-id="tabs"
                    TabIndicatorProps={{ style: { height: '2px', backgroundColor: "#B300ED" } }}
                    className={classes.tabs}
                  >
                    <Tab value={0} label="About" {...this.a11yProps(0)} className={classes.tab} />
                    <Tab value={1} label="Features" {...this.a11yProps(1)} className={classes.tab} />
                    <Tab value={2} label="Vendors" {...this.a11yProps(2)} className={classes.tab} />
                    <Tab value={3} label="Service"  {...this.a11yProps(3)} className={classes.tab} />
                    <Tab value={4} label="Integrations" {...this.a11yProps(4)} className={classes.tab} />
                    <Tab value={5} label="Videos" {...this.a11yProps(5)} className={classes.tab} />
                    <Tab value={6} label="Events" {...this.a11yProps(6)} className={classes.tab} />
                    <Tab value={7} label="Contacts"  {...this.a11yProps(7)} className={classes.tab} />
                  </Tabs>

                </Box>
              </Box>

            </Box>
            {this.state.value == 0 && <About handleDataSaved={this.handleDataSaved} handleDataChanged={this.handleDataChanged} navigation={this.props.navigation} id={""} data-test-id="about" incrementProgress={this.incrementProgress} categoryId={this.state.categoryIdCustomform} />}
            {this.state.value == 1 && <Feature handleBack={this.handleBack} openSaveModal={this.state.formDataChanged} handleOpenSaveModal={this.handleOpenSaveModal} handleDataSaved={this.handleDataSaved} handleDataChanged={this.handleDataChanged} navigation={this.props.navigation} id={""} incrementProgress={this.incrementProgress} />}
            {this.state.value == 2 && <VendorsPage handleBack={this.handleBack} openSaveModal={this.state.formDataChanged} handleOpenSaveModal={this.handleOpenSaveModal} handleDataSaved={this.handleDataSaved} handleDataChanged={this.handleDataChanged} navigation={this.props.navigation} id={""} incrementProgress={this.incrementProgress} />}
            {this.state.value == 3 && <ServicesPage handleBack={this.handleBack} openSaveModal={this.state.formDataChanged} handleOpenSaveModal={this.handleOpenSaveModal} handleDataSaved={this.handleDataSaved} handleDataChanged={this.handleDataChanged} navigation={this.props.navigation} id={""} incrementProgress={this.incrementProgress} />}
            {this.state.value == 4 && <IntegrationsPage handleBack={this.handleBack} openSaveModal={this.state.formDataChanged} handleOpenSaveModal={this.handleOpenSaveModal} handleDataSaved={this.handleDataSaved} handleDataChanged={this.handleDataChanged} navigation={this.props.navigation} id={""} incrementProgress={this.incrementProgress} />}
            {this.state.value == 5 && <VideosPage handleBack={this.handleBack} openSaveModal={this.state.formDataChanged} handleOpenSaveModal={this.handleOpenSaveModal} handleDataSaved={this.handleDataSaved} handleDataChanged={this.handleDataChanged} navigation={this.props.navigation} id={""} incrementProgress={this.incrementProgress} />}
            {this.state.value == 6 && <EventsPage handleBack={this.handleBack} openSaveModal={this.state.formDataChanged} handleOpenSaveModal={this.handleOpenSaveModal} handleDataSaved={this.handleDataSaved} handleDataChanged={this.handleDataChanged} navigation={this.props.navigation} id={""} incrementProgress={this.incrementProgress} />}
            {this.state.value == 7 && <ContactsPage handleBack={this.handleBack} openSaveModal={this.state.formDataChanged} handleOpenSaveModal={this.handleOpenSaveModal} handleDataSaved={this.handleDataSaved} handleDataChanged={this.handleDataChanged} navigation={this.props.navigation} id={""} incrementProgress={this.incrementProgress} />}
          </Box>
        </Box>

        <Dialog
          open={this.state.isOpenEditModal}
          onClose={this.handleCloseOpenEditModal}
          maxWidth="md"
          PaperProps={{ className: classes.contactUsDialog }}
          data-test-id="closeeditmodal"
        >

          <Box className={classes.cancelGridStyle}>
            <img
              src={CloseICon}
              className={classes.closeIconStyle}
              onClick={this.handleCloseOpenEditModal}
              data-test-id={'close_modal'}
            />
          </Box>
          <Box className={classes.BoxScrollbar_1}>

            <Typography className={classes.contactUsFont}>{"Update Name and Picture"}</Typography>
            <label htmlFor="upload-screenshot" className={classes.wrapper}>
              <Avatar alt="Tony" src={this.state.imagePreview ? this.state.imagePreview : "/static/images/avatar/1.jpg"} className={classes.avatar} />
              <img alt="Tony" src={camerIconEdit} className={classes.camera} />
              <Typography className={classes.editText}>Edit Picture</Typography>
            </label>

            <input
              type="file"
              style={{ display: "none" }}
              accept="image/*"
              onChange={this.onScreenshotUpload}
              data-testid={"inputref"}
              id="upload-screenshot"
            />

            <OutlinedInput
              id="outlined-adornment-weight"
              classes={{
                root: classes.outlinedInput,
                focused: classes.focused,
                input: classes.inputText,
                notchedOutline: classes.notchedOutline,
              }}
              inputProps={{
                'aria-label': 'address',
                className: classes.placeholder,
                placeholder: 'Name',
              }}
              style={{ width: '80%' }}
              value={this.state.title}
              onChange={this.handleChangeTitle}
              data-test-id={"title"}

            />
            <Box className={classes.buttonBoxStyle}>
              <Button
                variant="contained"
                className={classes.acceptBtn}
                onClick={this.handleSubmit}
                data-test-id={"saveBtn"}
                fullWidth
              >
                Save
              </Button>
            </Box>
          </Box>
        </Dialog>

        <Dialog
          open={this.state.openSaveModal}
          maxWidth="md"
          onClose={this.handleCloseSaveModal}
          data-testId={"openErrorModalDialpg"}

          PaperProps={{ className: classes.dialogStyles }}
        >
          <Grid container>
            <Grid item xs={12} className={classes.cancelGridStyleSupplier}>
              <img
                alt="deleteImg"
                data-testid={"closeinstruction"}

                src={CloseICon}
                className={classes.closeIconStyleSupplier}
                onClick={this.handleCloseSaveModal}
              />

            </Grid>
          </Grid>

          <Box className={classes.dialogBoxStyle_2}>
            <img
              src={errorIcon}
              alt="deleteIcon-1"
              className={classes.rightTickIcon}

            />

            <Typography className={classes.welcomeFontStyle}>Save all changes before proceeding further.</Typography>

            <Button
              variant="contained"
              onClick={this.handleCloseSaveModal}
              fullWidth
              className={classes.buttonStyle}
            >
              Back
            </Button>
          </Box>
        </Dialog>
        <CustomToast data-testid={"openSuccessDilog"} open={this.state.isSuccessDialog} onClose={this.handleCloseSuccessDialog} />
      </ThemeProvider>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
export { Customform4 }
export default withStyles(styles)(Customform4)
// Customizable Area End