import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { ChangeEvent } from "react";
import { setStorageData } from "../../../framework/src/Utilities";

export const locationMockData = ["1", "2-10", "11-50", "50+"]

export interface UserRole {
    id: number,
    name: string,
}

export interface AccountDetails {
    id: string;
    attributes: {
        full_name: string;
        email: string;
        user_type: string;
        no_of_location: string;
        annual_revenue: string;
        country: string;
        city: string;
        company_name: string;
        role_name: string;
        department: string;
        type_of_business: string;
        full_phone_number: string;
        profile_image: string
    };
}

interface ApiResponse {
    data: AccountDetails;
}
export interface Department {
    data: {
        id: string;
        type: string;
        attributes: {
            name: string;
        };
    }[];
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
}

export interface S {
    // Customizable Area Start
    openDetailsModal: boolean;
    openUpdateSucessModal: boolean;
    isResetModal: boolean;
    accountDetails: any;
    userDetails: any;
    prevUserDetails: any;
    anualRevenue: any;
    userRole: UserRole[];
    userDepartment: any;
    companyDetails: any;
    noOfLocation: string;
    openCountryCodeList: any;
    counrtyCodeList: any;
    selectCountryCode: string;
    changePhoneNumber: string;
    isResetPassword: boolean;
    oldPassword: string;
    newPassword: string;
    reEnterNewPassword: string;
    pswdError: boolean;
    pswdErrorText: string;
    oldPassError: boolean,
    oldPassErrorText: string,
    confirmPassErr: boolean,
    confirmPassSucess: boolean;
    confirmPassSucessText: string;
    newError: string;
    confirmPassTextErr: string,
    confirmPassword: string;
    toastErrMsg: string;
    editProfilePic: any;
    imagePreview: any;
    isLoader: boolean;
    disbaleUpdateBtn:boolean;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class VenderProfileController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getAccountDetailsAPICalledId: string = "";
    getUserRoleAPICalledId: string = "";
    getUserDepartmentAPICalledId: string = "";
    getCompanyDetailsAPICalledId: string = "";
    updateAccountAPICallId: string = "";
    getCountryCodeListAPICalledId: string = "";
    changePasswordAPICallId: string = "";
    passwordRegex: RegExp;
    uploadProfileId: string = "";
    showProfileId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];
        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            openDetailsModal: false,
            openUpdateSucessModal: false,
            accountDetails: null,
            userDetails: null,
            prevUserDetails: null,
            anualRevenue: 0,
            userRole: [],
            userDepartment: [],
            companyDetails: [],
            noOfLocation: "",
            openCountryCodeList: null,
            counrtyCodeList: [],
            selectCountryCode: "+44",
            changePhoneNumber: "",
            isResetPassword: false,
            oldPassword: "",
            newPassword: "",
            reEnterNewPassword: "",
            pswdError: false,
            pswdErrorText: "",
            confirmPassErr: false,
            confirmPassSucess: false,
            oldPassError: false,
            oldPassErrorText: "",
            confirmPassSucessText: "",
            confirmPassTextErr: "",
            confirmPassword: "",
            toastErrMsg: "",
            editProfilePic: "",
            imagePreview: "",
            isResetModal: false,
            isLoader: true,
            newError: "",
            disbaleUpdateBtn:false
            // Customizable Area End
        };

        // Customizable Area Start
        this.passwordRegex = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/);
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.getAccountDetails();
        this.getUserRole()
        this.getUserDepartment()
        this.getCompanyDetails()
        this.getCountryCodeList()
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevState.editProfilePic !== this.state.editProfilePic) {
            this.handleProfileImgUpload()
        }
    }


    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId && responseJson) {
                switch (apiRequestCallId) {
                    case this.getAccountDetailsAPICalledId:
                        this.handleAccountDetailsResponse(responseJson);
                        break;
                    case this.getUserRoleAPICalledId:
                        this.handleUserRoleResponse(responseJson);
                        break;
                    case this.getUserDepartmentAPICalledId:
                        this.handleDepartmentResponse(responseJson);
                        break;
                    case this.getCompanyDetailsAPICalledId:
                        this.handleCompanyDetailsResponse(responseJson);
                        break;
                    case this.updateAccountAPICallId:
                        this.handleUpdateDetailsResponse(responseJson);
                        break;
                    case this.getCountryCodeListAPICalledId:
                        this.handleCountryCodeResponse(responseJson);
                        break;
                    case this.changePasswordAPICallId:
                        this.handlePasswordChangeResponse(responseJson);
                        break;
                    case this.uploadProfileId:
                        this.handleProfileUpdate(responseJson);
                        break;
                    // case this.showProfileId:
                    //     this.handleAccountDetailsResponse(responseJson);
                    //     break;
                    default:
                        break;
                }
            }
        }
    }
    // Web Events
    onClosePopOver = () => {
        this.setState({
            openCountryCodeList: null,
        });
    }

    handleCountryCodeChange = (code: any) => {
        this.setState({ userDetails: { ...this.state.userDetails, countryCode: code } }, () => {
            this.setState({ openCountryCodeList: null })
        })
    }

    handleOpenPopover = (event: any) => {
        this.setState({ openCountryCodeList: event.currentTarget });
    };

    handleOpenDetails = () => {
        const countryCode = this.state.accountDetails.full_phone_number.split('-')[0]
        const phoneNumber = this.state.accountDetails.full_phone_number.split('-')[1]
        console.log("countrycode", countryCode);
        console.log("phonenumber", phoneNumber);

        this.setState({
            openDetailsModal: true,
            userDetails: {
                ...this.state.accountDetails,
                countryCode: countryCode,
                fullNumber: phoneNumber
            },
        })
    }
    handleDepartVen = (depTrue: undefined | string, deepFalse: undefined | string) => {
        return !this.state.userDetails?.role_name ? depTrue : deepFalse
    }
    handleSelectClass = (trueVal: undefined | string, falseVal: undefined | string) => {
        return !this.state.userDetails?.department ? trueVal : falseVal;
    }
    handleBusinessType = (busTrue: undefined | string, busFals: undefined | string) => {
        return !this.state.userDetails?.type_of_business ? busTrue : busFals
    }
    handleFormatNum =(formattedPhoneNumber:string)=>{
        return formattedPhoneNumber.length > 3 ? formattedPhoneNumber : ''
    }
    handleNumberLocationButton =(roleButton:any,seletClass:any,items:any)=>{
        return `${roleButton} ${this.state.userDetails?.no_of_location === items && seletClass}`
    }

    handleChangeName = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            userDetails: { ...this.state.userDetails, full_name: event.target.value }
        })
    }

    handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({ userDetails: { ...this.state.userDetails, email: event.target.value } })
    }

    handleChangePhoneNumber = (event: ChangeEvent<HTMLInputElement>) => {
        event.target.value = event.target.value.replace(/\D/g, "")
        this.setState({ userDetails: { ...this.state.userDetails, fullNumber: event.target.value } })
    }

    handleChangeHomeLocation = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({ userDetails: { ...this.state.userDetails, city: event.target.value } })
    }

    handleChangeSelectRole = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
        const selectedValue = event.target.value as string;
        this.setState({
            userDetails: { ...this.state.userDetails, role_name: selectedValue }
        });
    }

    handleChangeDepartment = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
        const selectedValue = event.target.value as string;
        this.setState({
            userDetails: { ...this.state.userDetails, department: selectedValue }
        });
    }

    handleChangeCompanyName = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({ userDetails: { ...this.state.userDetails, company_name: event.target.value } })
    }

    handleChangeBusinessType = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
        const selectedValue = event.target.value as string;
        this.setState({ userDetails: { ...this.state.userDetails, type_of_business: selectedValue } })
    }

    handleLocation = (selectedLocation: string) => {
        this.setState({
            userDetails: {
                ...this.state.userDetails,
                no_of_location: selectedLocation,
            },
        });
    };

    handleCloseDetailsModal = () => {
        this.setState({ openDetailsModal: false })
    }

    handleCloseIcon = () => {
        this.setState({ openUpdateSucessModal: false }, () => this.getAccountDetails())
    }

    handleCancelButton = () => {
        this.setState({ openDetailsModal: false })
    }

    handleResetPassword = () => {
        this.setState({ isResetPassword: true })
    }
    // Profile upload
    onProfileChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const files = e.target.files;
        if (files && files.length > 0) {
            const reader: FileReader = new FileReader();
            const VendorFile: any = files[0];
            reader.onloadend = () => {
                this.setState({
                    editProfilePic: VendorFile,
                    imagePreview: reader.result as string,
                });
            };
            reader.readAsDataURL(VendorFile);
        }
    }

    handleProfileImgUpload = () => {
        let formData = new FormData();
        formData.append("[data][attributes][profile_image]", this.state.editProfilePic, this.state.editProfilePic?.name);
        const headers = {
            token: localStorage.getItem("loginToken"),
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.uploadProfileId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.updateProfileUrl
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    onChangeAnnualRevenue = (event: ChangeEvent<{}>, newValue: number | number[]) => {
        if (typeof newValue === 'number') {
            this.setState({
                userDetails: {
                    ...this.state.userDetails,
                    annual_revenue: newValue,
                },
            });
        } else if (typeof newValue === 'string') {
            const annualRevenue = parseFloat((newValue as string).replace('$', ''));

            if (!isNaN(annualRevenue)) {
                this.setState({
                    userDetails: {
                        ...this.state.userDetails,
                        annual_revenue: `$${annualRevenue}`,
                    },
                });
            }
        }
    };

    handleOldPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        let old = event.target.value.trim();
        if (old.length > 0) {
            if (!this.passwordRegex.test(old.replace(" ", "@"))) {
                this.setState({
                    oldPassError: true,
                    oldPassErrorText: configJSON.venderOldErrText,
                });
            }
            else {
                this.setState({ oldPassError: false, oldPassErrorText: "" });
            }
        }
        this.setState({ oldPassword: event.target.value.trim(), newError: "",oldPassError: false, });
    }

    handleNewPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { oldPassword, reEnterNewPassword } = this.state;
        const newPassword = event.target.value.trim();
        let pswdError = false;
        let pswdErrorText = "";

        if (newPassword.length > 0) {
            if (!this.passwordRegex.test(newPassword.replace(" ", "@"))) {
                pswdError = true;
                pswdErrorText = configJSON.passformate;
            }
        }

        if (reEnterNewPassword && newPassword === reEnterNewPassword) {
            this.setState({
                confirmPassSucess: true,
                confirmPassSucessText: configJSON.passmatch,
                confirmPassErr: false
            });
        } else if (reEnterNewPassword) {
            this.setState({
                confirmPassErr: true,
                confirmPassSucess: false,
                confirmPassSucessText: configJSON.passnotmatch
            });
        }

        if (oldPassword && newPassword === oldPassword) {
            pswdError = true;
            pswdErrorText = "This is your old password. Please choose a different one.";
        }

        this.setState({
            newPassword: newPassword,
            pswdError: pswdError,
            pswdErrorText: pswdErrorText,
            newError: ""
        });
    }


    handlereEnterNewPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { newPassword } = this.state;
        const reEnteredPassword = event.target.value.trim();

        if (reEnteredPassword.length === 0) {
            this.setState({
                reEnterNewPassword: reEnteredPassword,
                confirmPassErr: true,
                confirmPassSucessText: "This field is required."
            });
        } else if (reEnteredPassword === newPassword) {
            this.setState({
                reEnterNewPassword: reEnteredPassword,
                confirmPassSucess: true,
                confirmPassSucessText: configJSON.passmatch,
                confirmPassErr: false,
                newError:""
            });
        } else {
            this.setState({
                reEnterNewPassword: reEnteredPassword,
                confirmPassErr: true,
                confirmPassSucess: false,
                confirmPassSucessText: configJSON.passnotmatch,
                newError:""
            });
        }

    }


    getAccountDetails = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getAccountDetailsAPICalledId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.detailsAPIEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPIMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getUserRole = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getUserRoleAPICalledId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.roleAPIEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPIMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getUserDepartment = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getUserDepartmentAPICalledId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.departmentAPIEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPIMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getCompanyDetails = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCompanyDetailsAPICalledId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.companyAPIEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPIMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    updateAccountDetails = () => {
        const propertiesToCheck = [
            "full_name",
            "email",
            "department",
            "no_of_location",
            "type_of_business",
            "annual_revenue",
            "city",
            "role_name",
            "company_name",
            "fullNumber",
            // "countryCode",
        ];
        if (propertiesToCheck.some(property => this.state.userDetails[property] !== this.state.accountDetails[property])) {
            const header = {
                "Content-Type": configJSON.validationApiContentType,
                token: localStorage.getItem("loginToken"),
            };

            const attrs = {
                "full_name": this.state.userDetails?.full_name,
                "email": this.state.userDetails?.email,
                "full_phone_number": this.state.userDetails?.fullNumber,
                "account_block_departments_id": this.state.userDetails?.department,
                "no_of_location": this.state.userDetails?.no_of_location,
                "type_of_businesses_id": this.state.userDetails?.type_of_business,
                "annual_revenue": this.state.userDetails?.annual_revenue,
                "country": "india",
                "city": this.state.userDetails?.city,
                "role_id": this.state.userDetails?.role_name,
                "company_name": this.state.userDetails?.company_name,
                "country_code": this.state.userDetails.countryCode,
                "type": "email_account"
            }

            const httpBody = {
                data: attrs,
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.updateAccountAPICallId = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.updateDetailsAPIEndPoint
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(httpBody)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.putMethod
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    }


    getCountryCodeList = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem("loginToken"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCountryCodeListAPICalledId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.countryListAPIEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPIMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    changePasswordAPI = () => {
        if (this.customValidation() && !this.state.oldPassError && !this.state.pswdError) {
            this.setState({disbaleUpdateBtn:true,confirmPassSucessText:""})
            const header = {
                "Content-Type": configJSON.validationApiContentType,
                token: localStorage.getItem("loginToken"),
            };

            const attrs = {
                "token": localStorage.getItem("loginToken"),
                "old_password": this.state.oldPassword,
                "new_password": this.state.newPassword,
                "new_password_confirm": this.state.reEnterNewPassword
            }
            const httpBody = {
                data: attrs,
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.changePasswordAPICallId = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.changePasswordAPIEndPoint
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(httpBody)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.postMethod
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    }


    customValidation = () => {
        const { oldPassword, newPassword, reEnterNewPassword } = this.state
        let isValid = true;
        if (!oldPassword) {
            this.setState({
                oldPassError: true,
                oldPassErrorText: "This field is required.",
            });
            isValid = false
        }
        if (!newPassword) {
            this.setState({
                pswdError: true,
                pswdErrorText: "This field is required.",
            });
            isValid = false
        }
        if (!reEnterNewPassword) {
            this.setState({
                confirmPassErr: true,
                confirmPassSucessText: "This field is required."
            });
            isValid = false
        }
        return isValid;
    }

    // API Response
    handleAccountDetailsResponse = (json: any) => {
        const apiresponse = json?.data?.attributes
        const rolename = this.state.userRole.find((item: any) => item.id == apiresponse?.role_name)
        const departmentname = this.state.userDepartment.find((item: any) => item.id == apiresponse?.department)
        const buisnesstype = this.state.companyDetails.find((item: any) => item.id == apiresponse?.type_of_business)
        if (apiresponse) {
            this.setState({ isLoader: false })
            setStorageData("userdetails", JSON.stringify(apiresponse))
            this.setState({
                accountDetails: {
                    ...apiresponse,
                    role: rolename?.name,
                    depart_ment: departmentname?.attributes?.name,
                    typeOfbusiness: buisnesstype?.attributes?.name,
                    profile_image: apiresponse.profile_image ? apiresponse.profile_image : ""
                }
            })
        }
    }

    handleUserRoleResponse = (json: UserRole[]) => {
        const apiresponse = json
        if (apiresponse) {
            this.setState({ userRole: apiresponse })
        }
    }

    handleDepartmentResponse = (json: any) => {
        const apiResponse = json?.data;
        this.setState({ userDepartment: apiResponse });
    }

    handleCompanyDetailsResponse = (json: any) => {
        const apiresponse = json?.data
        if (apiresponse) {
            this.setState({ companyDetails: apiresponse })
        }
    }

    handleUpdateDetailsResponse = (json: any) => {
        const apiresponse = json
        if (apiresponse) {
            this.setState({ openUpdateSucessModal: true, openDetailsModal: false })
        }
    }

    handleCountryCodeResponse = (json: any) => {
        const apiresponse = json?.country_codes
        if (apiresponse) {
            this.setState({ counrtyCodeList: apiresponse })
        }
    }

    handlePasswordChangeResponse = (json: any) => {
        if (json.errors) {
           
            this.setState({
                oldPassError: true,
                oldPassErrorText: configJSON.venderOldErrText,
                disbaleUpdateBtn:false
            });
        } else {
            this.setState({ isResetPassword: false,disbaleUpdateBtn:false })
            const apiresponse = json
            if (apiresponse) {
                this.setState({
                    oldPassword: "",
                    newPassword: "",
                    reEnterNewPassword: "",
                    openDetailsModal: false
                })
                this.setState({ isResetModal: true })
            }
        }
    }

    handleProfileUpdate = (json: any) => {
        const apiresponse = json
        if (apiresponse) {
            this.getAccountDetails()
        }
    }

    handleCloseResetPassword = () => {
        this.setState({
            oldPassword: "",
            newPassword: "",
            reEnterNewPassword: "",
            isResetPassword: false,
            oldPassError: false,
            oldPassErrorText: "",
            pswdError: false,
            pswdErrorText: "",
            confirmPassSucess: false,
            confirmPassErr: false,
            confirmPassSucessText: "",
            confirmPassTextErr: "",
            confirmPassword: "",
            newError: ""
        })
    }
    goBackLoginPage = () => {
        localStorage.clear();
        this.props.navigation.navigate("Home2", { type: "EmailAccountLoginBlock" })
    }
    // Customizable Area End
}
