import React from "react";
// Customizable Area Start
import "./index.css";

import LandingPageController, {
  Props,
  configJSON
} from "./LandingPageWebController.web";

import {
  search,
  
  events,
  dummyImage1,
  supplier,
  software,
  illustration1,
  illustration2,
  goodcopany,
  searchIconEvents,
    searchIconSuppliers,
    searchIconServices,
    searchIconSoftwares,
    downArrow
} from "./assets";

import {  withStyles } from "@material-ui/core/styles";
import {  Grid, Typography, Button, InputBase,  Box, Link } from "@material-ui/core";
import CommonLandingHeader from "./CommonLandingHeader.web";
import EmailAccountLoginBlock from "../../email-account-login/src/EmailAccountLoginBlock.web";
// Customizable Area End



export class LandingPage extends LandingPageController {
  
  
  
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

   
// Customizable Area Start
  displayTextResturantPage(classes: any,handleSearchInput: any, searchResultsVisible: boolean, handleInnerClick: any, values: any, handleAdvancedSearch: any) {

    return (<><Typography variant="h1" className={classes.landingHeaderTitle}>
      {this.state.buyerPageData.heading.split(",")[0]},
      <br />{this.state.buyerPageData.heading.split(",")[1]}
    </Typography>
      <Box className={classes.formWrap}>
        <form data-testId="advanceSearch" onSubmit={()=>this.handleAdvancedSearch("All")} className={classes.searchWrap}>
          <Box className={classes.searchIcon}>
            <img src={search} alt="Search Icon" className={classes.searchIconimg} />
          </Box>
          <InputBase  data-test-id="searchBarForm" onChange={handleSearchInput} type="text" placeholder="Search for directories" className={classes.searchWrapInput} />
          {searchResultsVisible && <Box  className={classes.searchResultWrapper}>
          {[{ id: 1,directory:"Suppliers",count:this.state.supplierCount,img:searchIconSuppliers },{ id: 2,directory:"Events",count:this.state.eventCount,img:searchIconEvents },{ id: 2,directory:"Services",count:this.state.serviceCount,img:searchIconServices },{ id: 3,directory:"Software",count:this.state.softwareCount,img:searchIconSoftwares }].map((item:any) =>(<Box
                  key={item.id}
                  onClick={()=>handleAdvancedSearch(item.directory)}
                  className={classes.searchResultRow}
                >
                  <Box className={classes.searchResultImgWrapper}>
                    <img
                      className={classes.advancedSearchIcon}
                      src={item.img}
                    />
                  </Box>
                  <Box className={classes.searchResultTextBox}>
                    <Typography className={classes.searchResultLastText}>
                      <Typography className={classes.searchResultFirstText}>
                        {values.searchTextValue}
                      </Typography>
                      in {item.directory} ({item.count} results)
                    </Typography>
                  </Box>
                </Box>)
                )}

           

            

           
            <Typography onClick={()=>handleAdvancedSearch("All")} className={classes.seeAllText}>See all directories</Typography>
          </Box>}
        </form>
      </Box></>)
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes }: any = this.props;
    this.displayTextResturantPage = this.displayTextResturantPage.bind(this);

    return (
      <>
        <Box onClick={this.handleOuterClick} className={classes.outerMostBox} >
          <CommonLandingHeader
            classes={classes}
            goToLogin={this.goToLogin}
            handleProfileMenuOpen={this.handleProfileMenuOpen}
            handlePopoverClose={this.handlePopoverClose}
            popoverAnchorE1={this.state.popoverAnchorEl}
            username={this.state.Username}
            headerNavigation={this.handleHeaderNavigation}
            signout={this.handleUserSignout}
            goToHomePage={this.goToHomePage}
            downarrow={downArrow}
            displayTextResturantPage={this.displayTextResturantPage}
            buyerPageData={this.state.buyerPageData}
            handleAdvancedSearch={this.handleAdvancedSearch}
            values={this.state}
            handleInnerClick={this.handleInnerClick}
            searchResultsVisible={this.state.searchResultsVisible}
            data-testid={"commonHeader"}
            handleSearchInput={this.handleSearchInput}>
          </CommonLandingHeader>
          <Box className={classes.landingServices}>
            <Grid container spacing={4} className={classes.landingServicesContainer}>
              <Grid item lg={3} md={3} sm={6} xs={12}>
                <Link href={"/Catalogue/Suppliers"} underline='none'>
                  <Box className={classes.serviceCard}>
                    <img src={supplier} alt="Supplier Icon" className={classes.directoryImg1} />
                    <Typography variant="h2" className={classes.serviceName}>{configJSON.suppliers}</Typography>
                    <Typography variant="body1" className={classes.serviceDetail}>{configJSON.suppliersDirectory}</Typography>
                  </Box>
                </Link>
              </Grid>
              <Grid item lg={3} md={3} sm={6} xs={12}>
                <Link href={"/Catalogue/Software"} underline='none'>
                  <Box className={`${classes.serviceCard} ${classes.serviceCard2}`}>
                    <img src={software} alt="Software Icon" className={classes.directoryImg1} />
                    <Typography variant="h2" className={`${classes.serviceName} ${classes.service2Name}`}>{configJSON.software}</Typography>
                    <Typography variant="body1" className={classes.serviceDetail}>{configJSON.softwareDirectory}</Typography>
                  </Box>
                </Link>
              </Grid>
              <Grid item lg={3} md={3} sm={6} xs={12}>
                <Link href={"/Catalogue/Services"} underline='none'>
                  <Box className={`${classes.serviceCard} ${classes.serviceCard3}`}>
                    <img src={dummyImage1} alt="Services Icon" className={classes.directoryImg3} />
                    <Typography variant="h2" className={`${classes.serviceName} ${classes.service3Name}`}>{configJSON.services}</Typography>
                    <Typography variant="body1" className={classes.serviceDetail}>{configJSON.servicesDirectory}</Typography>
                  </Box>
                </Link>
              </Grid>
              <Grid item lg={3} md={3} sm={6} xs={12}>
                <Link href={"/Catalogue/Events"} underline='none'>
                  <Box className={`${classes.serviceCard} ${classes.serviceCard4}`}>
                    <img src={events} alt="Events Icon" className={classes.directoryImg4}/>
                    <Typography variant="h2" className={`${classes.serviceName} ${classes.service4Name}`}>{configJSON.events}</Typography>
                    <Typography variant="body1" className={classes.serviceDetail}>{configJSON.eventsDirectory}</Typography>
                  </Box>
                </Link>
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.restaurantSuppliers}>
            <Box className={classes.restaurantSuppliersRow}>
              <Grid container className={classes.illustrationImgGrid} >
                <Grid item lg={5} md={6} sm={12} className={`${classes.illustration1} ${classes.illustration1Supplier}`}>
                  <img src={illustration1} alt="Illustration 1" className={classes.illustration1Img} />
                </Grid>
                <Grid item lg={7} md={6} sm={12} className={classes.resturantSupplierContent}>
                  <Typography data-testId="restaurentSupplierTitle" variant="h2" className={classes.restaurantSuppliersTitle}>
                    {this.state.buyerPageData.restaurent_supplier.title}
                  </Typography>
                  <Typography variant="body1" className={classes.restaurantSuppliersSubtitle}>
                    {this.state.buyerPageData.restaurent_supplier.subtitle}
                  </Typography>
                  <Typography variant="body1" className={classes.restaurantSuppliersDetail}>
                    {this.state.buyerPageData.restaurent_supplier.main_text}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box className={`${classes.restaurantSuppliers} `}>

            <Box className={`${classes.restaurantSuppliersRow} ${classes.weeklyChitrow}`}>
              <Grid container >
                <Grid item lg={7} md={6} sm={12} className={classes.weeklyChitContent}>
                  <Typography data-testId="weeklyChitTitle" variant="h2" className={classes.restaurantSuppliersTitle}>
                    {this.state.buyerPageData.weekly_chit.title}
                  </Typography>
                  <Typography variant="body1" className={`${classes.weeklyChitrowrestaurantSuppliersSubtitle} ${classes.restaurantSuppliersSubtitle}`} >{this.state.buyerPageData.weekly_chit.subtitle}</Typography>
                  <Typography variant="body1" className={classes.restaurantSuppliersDetail}>
                    {this.state.buyerPageData.weekly_chit.main_text}
                  </Typography>
                  <Button className={classes.weeklyChitBtn}>{configJSON.subscribe}</Button>
                </Grid>
                <Grid item lg={5} md={6} sm={12} className={`${classes.illustration2} ${classes.illustration2Grid}`}>
                  <img className={classes.illustration1Img} src={illustration2} alt="Illustration 2" />
                </Grid>
              </Grid>
            </Box>

          </Box>
          <Box className={`${classes.restaurantSuppliers} `}>

            <Box className={`${classes.restaurantSuppliersRow} ${classes.goodCompanyRow}`}>
              <Grid container className={classes.illustrationImgGrid}>
                <Grid item lg={5} md={6} sm={12} className={`${classes.illustration3} ${classes.illustration1Supplier} ${classes.goodCompanyImgWrapper}`}>
                  <img src={goodcopany} alt="Good Company" className={`${classes.illustration1Img} ${classes.goodCompanyImg}`} />
                </Grid>
                <Grid item lg={7} md={6} sm={12} className={classes.goodCompanyContent}>
                  <Typography variant="h2" className={classes.restaurantSuppliersTitle}>
                    {this.state.buyerPageData.in_good_company.title}
                  </Typography>
                  <Typography variant="body1" className={`${classes.goodCompanyRowrestaurantSuppliersSubtitle} ${classes.restaurantSuppliersSubtitle}`}>
                    {this.state.buyerPageData.in_good_company.subtitle}
                  </Typography>
                  <Typography variant="body1" className={classes.restaurantSuppliersDetail}>
                    {this.state.buyerPageData.in_good_company.main_text}
                  </Typography>
                </Grid>
              </Grid>
            </Box>

          </Box>
        </Box>
        {
          this.props.navigation.getParam("type") === "login" ?
            <EmailAccountLoginBlock id="EmailAccountLoginBlock" navigation={this.props.navigation} />
            : null
        }
      </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start

export const userStyles = () => ({

  landingBanner: {
    backgroundImage: `url(${require('../assets/banner.png')})`,
    backgroundPosition: "top",
    backgroundSize: "cover",
    fontFamily: "Open Sans",
    width: "100%",
    boxShadow: "none"
  },

  container: {
    paddingLeft: "40px",
    paddingRight: "40px",
    paddingTop: "17px",
    "@media(min-width: 1280px)": {
      maxWidth: "none"
    }

  },

  aboutContainer: {
    paddingLeft: "40px",
    paddingRight: "40px",
    paddingTop: "57px",
  },
  landingHeader: {
    
    fontFamily: "Poppins",
    maxWidth: "1440px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  headingGridContainer: {
  },
  landingHeaderRow: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  profilePaper: {
    width: "100%",
    padding: "20px",
    maxWidth: "400px",
    display: 'flex',
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    flexDirection: 'column' as const,
    gap: '15px' as const,
    borderRadius: '8px',
    backgroundColor: "#F8F2FF"
  },
  avatarWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '30px',
    cursor: 'pointer',
    padding: '10px',

    "@media (min-width: 300px) and (max-width: 950px)": {
      padding: '3px',
      marginRight: '5px',
    }
  },
  avatar: {
    marginRight: '8px',
    width: '56px',
    height: '56px',

    "@media (min-width: 300px) and (max-width: 950px)": {
      height: '30px',
      marginRight: '5px',
      width: '30px'
    }
  },
  directoryImg4:{
    height:54,
    width:54
  },
  directoryImg3:{
    height:55,
    width:67.5
  },
  directoryImg1:{
    height:52,
    width:52
  },
  fontsStyle: {
    color: '#0F172A',
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: '14px',
    cursor: "pointer"
  },
  textWrapper: {
    display: 'flex',
    marginLeft: '8px',
    flexDirection: 'column' as const,
  },
  welcomeText: {
    marginBottom: '0px',
    color: '#0F172A',
    fontSize: '14px',
    fontFamily: "Poppins",
    fontWeight: 500,

    "@media (min-width: 300px) and (max-width: 950px)": {
      fontWeight: 300,
      fontSize: '5px',
    }
  },
  nameText: {
    color: '#0F172A',
    fontWeight: 500,
    fontSize: '14px',
    fontFamily: "Poppins",

    "@media (min-width: 300px) and (max-width: 950px)": {
      fontWeight: 300,
      fontSize: '8px',
    }
  },
  downArrowIcon: {
    marginLeft: '4px',
    height: '8px',
    width: '10px',
    cursor: 'pointer',

    "@media (min-width: 300px) and (max-width: 950px)": {
      width: '5px',
      height: '5px',
    }
  },
  nameAndArrow: {
    alignItems: 'center',
    display: 'flex',
  },
  topheaderlogo: {
    height: "56px",
    width: "243px",
    cursor: "pointer",
    "@media(max-width: 520px)": {
      height: "8.615vw",
      width: "37.5vw",
    }
  },

  signinBtn: {
    borderRadius: "8px",
    background: "#FFF",
    color: "#9429C5",
    fontSize: "14px",
    fontStyle: "bold",
    fontWeight: 500,
    lineHeight: "14px",
    border: 0,
    cursor: "pointer",
    fontFamily: "Poppins",
    height: "36px",
    width: "162px",
    textTransform: "none" as "none",
    '&:hover': {
      backgroundColor: 'white',
    },
    "@media(max-width: 520px)": {
      height: "6.923vw",
      width: "31.154vw",
    }
  },
  landingBannerContent: {
    padding: "65px 0",
    display: "block",
  },
  row: {
    width: "100%",
    display: "block",
    "@media(max-width: 480px)": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    }
  },

  landingHeaderTitle: {
    color: "#FFF",
    fontFamily: "Open Sans, sans-serif",
    textAlign: "center" as "center",
    fontSize: "42px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "44.619px",
    "@media(max-width: 760px)": {
      fontSize: "35px",
    },
    "@media(max-width: 480px)": {
      fontSize: "30px",
    }
  },
  formWrap: {
    "@media(max-width: 470px)": {
      width: "130%",
    }
  },

  searchWrap: {
    position: "relative" as "relative",
    width: "65%",
    margin: "70px auto",
    maxWidth: "780px",
    "@media(max-width: 720px)": {
      width: "85%",
    },
    "@media(max-width: 470px)": {
      width: "100%",
    }
  },

  searchWrapInput: {
    borderRadius: "19px",
    background: "#FFF",
    padding: "18px 24px",
    display: "block",
    width: "100%",
    paddingLeft: "70px",
    border: 0,
    color: "#000",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "14px",
    fontFamily: "Poppins",
    "& .MuiInputBase-input": {
      padding: "6px 0px 7px 24px",
      "@media(max-width: 580px)": {
        padding: "6px 0px 7px 10px",
      },
    },
    "@media(max-width: 760px)": {
      padding: "20px",
      paddingLeft: "70px",
    },
    "@media(max-width: 580px)": {
      fontSize: "20px",
      paddingLeft: "60px",
    },
    "@media(max-width: 480px)": {

      paddingLeft: "40px",
    }
  },

  searchIcon: {
    position: "absolute" as "absolute",
    top: "18px",
    left: "24px",
    width: "38px",
    display: "block",
    zIndex: 10,
    "@media(max-width: 580px)": {
      top: "24px",
    },
    "@media(max-width: 480px)": {
      left: "10px",
    }
  },
  searchIconimg: {
    width: "100%",
    "@media(max-width: 580px)": {
      width: "70%",
    }
  },

  landingAboutFull: {
    background:
      "linear-gradient(0deg, rgba(255,255,255,1) 28%, rgba(255,255,255,0) 100%)",

    width: "100vw"
  },
  landingAbout: {
    paddingTop: "100px",
    display: "block",

    maxWidth: "1440px",
    marginRight: "auto",
    marginLeft: "auto"
  },
  landingAboutTitle: {
    color: "#333",
    fontSize: "38px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "80.956px",
    margin: "0 0 42px 0",
    fontFamily: "Poppins",
    "@media(max-width: 760px)": {
      fontSize: "38px",
    },
  },
  landingAboutDetail: {
    color: "#313131",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 300,
    margin: 0,
    lineHeight: "40.48px",
    fontFamily: "Poppins",
    "@media(max-width: 760px)": {
      fontSize: "20px",
    },
    "@media(max-width: 480px)": {
      fontSize: "18px",
    }
  },

  illusImgGrid: {
    alignItems: "center",
    display: "flex",
    "@media(max-width: 600px)": {
      marginTop: "50px",
    }
  },
  landingAboutImage: {
    width: "100%",
  },

  landingServices: {
    padding: "90px 0 70px 0px",
    display: "block",
    background: "#fff",
    fontFamily: "Poppins",
    width: "100%",

  },
  landingServicesContainer: {
    width: "85%",
    margin: "0 auto",
    maxWidth: "1020px"
  },
  serviceDetail: {
    color: "#313131",
    textAlign: "center" as const,
    fontFamily: "Poppins",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "27.2px",
    margin: 0,
  },

  serviceCard: {
    padding: "29px 0",
    display: "flex",
    borderRadius: "16px",
    border: "2px solid #00B884",
    background: "#EAFFF9",
    flexDirection: "column" as const,
    alignItems: "center",
    gap: "24px",
    cursor: "pointer"
  },

  serviceCard2: {
    border: "2px solid #57F",
    background: "#EFF2FF",
  },

  service2Name: {
    color: "#5577FF !important",
  },

  serviceCard3: {
    borderRadius: "16px",
    border: "2px solid #B300ED",
    background: "#FCF1FF",
  },
  service3Name: {
    color: "#B300ED !important",
  },
  serviceCard4: {
    borderRadius: "16px",
    border: "2px solid #DC004A",
    background: "#FFF6F9",
  },
  service4Name: {
    color: "#DC004A !important",
  },
  serviceName: {
    fontSize: "26px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "26px",
    color: "#00B884",
    fontFamily: "Poppins"
  },

  restaurantSuppliers: {
    margin: "80px 0",
    display: "block",
    fontFamily: "Poppins",
    "@media(max-width: 600px)": {
      margin: "40px 0",
    },
  },
  restaurantSuppliersRow: {
    borderRadius: "32px",
    background: "#F1FAFF",
    padding: "69px 0px",
    width: "100%",
    margin: "0 auto",
    maxWidth: "1360px",
    "@media(max-width: 1440px)": {
      width: "calc(100% - 30px)"
    },
  },

  restaurantSuppliersTitle: {
    color: "#333",
    fontSize: "38px",
    fontStyle: "normal",
    fontFamily: "Poppins",
    fontWeight: 600,
    lineHeight: "80.956px",
    "@media(max-width: 992px)": {
      marginTop: "30px"
    },
    "@media(max-width: 700px)": {
      fontSize: "38px",
    },
    "@media(max-width: 606px)": {
      fontSize: "38px",
      lineHeight: "60px",

    }

  },
  restaurantSoftwareTitle: {
    color: "#333",
    fontSize: "48px",
    fontStyle: "normal",
    fontFamily: "Poppins",
    fontWeight: 600,
    lineHeight: "80.956px",
    "@media(max-width: 992px)": {
      marginTop: "30px"
    },
    "@media(max-width: 700px)": {
      fontSize: "48px",
    },
    "@media(max-width: 606px)": {
      fontSize: "48px",
      lineHeight: "60px",

    }

  },
  restaurantSuppliersSubtitle: {
    color: "#2E6FD1",
    fontFamily: "Poppins",
    fontSize: "30px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "35px",
    marginTop: "15px",
    "@media(max-width: 606px)": {
      fontSize: "24px",
      // marginTop: "0px",
    }

  },

  restaurantSuppliersDetail: {
    color: "#666",
    fontFamily: "Poppins",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 100,
    lineHeight: "30px",
    marginTop: "30px",
    // paddingRight: "64px"
  },
  illustration1: {
    display: "flex",
    alignItems: "center",
    padding: "0 20px 0 51px",
    "@media(max-width: 960px)": {
      padding: "0 20px",
    }

  },
  illustration2: {
    display: "flex",
    alignItems: "center",
    paddingRight: "38px",
    "@media(max-width: 960px)": {
      paddingRight: "0px",
      justifyContent: "center"
    }
  },
  illustration3: {
    display: "flex",
    alignItems: "center",

  },
  illustration2Grid: {
    "@media(max-width: 960px)": {
      marginTop: "30px"
    }
  },
  illustration1Supplier: {
    "@media(max-width: 960px)": {
      justifyContent: "center"
    }
  },
  resturantSupplierContent: {
    padding: "0 40px 0 20px"
  },
  illustration1Img: {
    width: "100%",
    "@media(max-width: 960px) and (min-width: 600px)": {
      width: "90%",
    }
  },

  illustrationImgGrid: {
    "@media(max-width: 600px)": {
      justifyContent: "center"
    }
  },
  weeklyChitrow: {
    borderRadius: "32px",
    background: "#FFF3EC !important",
  },
  weeklyChitrowrestaurantSuppliersSubtitle: {
    color: "#FF823B",
  },
  weeklyChitContent: {
    padding: "0 20px 0 48px",
    "@media(max-width: 960px)": {
      padding: "0 20px"
    }
  },
  goodCompanyContent: {
    paddingRight: "42px",
    "@media(max-width: 960px)": {
      padding: "0 20px"
    }
  },
  weeklyChitBtn: {
    borderRadius: "12px",
    background: "#FF823B",
    padding: "29px 28px 28px 27px",
    color: "#FFF",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "14px",
    border: 0,
    cursor: "pointer",
    textTransform: "none" as "none",
    marginTop: "30px",
    fontFamily: "Poppins",
    '&:hover': {
      backgroundColor: '#FF823B',

    },
  },

  goodCompanyRow: {
    borderRadius: "32px",
    background: "#E9FFEF !important",
  },
  goodCompanyRowrestaurantSuppliersSubtitle: {
    color: "#3DB05C",
    fontSize: "28px"
  },

  searchRow: {
    position: "relative" as "relative"
  },
  searchResultWrapper: {

    width: "100%",
    backgroundColor: "white",
    position: "absolute" as "absolute",
    top: "100px",
    borderRadius: "10px"
  },
  searchResultRow: {
    display: "flex",
    padding: "12px 20px",
    cursor: "pointer"
  },
  searchResultFirstRow: {
    marginTop: "13px"
  },
  searchResultImgWrapper: {
    marginRight: "18px",
    // "@media(max-width: 450px)": {
    //   marginRight:"14px",
    // },
  },
  searchResultTextBox: {
    display: "flex",
    alignItems: "center"
  },
  searchResultFirstText: {
    color: '#000',
    fontFamily: "Poppins",
    fontSize: "24px",
    lineHeight: "14px",
    marginRight: "18px",
    fontWeight: 400,
    display: "inline"
    // "@media(max-width: 450px)": {
    //   fontSize:"14px",
    //   marginRight:"10px",
    // },
  },
  searchResultLastText: {
    color: '#C1B5B5',
    fontFamily: "Poppins",
    fontSize: "24px",
    lineHeight: "14px",
    fontWeight: 400,
    // "@media(max-width: 450px)": {
    //   fontSize:"14px",

    // },
  },
  seeAllText: {
    fontFamily: "Poppins",
    fontSize: "24px",
    lineHeight: "14px",
    margin: "10px 0 35px 88px",
    fontWeight: 400,
    color: "#5577FF",
    cursor: "pointer"
  },
  outerMostBox: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column" as "column",
    marginTop:"3%"
  },
  goodCompanyImgWrapper: {
    justifyContent: "center"
  },
  goodCompanyImg: {
    maxWidth: "323px"
  },
  advancedSearchIcon: {
    // "@media(max-width: 550px)": {
    //   height:"40px",
    //   width:"40px"
    // },
    // "@media(max-width: 450px)": {
    //   height:"30px",
    //   width:"30px"
    // }

  }

});

export default withStyles(userStyles)(LandingPage);
// Customizable Area End