import React from "react";

// Customizable Area Start
import {
    Box,
    Typography,
    Button,
    OutlinedInput,
    Chip,
    Dialog,
    Checkbox,
    TextField,
    Theme,
    Backdrop,
    CircularProgress,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
// Customizable Area End

// Customizable Area Start
import IntegrationsPageController, {
    Props,
    configJSON,
} from "./IntegrationsPageController";
import { plusIcon, CloseICon, deleteIcon_1 } from "./assets";
import { closeIcon } from "../../landingpage/src/assets";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CustomFormFeedback from "../../../components/src/CustomFormFeedback.web";
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import CustomToast from "../../../components/src/CustomToast.web";
import clsx from 'clsx';
import CustomSaveButton from '../../../components/src/CustomSaveButton.web';
import CustomBackButton from '../../../components/src/CustomBackButton.web';


// Customizable Area End

export class IntegrationsPage extends IntegrationsPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes }: any = this.props
        // Customizable Area End
        return (
            // Customizable Area Start
            // Required for all blocks
            <Box className={classes.boxWrapperStyle}>
                <Backdrop className={classes.backdropStyle} open={this.state.isLoadIntegrationData}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Typography className={classes.integrationTextStyle}>{configJSON.integartions}</Typography>
                <Box className={classes.boxWidth}>
                    <Box className={classes.contentBoxStyle}>
                        <Typography className={classes.fontStyle5}>{configJSON.integartions}</Typography>
                        <Typography className={classes.fontStyle2}>{configJSON.enterNewIntegration}</Typography>
                        <Autocomplete
                            multiple
                            id="tags-filled"
                            options={this.state.integrationList}
                            getOptionLabel={(option: any) => option.name}
                            data-test-id={`autocomplete-1`}
                            value={this.state.selectedIntegratinList}
                            disableClearable={true}
                            onChange={(event, newValue) => this.onAutoCompleteChange(event, newValue)}
                            // freeSolo
                            className={classes.autocomplete}
                            renderOption={(option, { selected }) => (
                                <div>
                                    <Checkbox
                                        checkedIcon={<span className={clsx(classes.icon_style, classes.checkedIcon_style)} />}
                                        icon={<span className={classes.icon_style} />}
                                        style={{
                                            color: selected ? "#B300ED" : '#64748B',
                                            marginRight: 8
                                        }}
                                        checked={selected}
                                    />
                                    {option.name}
                                </div>
                            )}
                            renderTags={(value, getTagProps) =>
                                value.map((option: any, index: number) => (
                                    <Chip variant="outlined" label={option.name}
                                        {...getTagProps({ index })}
                                        className={classes.chip}
                                        style={{ fontSize: '14px', fontFamily: "Poppins" }}
                                        deleteIcon={<img src={deleteIcon_1} style={{ height: '20px', width: '20px' }} />}
                                    />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    placeholder={this.state.selectedIntegratinList.length > 0 ? "" : "Select Integrations"}
                                    InputProps={{ ...params.InputProps, disableUnderline: true }}
                                />
                            )}
                        />

                    </Box>
                </Box>

                <Box className={classes.boxWidth_2}>
                    <Box className={classes.contentBoxStyle}>
                        <Typography className={classes.fontStyle5} data-testid={"link_name"}>{configJSON.link}</Typography>
                        <Typography className={classes.fontStyle2}>{configJSON.linkIntegration}</Typography>
                        <OutlinedInput
                            id="outlined-adornment-weight"
                            value={this.state.updatedIntegrationdata?.link}
                            onChange={this.handleChangeLink}
                            data-testid={"change_link"}
                            inputProps={{
                                'aria-label': 'address',

                                className: classes.placeholder,
                            }}
                            classes={{
                                root: classes.outlinedInput,
                                focused: classes.focused,
                                notchedOutline: classes.notchedOutline,
                                input: classes.inputText
                            }}
                        />
                    </Box>
                </Box>

                <Box className={classes.boxWidth}>
                    <Box className={classes.contentBoxStyle}>
                        <Typography className={classes.fontStyle6}>{configJSON.note}</Typography>
                        <Typography className={classes.fontStyle7}>{configJSON.description}</Typography>
                    </Box>
                </Box>

                <Box className={classes.boxWidth_3}>
                    <Typography className={classes.fontStyle3}>{configJSON.description_2}</Typography>
                    <Typography className={classes.fontStyle4} onClick={this.handleContactUsModal} data-testid={"contactus_modal"}>{configJSON.contact}</Typography>
                </Box>

                <Box className={classes.boxFeild_3}>
                    <Box className={classes.buttonBox}>
                        <Typography className={classes.fontStyle5}>{configJSON.needHelp}</Typography>
                        <Typography className={classes.fontStyle4}>{configJSON.no}</Typography>
                        <Typography className={classes.fontStyle4} onClick={this.handleFeedbackModal} data-test-id={"openFeedbackModal"}>{configJSON.yes}</Typography>
                    </Box>

                    <Box className={classes.buttonBoxStyle}>
                        <CustomBackButton data-testid="navigateToAccount" label={configJSON.backBtn} handleClick={this.goToVendorAccount} />
                        <CustomSaveButton label={configJSON.saveBtn} data-testid={"save_button"} handleClick={this.updateIntegrationListingData} />


                    </Box>
                    <CustomToast open={this.state.isSucess} onClose={this.handleCloseSuccess} />



                    <Dialog
                        open={this.state.isContsctUs}
                        data-test-id={"openDescriptionModal"}
                        onClose={this.handleCloseContactModal}
                        maxWidth="md"
                        PaperProps={{ className: classes.contactUsDialog }}
                    >

                        <Box className={classes.cancelGridStyle}>
                            <img
                                src={closeIcon}
                                className={classes.closeIconStyle}
                                onClick={this.handleCloseContactModal}
                                data-testid={"closeModal"}
                            />
                        </Box>
                        <Box className={classes.BoxScrollbar}>
                            <Typography className={classes.contactUsFont}>{configJSON.contact}</Typography>
                            <Box className={classes.contentBoxStyle_1}>
                                <TextField
                                    id="outlined-adornment-weight"
                                    variant="outlined"
                                    className={classes.emailIntegration}
                                    inputProps={{
                                        'aria-label': 'address',
                                        placeholder: 'Email',
                                        className: classes.placeholder,
                                    }}
                                    value={this.state.email}
                                    onChange={this.handleChangeEmail}
                                    data-test-id={"email"}
                                    error={this.state?.isValidEmail?.valid}
                                    helperText={
                                        this.state?.isValidEmail?.valid &&
                                        <Box className={classes.errorBoxStyleIntegration}>
                                            {<ErrorOutlineOutlinedIcon className={classes.errorIconStyle} color="error" />}
                                            <Typography style={{
                                                color: "#D53333",
                                                fontFamily: "Poppins",
                                                fontSize: "16px",
                                                fontWeight: 400,
                                            }}>
                                                {this.state?.isValidEmail?.message}
                                            </Typography>
                                        </Box>
                                    }

                                />

                                <OutlinedInput
                                    id="outlined-adornment-weight"
                                    rows={6}
                                    multiline
                                    classes={{
                                        root: classes.outlinedInput_1,
                                        focused: classes.focused,
                                        notchedOutline: classes.notchedOutline,
                                        input: classes.inputText
                                    }}
                                    inputProps={{
                                        'aria-label': 'address',
                                        placeholder: 'Your Message',
                                        className: classes.placeholder,
                                    }}
                                    value={this.state.message}
                                    onChange={this.handleChangeMessage}
                                    data-test-id={"message"}

                                />
                                <Typography className={classes.contactFontStyle}>{configJSON.contactus}</Typography>
                                <OutlinedInput
                                    id="outlined-adornment-weight"
                                    inputProps={{
                                        'aria-label': 'address',
                                        placeholder: 'Enter Link',
                                        className: classes.placeholder,
                                    }}
                                    classes={{
                                        root: classes.outlinedInput,
                                        focused: classes.focused,
                                        notchedOutline: classes.notchedOutline,
                                        input: classes.inputText
                                    }}
                                    value={this.state.contactuslink}
                                    onChange={this.handleChangeContactuslink}
                                    data-test-id={"contactuslink"}

                                />
                            </Box>

                            <Box className={classes.boxWidth_4}>

                                <Box className={classes.imagefileBtnWrapper}>
                                    <label htmlFor="upload-document"
                                        className={classes.addimagebtnintegration}>
                                        <img src={plusIcon} alt="AddIConWhite" />
                                        <Typography className={classes.mediaFontStyle}>Add Media</Typography>
                                    </label>
                                    <input
                                        type="file"
                                        style={{ display: "none" }}
                                        accept=".pdf,.doc,.docx,.xlsx"
                                        onChange={this.onFileUploadIntegration}
                                        data-testid={"inputrefdocument"}
                                        id="upload-document"
                                    />
                                </Box>
                            </Box>

                            <Box className={classes.sendBtnBox}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    className={classes.sendButton}
                                    data-test-id={"submitcontactus"}
                                    onClick={this.handleSubmitContactUsIntegration}
                                >
                                    {configJSON.send}
                                </Button>
                            </Box>
                        </Box>
                    </Dialog>

                    <CustomFormFeedback data-test-id="closefeedback" isFeedback={this.state.isFeedback} handleCloseFeedbackModal={this.handleCloseFeedbackModal} handleSubmitFeedback={this.handleSubmitFeedbackIntegration} handleFeedbackEmailChange={this.handleFeedbackEmailChange} handleFeedbackMessageChange={this.handleFeedbackMessageChange} closeIcon={CloseICon} isValidEmail={this.state.isValidEmail} />

                </Box>
            </Box>
        );
        // Customizable Area End
    }
}
// Customizable Area Start

export const userStyles: any = (theme: Theme) => ({
    boxWrapperStyle: {
        margin: '33px 46px 50px 45px',
        border: '2px solid #C8C9CA',
        padding: '40px 5% 40px 5%',
        borderRadius: '6px',

        [theme.breakpoints.down('md')]: {
            margin: "0",
        },
    },
    backdropStyle: {
        zIndex: 1200,
        color: '#fff',
    },
    buttonBox: {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        width: '45%',

        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
    },

    BoxFeild: {
        width: "100%",
        padding: '15px',
        border: '2px solid #CBD5E1',
        borderRadius: '8px',
        backgroundColor: '#FAF5FF',
        display: 'flex',
        gap: '30px',
    },
    boxFeild_2: {
        display: 'flex',
        gap: '10px',
        justifyContent: 'center',
        alignItems: 'center'
    },

    boxFeild_3: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '6%',
        [theme.breakpoints.down('md')]: {
            flexDirection: "column",
            alignItems: "unset",
            gap: "20px",

        },
    },
    integrationTextStyle: {
        fontSize: '20px',
        fontWeight: 700,
        color: '#2D2E2F',
        fontFamily: 'Poppins'
    },
    plusicon: {
        height: '20px',
        width: '20px'
    },
    mediaFontStyle: {
        fontSize: '16px',
        fontWeight: 700,
        color: '#B300ED',
        fontFamily: 'Inter',
        textTransform: 'initial' as const,
    },
    contentBoxStyle: {
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        gap: '8px'
    },

    contentBoxStyle_1: {
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        gap: '15px',
        padding: '30px 10% 30px 10%'
    },

    outlinedInput: {
        '&$focused $notchedOutline': {
            borderColor: '#DE76FF',
        },
        '&:hover $notchedOutline': {
            borderColor: '#DE76FF',
        },
        backgroundColor: '#FAF5FF',
        borderRadius: '8px',
    },

    sendBtnBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '8%',
        marginBottom: '8%'
    },

    outlinedInput_1: {
        '&$focused $notchedOutline': {
            borderColor: '#DE76FF',
        },
        '&:hover $notchedOutline': {
            borderColor: '#DE76FF',
        },
        borderRadius: '8px',
    },

    focused: {},
    notchedOutline: {
        borderWidth: '1px',
    },
    placeholder: {
        fontWeight: 400,
        fontSize: "14px",
        borderRadius: '8px',
        fontFamily: "Poppins",
    },

    inputText: {
        fontWeight: 400,
        fontSize: "16px",
        borderRadius: '8px',
        fontFamily: "Poppins",
        color: '#000000'
    },

    contactUsFont: {
        fontWeight: 700,
        fontSize: "28px",
        fontFamily: "Poppins",
        color: '#0F172A',
        textAlign: 'center' as const
    },

    boxWidth: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        gap: '30px',
        marginTop: '3%'
    },
    boxWidth_3: {
        width: '58%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '5%',
        [theme.breakpoints.down('md')]: {
            width: "100%",
            gap: '20px',
        },
    },
    boxWidth_4: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '0px 10% 0PX 10%'
    },
    boxWidth_2: {
        width: '50%',
        marginTop: '3%',

        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
    },
    fontStyle1: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#000000',
        fontFamily: 'Poppins',
        background: 'none',
    },
    fontStyle6: {
        fontSize: '14px',
        fontWeight: 600,
        color: '#000000',
        fontFamily: 'Poppins'
    },
    fontStyle2: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#737373',
        fontFamily: 'Poppins'
    },
    fontStyle7: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#707070',
        fontFamily: 'Poppins',
        lineHeight: '21px'
    },
    fontStyle5: {
        fontSize: '16px',
        fontWeight: 500,
        color: '#262424',
        fontFamily: 'Poppins'
    },

    iconStyle: {
        height: '20px',
        width: '20px'
    },
    fontStyle3: {
        fontSize: '14px',
        fontWeight: 500,
        color: '#000000',
        fontFamily: 'Poppins'
    },
    fontStyle4: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#6200EA',
        fontFamily: 'Poppins',
        cursor: "pointer"
    },

    acceptBtn: {
        backgroundColor: "#B300ED",
        fontSize: "16px",
        fontWeight: 400,
        fontFamily: "Poppins",
        color: "#FFFFFF",
        width: '241px',
        height: "55px",
        textWrap: "nowrap",
        padding: '10px 0px',
        borderRadius: '8px',
        textTransform: 'initial' as const,
        '&:hover': {
            backgroundColor: "#B300ED",
        },
        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
    },
    icon_style: {
        borderRadius: 6,
        width: 24,
        height: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        border: '1px solid #64748B',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            background: 'rgba(206,217,224,.5)',
            boxShadow: 'none',
        },
    },
    checkedIcon_style: {
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        backgroundColor: '#8833FF',
        '&:before': {
            display: 'block',
            width: 20,
            height: 20,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#B300ED',
        },
    },
    sendButton: {
        backgroundColor: "#B300ED",
        fontSize: "18px",
        fontWeight: 400,
        fontFamily: "Poppins",
        color: "#FFFFFF",
        width: '65%',
        padding: '5px 0px',
        borderRadius: '8px',
        textTransform: 'initial' as const,
        '&:hover': {
            backgroundColor: "#B300ED",
        }
    },

    declineBtn: {
        backgroundColor: "#FFFFFF",
        fontSize: "18px",
        fontWeight: 400,
        fontFamily: "Poppins",
        color: "#B300ED",
        width: '30%',
        borderColor: '#B300ED',
        borderRadius: '8px',
        padding: '10px 0px',
        textTransform: 'initial' as const,
        '&:hover': {
            backgroundColor: "#FFFFFF",
        },
        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
    },
    addMoreBtn: {
        borderColor: '#B300ED',
        width: '35%',
        borderRadius: '10px',
        '&:hover': {
            borderColor: '#B300ED',
        }
    },
    buttonBoxStyle: {
        display: 'flex',
        gap: '10px',
        justifyContent: 'flex-end',
        alignItems: 'center',

    },

    dialogStyles: {
        overflow: 'hidden',
        justifyContent: 'center',
        width: '529px',
        borderRadius: '8px 8px 32px 8px',
        backgroundColor: ' #FFFFFF',
        display: 'flex',
        alignItems: 'center',
    },

    dialogBoxStyle: {
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
        marginBottom: '40px',
        paddingTop: '30px'
    },

    rightTickIcon: {
        height: '65px',
        width: '65px',
    },

    buttonStyle: {
        fontSize: '16px' as const,
        fontWeight: 400,
        fontFamily: 'Poppins',
        color: '#FFFFFF',
        backgroundColor: '#B300ED',
        textTransform: 'initial' as const,
        borderRadius: '8px',
        padding: '8px 32px 8px 32px',
        '&:hover': {
            backgroundColor: "#B300ED",
        },
        [theme.breakpoints.down('sm')]: {
            width: "unset"
        },
    },

    welcomeFontStyle: {
        fontSize: '24px',
        fontWeight: 700,
        fontFamily: 'Poppins',
        lineHeight: '32px',
        textAlign: 'center' as const,
        color: '#0F172A'
    },

    contactFontStyle: {
        fontSize: '14px',
        fontWeight: 500,
        fontFamily: 'Poppins',
        lineHeight: '24px',
        textAlign: 'justify' as const,
        color: '#262424'
    },

    BoxScrollbar: {
        height: '100%',
        overflowX: 'hidden' as const,
        overflowY: 'scroll' as const,
        scrollbarColor: "#D6D6D6 transparent",
        "&::-webkit-scrollbar": {
            width: 4,
            height: 6,
            padding: '30px',
            backgroundColor: "#D6D6D6",
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "#D6D6D6",
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#B300ED",
            borderRadius: 6,
            minHeight: 24,
            minWidth: 14,
        },
        "&::-webkit-scrollbar-thumb:active": {
            background: "#B300ED",
        },
        "&::-webkit-scrollbar-thumb:focus": {
            background: "#B300ED",
        },

        "&::-webkit-scrollbar-thumb:hover": {
            background: "#B300ED",
        },
        "&::-webkit-scrollbar-corner": {
            backgroundColor: "#B300ED",
        },
    },

    contactUsDialog: {
        overflow: 'hidden',
        justifyContent: 'center',
        width: '600px',
        borderRadius: '8px 8px 32px 8px',
        backgroundColor: ' #FFFFFF',
        display: 'flex',
        marginTop: '5%',
        padding: '10px',
        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
    },
    cancelGridStyle: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end'
    },

    closeIconStyle: {
        cursor: 'pointer',
        height: '32px',
        width: '32px',
        padding: '5px'
    },

    autocomplete: {
        backgroundColor: "#f6f0ff",
        padding: "15px 5px",
        borderRadius: '8px',
        border: "1px solid  #CBD5E1",
        marginBottom: "42px",
        fontFamily: "Poppins",
        '& .MuiAutocomplete-inputRoot-251[class*="MuiFilledInput-root"]': {
            padding: "0",
            background: "transparent",
        },

        '& .MuiChip-deleteIcon-309': {
            color: "#2A0066",
        },
        '&:before': {
            borderBottom: "none",
            display: "none"
        },
        '& .MuiInputBase-fullWidth': {
            flexWrap: "wrap",
        },
        "& .MuiAutocomplete-inputFocused": {
            paddingLeft: "10px"
        },
        "& input::placeholder": {
            fontFamily: "Poppins",
            fontWeight: 400,
            paddingLeft: "10px",
            fontSize: "14",
        }
    },

    chip: {
        margin: "0 3px",
        border: "none",
    },
    emailIntegration: {
        fontFamily: "Poppins",
        fontWeight: 400,
        color: "#828589",
        fontSize: "14px",
        lineHeight: "normal",
        borderRadius: "6px",
        backgroundColor: "#FAF5FF",
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#DE76FF",
            backgroundColor: "#FAF5FF",
        },

        "& .Mui-focused": {
            border: "1px solid #CBD5E1",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#DDDDDD",
        },
        "& .MuiInputBase-input:focus": {
            borderTop: "1px solid #CBD5E1",
            outline: 'none',
            backgroundColor: "transparent",
        },
        "& input::placeholder": {
            fontSize: "14",
            fontWeight: 400,
            fontFamily: "Poppins",
        },
        "& .MuiOutlinedInput-root": {
            borderColor: "#DE76FF",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#DE76FF !important",
        },


    },
    errorBoxStyleIntegration: {
        marginTop: '2px',
        display: 'flex',
        gap: "10px",
    },
    addimagebtnintegration: {
        height: "44px",
        padding: "10px 16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        borderRadius: "8px",
        border: "1px solid #B300ED",
        background: "#FFF",
        color: "white",
        textTransform: "initial" as const,
        cursor: "pointer",
    },


});

export default withStyles(userStyles)(IntegrationsPage)
// Customizable Area End