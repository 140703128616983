import React from "react";

// Customizable Area Start
import {
    Typography,
    Box,
    Avatar,
    List,
    ListItem,
    ListItemText,
    Divider,
    ListItemIcon,
    AppBar,
    Toolbar,
    Drawer,
    Hidden,
    IconButton,
    Theme,
    Backdrop,
    CircularProgress,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { logoImage, searchIcon, personImage, downArrow, personImage1 } from './assets';
import MenuIcon from "@material-ui/icons/Menu";

// Customizable Area End

// Customizable Area Start
import { createTheme } from '@material-ui/core';
import { ThemeProvider } from "@material-ui/core/styles"
import VendorAccountDetailsController, {
    Props, configJSON, mockListData, drawerWidth
} from "./VendorAccountDetailsController";
import VenderProfile from "./VenderProfile.web";
import PaymentHistory from "./PaymentHistory.web";
import ListVendor from "./ListVendor.web";
import PaymentMethod from "./PaymentMethod.web";
import Review from "./Review.web";
import AppBarPage from "../../../components/src/AppBar.web";
const theme = createTheme({
    overrides: {
      MuiListItem: {
        root: {
          '&:hover': {
            backgroundColor: 'green', 
            color: 'blue', 
          },
          '&.Mui-selected': {
            backgroundColor: 'none', 
          },
          "MuiListItem-root-272.Mui-selected, .MuiListItem-root-272.Mui-selected:hover" :{
            backgroundColor:"none"
          }
        },
      },
    },
  });
// Customizable Area End
export class VendorAccountDetails extends VendorAccountDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderSidebarAccountVendor = (classes: any, selectedOption: any) => {

        return (
            <Box className={classes.sidebar}>
                <Box className={classes.sidebarTop}>
                    <Avatar
                        alt="Profile Picture"
                        src={this.state.Username?.profile_image || personImage1}
                        className={classes.sidebarAvatar}
                    />
                    <Typography variant="subtitle1" className={classes.welcomeText1}>
                        {`Welcome ${this.state.Username?.full_name}` || configJSON.welcomeName}
                    </Typography>
                    <Typography variant="subtitle1" className={classes.venderText}>
                        {configJSON.vendorAccount}
                    </Typography>
                </Box>
                <Divider style={{ backgroundColor: '#D9D9D9',marginTop:"0.7rem",marginBottom:"0.7rem" }} />
                <ThemeProvider theme={theme}>
                <Box component="nav" style={{backgroundColor:"none"}}>
                    {mockListData.map((item) => (
                        <Box
                            style={{
                                display: "flex", justifyContent: "center",
                                alignItems: "center", padding: "1rem", marginBottom: "-2rem"
                            }}
                            className={`${styles.listItem} ${selectedOption === item.text ? styles.listItemSelected : ''}`}
                            key={item.id}
                            data-testId={`listItem${item.id}`}
                            onClick={() => this.handleOptionSelect(item.text)}

                        >
                            <ListItemIcon style={{
                                color: 'rgba(0, 0, 0, 0.54)',
                                display: 'inline-flex',
                                minWidth: '19px',
                                flexShrink: 0,
                                cursor:"pointer"
                            }}>
                                <img src={item.icon} alt={item.text} className={classes.listIconsStyle} />
                            </ListItemIcon>
                            <ListItemText primary={item.text}
                                onClick={() => this.handleClick(item.id)}
                            style={{
                                flex: '1 1 auto',
                                minWidth: '0',
                                marginTop: '4px',
                                marginBottom: '4px',
                                backgroundColor: this.state.selectedId === item.id ? '#F6F0FF' :  '',
                                position: 'relative',
                                left: '18px',
                                borderRadius:"8px",
                                padding:"8px 12px 8px 12px",
                                cursor:"pointer"
                               
                           }}
                                className={classes.listTextStyle}
                            />
                        </Box>
                    ))}
                </Box>
                </ThemeProvider>
            </Box>  
        )
    }

    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes }: any = this.props
        const { selectedOption } = this.state;

        // Customizable Area End
        return (
            // Customizable Area Start
            // Required for all blocks
            <>
                {this.state.isLoader ?
                    <Backdrop className={classes.backdrop} open={this.state.isLoader}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    :
                    <Box className={classes.root}>
                        <AppBarPage
                            // handleClick={this.goToLoginPage}
                            logoImg={logoImage}
                            serachIcon={searchIcon}
                            username={this.state.Username}
                            dummyicon={this.state.Username?.profile_image || personImage}
                            downarrow={downArrow}
                            data-testId={"signInBtn"}
                            searchDirectoryData={this.state.searchDirectoryData}
                            goToHomePage={this.navigateToHomeScreen}
                            handleSearchInput={this.handleSearchInput}
                            handleAdvancedSearch={this.handleAdvancedSearchNavigation}
                            handleHeaderNavigation={this.navigateToVendor}
                            handleUserSignout={this.handleUserSignout}
                            showDirectoryPopup={this.state.showDirectoryPopup}
                             handleSubmit={this.handleSubmitSearch}
                             handleDirectoryPopupClose={this.handleDirectoryPopupClose}
                           
                        />

                        <Box className={classes.gridContainerStyle}>
                            <div className={classes.rootDrawerVendor}>
                                <AppBar className={classes.appBarVendor}>
                                    <Toolbar className={classes.toolbarVendor}>
                                        <IconButton
                                            color="inherit"
                                            aria-label="open drawer"
                                            edge="start"
                                            onClick={this.handleDrawerToggle}
                                            className={classes.menuButtonVendor}
                                        >
                                            <MenuIcon />
                                        </IconButton>
                                    </Toolbar>
                                </AppBar>

                                <nav className={classes.drawerVendor} aria-label="mailbox folders">
                                    <Hidden smUp implementation="css">
                                        <Drawer
                                            variant="temporary"
                                            open={this.state.mobileOpen}
                                            onClose={this.handleDrawerToggle}
                                            classes={{
                                                paper: classes.drawerPaperVendor,
                                            }}
                                            ModalProps={{
                                                keepMounted: true,
                                            }}
                                        >

                                            {this.renderSidebarAccountVendor(classes, selectedOption)}

                                        </Drawer>
                                    </Hidden>
                                    <Hidden xsDown implementation="css">
                                        <Drawer
                                            classes={{
                                                paper: classes.drawerPaperDesktop,
                                            }}
                                            variant="permanent"
                                            open
                                        >
                                            {this.renderSidebarAccountVendor(classes, selectedOption)}
                                        </Drawer>
                                    </Hidden>
                                </nav>

                                <main className={classes.content}>
                                    <Box className={classes.mainContents}>
                                        {selectedOption === 'Account Details' && <VenderProfile navigation={this.props.navigation} id={""} />}
                                        {selectedOption === 'Listings' && <ListVendor navigation={this.props.navigation} id={""} data={undefined} route={undefined} />}
                                        {selectedOption === 'Payment History' && <PaymentHistory navigation={this.props.navigation} id={""} data={undefined} route={undefined} />}
                                        {selectedOption === 'Payment Method' && <PaymentMethod navigation={this.props.navigation} id={""} data={undefined} route={undefined} />}
                                        {selectedOption == "Reviews Submitted" && <Review navigation={this.props.navigation} id={""} classes={undefined} />}
                                    </Box>
                                </main>
                            </div>
                        </Box>
                    </Box>
                }
            </>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
const styles = {
    listItem: {
      backgroundColor: "none", 
      transition: "background-color 0.3s" 
    },
    listItemHovered: {
      backgroundColor: "none"
    },
    listItemSelected: {
      backgroundColor: "none" 
    },
    listItemIcon: {
      color: "rgba(0, 0, 0, 0.54)",
      display: "inline-flex",
      minWidth: "19px",
      flexShrink: 0
    },
    listItemText: {
      flex: "1 1 auto",
      minWidth: "0",
      marginTop: "4px",
      marginBottom: "4px",
      background: "red",
      padding: "4.2px",
      position: "relative",
      left: "14px"
    }
  };

export const userStyles: any = (theme: Theme) => ({

    root: {
        flexGrow: 1,
        backgroundColor: '#F6F0FF',
    },
    logoStyle: {
        marginLeft: '30px',
        height: '30px',
        width: '130px'
    },

    searchWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1,
    },
    search: {
        position: 'relative' as const,
        borderRadius: '10px',
        backgroundColor: '#f0f0f0',
        marginLeft: 0,
        width: '70%',
        display: 'flex',
        alignItems: 'center' as const,
    },
    searchIcon: {
        padding: '10px',
        color: 'black',
        position: 'absolute' as const,
        display: 'flex',
        alignItems: 'center' as const,
        justifyContent: 'center' as const,
        height: '100%'
    },
    inputRoot: {
        width: '100%',
    },
    inputInput: {
        width: '100%',
        padding: '12px 20px',
        paddingLeft: '40px',
        border: 'none',
        color: '#0F172A',
        fontSize: '14px',
        fontWeight: 400,
        fontFamily: "Poppins",
        '&::placeholder': {
            color: '#0F172A',
            fontSize: '14px',
            fontWeight: 400,
            fontFamily: "Poppins"
        },
    },

    appbar: {
        background: 'linear-gradient(90deg, rgb(123, 53, 196), rgb(143, 109, 243),rgb(194, 134, 241))',
        width: '100%'
    },
    avatarWrapper: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        marginRight: '30px',
        padding: '10px'
    },
    avatar: {
        marginRight: '8px',
        height: '56px',
        width: '56px'
    },

    welcomeText: {
        marginBottom: '0px',
        color: '#0F172A',
        fontSize: '14px',
        fontWeight: 500,
        fontFamily: "Poppins"
    },
    nameText: {
        color: '#0F172A',
        fontSize: '14px',
        fontWeight: 500,
        fontFamily: "Poppins"
    },
    downArrowIcon: {
        marginLeft: '4px',
        width: '10px',
        height: '8px'
    },
    nameAndArrow: {
        display: 'flex',
        alignItems: 'center',
    },

    gridContainerStyle: {
        display: 'flex',
        gap: 20,
        margin: '2vw 2vw 2vw 0vw',
        marginTop: '11%',
        [theme.breakpoints.down('sm')]: {
            margin: '2vw 0vw',

        },
        "@media(max-width: 1200px)": {
            marginTop:"13%",
          },
          "@media(max-width: 1000px)": {
            marginTop:"20%",
          },
          "@media(max-width: 950px)": {
            marginTop:"16%",
          },          
          "@media(max-width: 820px)": {
            marginTop:"15%",
          },
          "@media(max-width: 760px)": {
            marginTop:"16%",
          },
          "@media(max-width: 630px)": {
            marginTop:"20%",
          },
          "@media(max-width: 480px)": {
            marginTop:"30%",
        },
    },

    sidebar: {
        backgroundColor: '#FFFFFF',
        width: '23.4vw',
        height: '814px',
        [theme.breakpoints.down('sm')]: {
            backgroundColor: '#FFFFFF',
            width: '100%',
        },
    },
    mainContents: {
        width: '71vw',
        paddingLeft: '5px',

        [theme.breakpoints.down('md')]: {
            width: "70vw",
            paddingLeft: '20px',
        },
        [theme.breakpoints.down('sm')]: {
            width: "64vw",
            paddingLeft: '0',
        },
        [theme.breakpoints.down('xs')]: {
            width: "100vw",
            paddingLeft: '0',
        },

    },

    sidebarTop: {
        display: 'flex',
        flexDirection: 'column' as const,
        alignItems: 'center' as const,
        padding: '20px',
    },
    sidebarAvatar: {
        width: '140px',
        height: '140px',
        border: "4px solid #F0E5FF",
        background: "#FFF",
        borderRadius: "50%",
    },
    welcomeText1: {
        marginTop: '8px',
        fontSize: '18px' as const,
        fontWeight: 500,
        fontFamily: 'Poppins',
        color: '#262424'
    },

    venderText: {
        // marginTop: '8px',
        fontSize: '14px' as const,
        fontWeight: 400,
        fontFamily: 'Poppins',
        color: '#707070'
    },

    listIconsStyle: {
        width: '24px',
        height: '24px',
        marginLeft:"14px"
    },

    listTextStyle: {
        "& .MuiListItemText-primary": {
            fontFamily: 'Poppins',
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '24px',
            color: "#0F172A",
            cursor:"pointer"
        },
        "& .MuiTypography-body1-281" : {
            fontFamily: 'Poppins',
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '24px',
            color: "#0F172A",
            cursor:"pointer"
        }
        
       


    },

    rootDrawerVendor: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        [theme.breakpoints.up("sm")]: {
            gap: "20px",
            flexDirection: "row",
            width: "100%",
        },
    },

    drawerVendor: {
        [theme.breakpoints.up("sm")]: {
            flexShrink: 0,
        },
    },
    appBarVendor: {
        position: "absolute",
        top: "unset",
        boxShadow: "none",
        [theme.breakpoints.up("sm")]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
            margin: "0",
            height: "0",
        },
        [theme.breakpoints.down("sm")]: {
            backgroundColor: "transparent",
        },
    },
    menuButtonVendor: {
        color: "black",
        [theme.breakpoints.up("sm")]: {
            display: "none",
        },
    },

    drawerPaperVendor: {
        width: drawerWidth,
        border: "none",
        position: "unset",
       
    },
    drawerPaperDesktop: {
        border: "none",
        position: "unset",
        overflowX: "hidden" 
    },
    content: {
        flexGrow: 1,
        [theme.breakpoints.down('sm')]: {
            marginTop: "0",
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: "55px",
        },
    },
    toolbarVendor: {
        [theme.breakpoints.up("sm")]: {
            display: "none",
        },
    },
    backdrop: {
        zIndex: 1200,
        color: '#fff',
    },
});

export default withStyles(userStyles)(VendorAccountDetails)
// Customizable Area End
