Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.signinAPIEndPoint = "/bx_block_login/logins";
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";
exports.welcome = "Welcome Back !";
exports.credential = "Enter your credentials to continue";
exports.forget = "Forgot password?";
exports.login = "Login";
exports.donnthaveaccount = "Don't have account?";
exports.register = "Register";

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
exports.viewApiURL = "bx_block_roles_permissions/account_details/:account_detail_id/view_role"
exports.viewDepartmentApiURL = "account_block/departments"
exports.postBusinessSubCategory = "account_block/business_category"
exports.updateSurveyURL = "update_survey"
exports.typeOfBusiness = "account_block/type_of_business"
exports.getApiMethod = "GET"
exports.putApiMethod = "PUT"
exports.postApiMethod = "POST"
exports.passErrMsg = "Password must be 8 characters long and contain at least 1 uppercase, 1 lowercase, 1 number & 1 special character";
exports.emailErrMsg = "Email Not Valid";
exports.passNotMatch = "Your password is incorrect";
exports.correctemailMsg = "Please enter correct email address";
exports.spaceErrMsg = "Your password can't start or end with a blank space";
exports.requiPass = "password is incorrect";
exports.emailRequire = "Email is required";
exports.emailnotmatch = "Your email doesn't match";
exports.incorrectpass = "password is incorrect";
exports.loggedin = "Logged In Sucessfully";
exports.detailsAPIEndPoint = "/account_block/accounts/show_profile";

// Customizable Area End