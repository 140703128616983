import React from "react";
// Customizable Area Start
import "./index.css";
import { withStyles, createStyles, Theme, createTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import StarIcon from '@material-ui/icons/StarBorder';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CommonLandingHeader from "./CommonLandingHeader.web";
import { tick, downArrow } from "./assets";
export const theme = createTheme({});

// Customizable Area End
import RestaurantsLandingPageWebController, {
  Props,
  configJSON
} from "./LandingPageWebController.web";
import {
  topheaderlogo,
  illustration,
  marketingoption,
  marketingsolution,
  diversity_inclusion,
  privacy,
  eco,
  ethics,
  charity
} from "./assets";
import Grid from "@material-ui/core/Grid";
import PlanList from "../../../components/src/PlanList.web";
class VendorLandingPage extends RestaurantsLandingPageWebController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { classes } = this.props;

    const displayTextResturantPage = () => {
      return (

        <Box className={classes.bannerContentBox}>
          <Typography variant="h4" gutterBottom className={classes.bannerContentHeading}>
            {this.state.vendorPageData.heading.title}
          </Typography>
          <Typography variant="h6" gutterBottom className={classes.bannerContentSubHeading}>
          {this.state.vendorPageData.heading.subtitle}
          </Typography>
          <Button variant="contained" color="primary" className={`${classes.signinBtn} ${classes.bannerContentBtn}`}>
            Get Started
          </Button>
        </Box>
      )
    }

    return (
      <>
        <CssBaseline />
        <CommonLandingHeader
          classes={classes}
          goToLogin={this.goToLogin}
          displayTextResturantPage={displayTextResturantPage}
          buyerPageData={this.state.vendorPageData}
          handleProfileMenuOpen={this.handleProfileMenuOpen}
          handlePopoverClose={this.handlePopoverClose}
          popoverAnchorE1={this.state.popoverAnchorEl}
          username={this.state.Username}
          headerNavigation={this.handleHeaderNavigation}
          signout={this.handleUserSignout}
          goToHomePage={this.goToHomePage}
          downarrow={downArrow}
        />
        <Container maxWidth={false} className={classes.root}>
          <Grid container spacing={2} className={classes.marketingOption}>
            <Grid item xs={12} sm={12} md={5}>
              <img src={marketingoption} alt="Image" width="100%" className={classes.marketingOptionImg} />
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <Paper elevation={0} className={classes.marketingOptionPaper}>
                <Typography data-testId="marketinOptions" variant="h4" gutterBottom className={classes.marketingOptionHeading}>
                  {this.state.vendorPageData.too_many_marketting_option.title}
                </Typography>
                <Typography variant="h6" gutterBottom className={classes.marketingOptionSubHeading}>
                  {this.state.vendorPageData.too_many_marketting_option.subtitle}
                </Typography>
                <Typography variant="body1" className={classes.marketingOptionDiscription}>
                  {this.state.vendorPageData.too_many_marketting_option.main_text}
                </Typography>
                <Button variant="contained" color="primary" className={classes.marketingOptionBtn}>
                  Explore now
                </Button>
              </Paper>
            </Grid>
          </Grid>

          <Grid container spacing={2} className={`${classes.marketingOption} ${classes.marketingSolution}`}>
            <Grid item xs={12} sm={12} md={8}>
              <Paper elevation={0} className={classes.marketingOptionPaper}>
                <Typography data-testId="OneStopMarketing" variant="h4" gutterBottom className={classes.marketingOptionHeading}>
                  {this.state.vendorPageData.the_one_stop_marketing.title}
                </Typography>
                <Typography variant="h6" gutterBottom className={`${classes.marketingOptionSubHeading} ${classes.marketingSolutionSubHeading}`}>
                  {this.state.vendorPageData.the_one_stop_marketing.subtitle}
                </Typography>
                <Typography variant="body1" className={classes.marketingOptionDiscription}>
                  {this.state.vendorPageData.the_one_stop_marketing.main_text}
                </Typography>
                <Button variant="contained" color="primary" className={`${classes.marketingOptionBtn} ${classes.marketingSolutionBtn}`}>
                  Explore now
                </Button>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <img src={marketingsolution} alt="Image" width="100%" className={`${classes.marketingOptionImg} ${classes.marketingSolutionImg}`} />
            </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.marketingJourney}>
            <Grid item xs={12} sm={12} md={8} className={classes.marketingJourneyWrapperGrid}>
              <Typography variant="h4" gutterBottom className={classes.marketingOptionHeading}>
                {this.state.vendorPageData.marketing_journey.title}
              </Typography>
              <Typography variant="h6" gutterBottom className={classes.marketingOptionSubHeading}>
                {this.state.vendorPageData.marketing_journey.subtitle}
              </Typography>

              <div className={classes.marketingCardContainer}>
                <ArrowDropUpIcon
                  className={classes.arrowUpStyle}

                />
                <ArrowDropDownIcon
                  className={classes.arrowDownStyle}
                />

                <Grid container className={classes.marketingJourneyCircleWrapper} >
                  <Grid item xs={12} sm={5} className={classes.marketingStepOneContainer}>
                    <div className={`${classes.marketingPointCircle} ${classes.marketingPointCircleOne}`} />
                    <hr className={classes.marketingStepOneLine} />
                    <Box className={`${classes.marketingStepOneCircle} ${classes.marketingStepOneCircleOne}`} >
                      <Typography variant="body1" className={classes.marketingCircleTitle}>
                        {this.state.vendorPageData.marketing_journey.circle_one.main_topic}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={7} className={classes.marketingStepOneDetailContainer}>
                    <Typography variant="body1" className={classes.marketingStepOneTitle}>
                      {this.state.vendorPageData.marketing_journey.circle_one.main_text}
                    </Typography>
                    <Typography variant="body1" className={classes.marketingStepOneDescription}>
                      {this.state.vendorPageData.marketing_journey.circle_one.description}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container className={classes.marketingJourneyCircleWrapper} >
                  <Grid item xs={12} sm={5} className={classes.marketingStepOneContainer}>
                    <div className={`${classes.marketingPointCircle} ${classes.marketingPointCircleTwo}`} />
                    <hr className={classes.marketingStepOneLine} />
                    <Box className={`${classes.marketingStepOneCircle} ${classes.marketingStepOneCircleTwo}`} >
                      <Typography variant="body1" className={classes.marketingCircleTitle}>
                        {this.state.vendorPageData.marketing_journey.circle_two.main_topic}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={7} className={classes.marketingStepOneDetailContainer}>
                    <Typography variant="body1" className={classes.marketingStepOneTitle}>
                      {this.state.vendorPageData.marketing_journey.circle_two.main_text}
                    </Typography>
                    <Typography variant="body1" className={classes.marketingStepOneDescription}>
                      {this.state.vendorPageData.marketing_journey.circle_two.description}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container className={classes.marketingJourneyCircleWrapper} >
                  <Grid item xs={12} sm={5} className={classes.marketingStepOneContainer}>
                    <div className={`${classes.marketingPointCircle} ${classes.marketingPointCircleThree}`} />
                    <hr className={classes.marketingStepOneLine} />
                    <Box className={`${classes.marketingStepOneCircle} ${classes.marketingStepOneCircleThree}`} >
                      <Typography variant="body1" className={classes.marketingCircleTitle}>
                        {this.state.vendorPageData.marketing_journey.circle_three.main_topic}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={7} className={classes.marketingStepOneDetailContainer}>
                    <Typography variant="body1" className={classes.marketingStepOneTitle}>
                      {this.state.vendorPageData.marketing_journey.circle_three.main_text}
                    </Typography>
                    <Typography variant="body1" className={classes.marketingStepOneDescription}>
                      {this.state.vendorPageData.marketing_journey.circle_three.description}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container className={classes.marketingJourneyCircleWrapper} >
                  <Grid item xs={12} sm={5} className={classes.marketingStepOneContainer}>
                    <div className={`${classes.marketingPointCircle} ${classes.marketingPointCircleFour}`} />
                    <hr className={classes.marketingStepOneLine} />
                    <Box className={`${classes.marketingStepOneCircle} ${classes.marketingStepOneCircleFour}`} >
                      <Typography variant="body1" className={classes.marketingCircleTitle}>
                        {this.state.vendorPageData.marketing_journey.circle_four.main_topic}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={7} className={classes.marketingStepOneDetailContainer}>
                    <Typography variant="body1" className={classes.marketingStepOneTitle}>
                      {this.state.vendorPageData.marketing_journey.circle_four.main_text}
                    </Typography>
                    <Typography variant="body1" className={classes.marketingStepOneDescription}>
                      {this.state.vendorPageData.marketing_journey.circle_four.description}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4} />
          </Grid>

          <Grid container spacing={2} className={`${classes.marketingOption} ${classes.companyQualities}`}>
            <Grid item xs={12} sm={12} md={9}>
              <Paper elevation={0} className={classes.marketingOptionPaper}>
                <Typography variant="h4" gutterBottom className={classes.marketingOptionHeading}>
                  {this.state.vendorPageData.celebrating_dieference_makers.title}
                </Typography>
                <Typography variant="h6" gutterBottom className={classes.marketingOptionSubHeading}>
                  {this.state.vendorPageData.celebrating_dieference_makers.subtitle}
                </Typography>
                <Typography variant="body1" className={classes.marketingOptionDiscription}>
                  {this.state.vendorPageData.celebrating_dieference_makers.main_text}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={3} />
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <Card className={`${classes.companyQualityCard} ${classes.companyQualityCardOne}`} >
                <div className={classes.companyQualityCardImgContainer}>
                  <img src={diversity_inclusion} alt="Image" width="100%" className={classes.companyQualityCardImg} />
                </div>
                <CardContent className={classes.companyQualityCardContent}>
                  <Typography variant="h6" component="div" className={classes.companyQualityCardHeading}>
                    {this.state.vendorPageData.celebrating_dieference_makers?.sub_section_one.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" className={classes.companyQualityCardDiscription}>
                    {this.state.vendorPageData.celebrating_dieference_makers?.sub_section_one.text}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Card className={`${classes.companyQualityCard} ${classes.companyQualityCardTwo}`} >
                <div className={classes.companyQualityCardImgContainer}>
                  <img src={privacy} alt="Image" width="100%" className={classes.companyQualityCardImg} />
                </div>
                <CardContent className={classes.companyQualityCardContent}>
                  <Typography variant="h6" component="div" className={classes.companyQualityCardHeading}>
                    {this.state.vendorPageData.celebrating_dieference_makers?.sub_section_two.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" className={classes.companyQualityCardDiscription}>
                    {this.state.vendorPageData.celebrating_dieference_makers?.sub_section_two.text}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Card className={`${classes.companyQualityCard} ${classes.companyQualityCardThree}`} >
                <div className={classes.companyQualityCardImgContainer}>
                  <img src={charity} alt="Image" width="100%" className={classes.companyQualityCardImg} />
                </div>
                <CardContent className={classes.companyQualityCardContent}>
                  <Typography variant="h6" component="div" className={classes.companyQualityCardHeading}>
                    {this.state.vendorPageData.celebrating_dieference_makers?.sub_section_three.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" className={classes.companyQualityCardDiscription}>
                    {this.state.vendorPageData.celebrating_dieference_makers?.sub_section_three.text}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Card className={`${classes.companyQualityCard} ${classes.companyQualityCardFour}`} >
                <div className={classes.companyQualityCardImgContainer}>
                  <img src={eco} alt="Image" width="100%" className={classes.companyQualityCardImg} />
                </div>
                <CardContent className={classes.companyQualityCardContent}>
                  <Typography variant="h6" component="div" className={classes.companyQualityCardHeading}>
                    {this.state.vendorPageData.celebrating_dieference_makers?.sub_section_four.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" className={classes.companyQualityCardDiscription}>
                    {this.state.vendorPageData.celebrating_dieference_makers?.sub_section_four.text}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Card className={`${classes.companyQualityCard} ${classes.companyQualityCardFive}`} >
                <div className={classes.companyQualityCardImgContainer}>
                  <img src={ethics} alt="Image" width="100%" className={classes.companyQualityCardImg} />
                </div>
                <CardContent className={classes.companyQualityCardContent}>
                  <Typography variant="h6" component="div" className={classes.companyQualityCardHeading}>
                    {this.state.vendorPageData.celebrating_dieference_makers?.sub_section_five.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" className={classes.companyQualityCardDiscription}>
                    {this.state.vendorPageData.celebrating_dieference_makers?.sub_section_five.text}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Grid container spacing={2} className={`${classes.marketingOption} ${classes.companyQualities} ${classes.subscriptionPricingWrapper}`}>
            <Grid item xs={12} sm={12} md={10}>
              <Paper elevation={0} className={classes.marketingOptionPaper}>
                <Typography variant="h4" gutterBottom className={classes.marketingOptionHeading}>
                  {this.state.vendorPageData.listing_subscription_pricing.title}
                </Typography>
                <Typography variant="h6" gutterBottom className={classes.marketingOptionSubHeading}>
                  {this.state.vendorPageData.listing_subscription_pricing.subtitle}
                </Typography>
                <Typography variant="body1" className={classes.marketingOptionDiscription}>
                  {this.state.vendorPageData.listing_subscription_pricing.main_text}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={2} />
          </Grid>

          <Box className={`${classes.restaurantSuppliers} `}>
            <Box className={`${classes.restaurantSuppliersRow} ${classes.restaurantSoftwareRow}`}>
              <Grid container className={classes.resturantSoftwareContent}>
                <Grid item lg={8} md={8} sm={8} >
                  <p style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',fontSize:"48px",color:'#000000',fontWeight:500 }}>
                    {this.state.buyerPageData.selling_res_software.title}
                  </p>
                  <Typography variant="body1" className={`${classes.restaurantSoftwareSubtitle} ${classes.softwareSubtitle}`}>
                   <span style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)'}}> {this.state.buyerPageData.selling_res_software.subtitle}</span>
                  </Typography>
                </Grid>
                <Grid item lg={4} md={4} sm={4} className={classes.restaurantSoftwareBtnWrap}>
                  <Button data-testId="joinUsBtn" onClick={this.navigateToJoinUs} className={classes.restaurantSoftwareBtn}>{configJSON.joinUs}</Button>
                </Grid>
                <Typography variant="body1" className={classes.restaurantSoftwareDetail}>
                  {this.state.buyerPageData.selling_res_software.main_text}
                </Typography>
              </Grid>
            </Box>
          </Box>

          <Container component="main" maxWidth={false} className={classes.subscriptionPricingContainer}>
            <Grid container spacing={5} alignItems="flex-end">
              {configJSON.subscriptions.map((item: any, i: any) => (
                <Grid item key={item.title} xs={12} md={4}>
                  <Card className={classes.subscriptions}>
                    <CardHeader
                      title={item.title}
                      subheader={item.description}
                      titleTypographyProps={{ align: 'center' }}
                      subheaderTypographyProps={{ align: 'center' }}
                      action={item.title === 'Pro' ? <StarIcon /> : null}
                      className={classes.subscriptionsCardHeader}
                      style={{ backgroundColor: item.theme_color }}
                    />
                    <Paper elevation={10} className={classes.subscriptionPaper}>
                      <CardContent className={classes.subscriptionsCardContent}>
                        <div className={classes.subscriptionsCardPricing}>
                          <Typography variant="h5" color="textPrimary" style={{ color: item.theme_color }}>
                            {item.price}
                          </Typography>
                          <Typography variant="h6" color="textSecondary" style={{ color: item.theme_color }}>
                            {item.time}
                          </Typography>
                        </div>
                        <ul className={classes.perksContainer}>
                          {item.perks.map((list: any, i: any) => (
                            <Typography component="li" variant="subtitle1" align="center" key={i} className={classes.subscriptionsPerks}>
                              <img src={tick} alt="Image" width="100%" className={classes.subscriptionsPerksImg} />
                              <Typography variant="subtitle1" className={classes.subscriptionsPerksTxt}>Lorem ipsum dolor sit.</Typography>
                            </Typography>
                          ))}
                        </ul>
                      </CardContent>
                      <CardActions className={classes.subscriptionsCardBtn}>
                        <Button variant="contained" color="primary" >
                          See more
                        </Button>
                      </CardActions>
                    </Paper>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Container >
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const vendorLandingPageStyle = (theme: Theme) => createStyles({
  root: {
    width: "100%",
    padding: '90px 60px 110px 60px',
    backgroundColor: "#fff",
    borderTop: "1px solid white",
    "@media(max-width: 480px)": {
      padding: '90px 10px 110px 10px',
    }
  },

  softwareSubtitle: {
    "@media(max-width: 700px)": {
      lineHeight: "1.5"
    }
  },

  restaurantSoftwareDetail: {
    color: "#333",
    marginTop: "42px",
    marginBottom: '30px',
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 300,
    lineHeight: "30px",
    padding: "0 0",
    fontFamily: "Poppins",
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)'
  },


  restaurantSoftwareBtn: {
    borderRadius: "12px",
    background: "#B300ED",
    color: "#FFF",
    fontSize: "32px",
    fontStyle: "normal",
    fontFamily: "Poppins",
    fontWeight: 500,
    lineHeight: "14px",
    width: "250px",
    display: "flex",
    border: 0,
    height: "84px",
    marginTop: "-12px",
    cursor: "pointer",
    boxShadow: "0px 6px 3px rgba(16, 7, 18, 0.23)",
    textTransform: "none" as "none",
    '&:hover': {
      backgroundColor: '#B300ED',
    },
  },

  restaurantSoftwareBtnWrap: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  },

  restaurantSoftwareSubtitle: {
    color: "#B300ED",
    fontFamily: "Poppins",
    fontSize: "36px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "26px",
    margin: "0 0",
    marginTop: "57px",
  },

  resturantSoftwareContent: {
    padding: "30px 70px",
    "@media(max-width: 700px)": {
      padding: "0 10px",
    }
  },

  restaurantSoftwareRow: {
    borderRadius: "32px",
    border: "2px solid #B300ED",
    background: "#FFF",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
  },

  restaurantSuppliers: {
    margin: "0px 0px 50px 0px",
    display: "block",
    fontFamily: "Poppins",
    "@media(max-width: 600px)": {
      margin: "40px 0",
    },
  },

  restaurantSoftwareTitle: {
    color: "#333",
    fontSize: "48px",
    fontStyle: "normal",
    fontFamily: "Poppins",
    fontWeight: 600,
    lineHeight: "80.956px",
    "@media(max-width: 992px)": {
      marginTop: "30px"
    },
    "@media(max-width: 700px)": {
      fontSize: "48px",
    },
    "@media(max-width: 606px)": {
      fontSize: "48px",
      lineHeight: "60px",
    }
  },

  bannerContentBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  downArrowIcon: {
    marginLeft: '4px',
    width: '10px',
    height: '8px',
    cursor: 'pointer',

    "@media (min-width: 300px) and (max-width: 950px)": {
      width: '5px',
      height: '5px',
    }
  },
  nameAndArrow: {
    display: 'flex',
    alignItems: 'center',
  },

  profilePaper: {
    width: "100%",
    maxWidth: "400px",
    padding: "20px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    display: 'flex',
    flexDirection: 'column' as const,
    gap: '15px' as const,
    borderRadius: '8px',
    backgroundColor: "#F8F2FF"
  },
  avatarWrapper: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginRight: '30px',
    padding: '10px',

    "@media (min-width: 300px) and (max-width: 950px)": {
      marginRight: '5px',
      padding: '3px',
    }
  },
  avatar: {
    marginRight: '8px',
    height: '56px',
    width: '56px',

    "@media (min-width: 300px) and (max-width: 950px)": {
      marginRight: '5px',
      height: '30px',
      width: '30px'
    }
  },
  fontsStyle: {
    color: '#0F172A',
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: "Poppins",
    cursor: "pointer"
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column' as const,
    marginLeft: '8px',
  },
  welcomeText: {
    marginBottom: '0px',
    color: '#0F172A',
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: "Poppins",

    "@media (min-width: 300px) and (max-width: 950px)": {
      fontSize: '5px',
      fontWeight: 300,
    }
  },
  nameText: {
    color: '#0F172A',
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: "Poppins",

    "@media (min-width: 300px) and (max-width: 950px)": {
      fontSize: '8px',
      fontWeight: 300,
    }
  },

  bannerContentHeading: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: '38px',
    fontFamily: "Poppins"
  },
  bannerContentSubHeading: {
    color: '#fff',
    fontSize: '28px',
    marginTop: '15px',
    marginBottom: '30px',
    fontFamily: "Poppins"
  },
  bannerContentBtn: {
    boxShadow: 'none'
  },
  marketingOption: {
    backgroundColor: '#f2faff',
    borderRadius: '30px',
    padding: '30px',
    '& .MuiGrid-item': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    marginBottom: '50px'
  },
  marketingOptionImg: {
    width: "350px",
    height: 'auto',
    "@media(max-width: 480px)": {
      width: "100%",
    },
  },
  marketingOptionHeading: {
    color: '#333',
    fontSize: '41px',
    fontWeight: 500,
    fontFamily: "Poppins",
    "@media(max-width: 480px)": {
      fontSize: '38px',
    },
  },
  marketingOptionSubHeading: {
    color: '#2f6fd1',
    fontSize: '30px',
    paddingBottom: '10px',
    paddingTop: '10px',
    fontWeight: 500,
    fontFamily: "Poppins",
    "@media(max-width: 480px)": {
      fontSize: '29px',
    },
  },
  marketingOptionDiscription: {
    color: '#333',
    fontSize: '25px',
    fontWeight: 'lighter',
    marginBottom: '30px',
    lineHeight: '45px',
    fontFamily: "Poppins",
    "@media(max-width: 480px)": {
      fontSize: '18px',
    },
  },
  marketingOptionBtn: {
    color: '#fff',
    backgroundColor: '#2f6fd1',
    fontSize: '14px',
    padding: '12px 30px',
    textTransform: 'initial',
    fontWeight: 500,
    fontFamily: "Poppins"
  },
  marketingSolution: {
    backgroundColor: '#fff3ed'
  },
  marketingSolutionSubHeading: {
    color: '#764326',
  },
  marketingSolutionBtn: {
    backgroundColor: '#764326',
  },
  marketingSolutionImg: {
    height: '350px',
  },
  searchIcon: {
    position: "absolute" as "absolute",
    top: "26px",
    left: "24px",
    width: "33px",
    display: "block",
    zIndex: 10,
    "@media(max-width: 480px)": {
      left: "10px",
    }
  },
  companyQualities: {
    backgroundColor: '#fff',
    marginBottom: '0px',
    paddingBottom: '0px',
  },
  companyQualityCard: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '20px',
    borderRadius: '25px',

    backgroundColor: '#e3b7db',
    color: '#fff',
    marginLeft: '50px',
    overflow: 'inherit',
    height: "100%",
    boxShadow: "none",
    "@media(max-width: 480px)": {
      marginLeft: '60px',
    },
    "@media(max-width: 380px)": {
      marginLeft: '50px',
    },
  },
  companyQualityCardOne: {
    backgroundColor: "#f2faff"
  },
  companyQualityCardTwo: {
    backgroundColor: "#fdf1eb"
  },
  companyQualityCardThree: {
    backgroundColor: "#f5fff2"
  },
  companyQualityCardFour: {
    backgroundColor: "#e9edfd"
  },
  companyQualityCardFive: {
    backgroundColor: "#fdf2ff"
  },
  landingAbout: {
    paddingTop: "100px",
    display: "block",

    maxWidth: "1440px",
    marginRight: "auto",
    marginLeft: "auto"
  },
  landingAboutTitle: {
    color: "#333",
    fontSize: "38px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "80.956px",
    margin: "0 0 42px 0",
    fontFamily: "Poppins",
    "@media(max-width: 760px)": {
      fontSize: "38px",
    },
  },
  companyQualityCardImgContainer: {
    backgroundColor: "#fff",
    borderRadius: '100px',
    border: '1px solid #000',
    marginLeft: '-50px',
    padding: '25px 25px'
  },
  companyQualityCardImg: {
    width: '80px',
    height: '80px',
    objectFit: 'contain',
    "@media(max-width: 380px)": {
      width: '60px',
      height: '60px',
    },
  },
  companyQualityCardContent: {
    padding: '40px 30px',
    paddingBottom: '40px !important',
  },
  landingBannerContent: {
    padding: "65px 0",
    display: "block",
  },


  landingHeaderTitle: {
    color: "#FFF",
    textAlign: "center" as "center",
    fontSize: "42.494px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "44.619px",
    "@media(max-width: 760px)": {
      fontSize: "35px",
    },
    "@media(max-width: 480px)": {
      fontSize: "30px",
    }
  },

  searchWrap: {
    position: "relative" as "relative",
    width: "65%",
    margin: "70px auto",
    maxWidth: "780px",
    "@media(max-width: 720px)": {
      width: "85%",
    },
    "@media(max-width: 470px)": {
      width: "100%",
    }
  },
  companyQualityCardHeading: {
    fontSize: '2rem',
    fontWeight: 600,
    marginBottom: '15px',
    color: "#333333",
    fontFamily: "Poppins",
    "@media(max-width: 480px)": {
      fontSize: '28px',
    },
  },
  landingAboutDetail: {
    color: "#313131",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 300,
    margin: 0,
    fontFamily: "Poppins",
    "@media(max-width: 760px)": {
      fontSize: "20px",
    },
    "@media(max-width: 480px)": {
      fontSize: "18px",
    }
  },
  companyQualityCardDiscription: {
    fontSize: '20px',
    lineHeight: '35px',
    color: '#313131',
    fontFamily: "Poppins",
  },
  subscriptions: {
    borderRadius: '15px',
    backgroundColor: '#f4f2f2'
  },
  row: {
    width: "100%",
    display: "block",
  },
  subscriptionsCardHeader: {
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
    padding: '40px 30px 100px 30px',
    '& .MuiCardHeader-title': {
      color: '#fff',
      fontWeight: 'bold',
      fontSize: '26px',
      fontFamily: "Poppins",
    },
    '& .MuiCardHeader-subheader': {
      color: '#fff',
      lineHeight: '35px',
      marginTop: '10px',
      fontSize: '16px',
      fontFamily: "Poppins",
    },
  },
  subscriptionsCardContent: {
    position: 'relative',
    paddingTop: '55px',
    marginBottom: '30px',
    display: 'flex',
    justifyContent: 'center'
  },
  signinBtn: {
    borderRadius: "8px",
    background: "#FFF",
    // padding: "11px 57px 11px 57px",
    color: "#9429C5",
    fontSize: "14px",
    fontStyle: "bold",
    fontWeight: 500,
    lineHeight: "14px",
    border: 0,
    cursor: "pointer",
    fontFamily: "Poppins",
    height: "36px",
    width: "162px",
    textTransform: "none" as "none",
    '&:hover': {
      backgroundColor: 'white',
    },
    "@media(max-width: 520px)": {
      height: "6.923vw",
      width: "31.154vw",
    }
  },
  subscriptionsCardPricing: {
    top: '-85px',
    width: '150px',
    height: '150px',
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    borderRadius: '80px',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#fff',
    '& .MuiTypography-h5': {
      fontWeight: 'bold',
      fontSize: '26px',
      fontFamily: "Poppins",
    },
    '& .MuiTypography-h6': {
      fontSize: '16px',
      fontFamily: "Poppins",
    },
  },
  searchWrapInput: {
    borderRadius: "19px",
    background: "#FFF",
    padding: "24px",
    display: "block",
    width: "100%",
    paddingLeft: "70px",
    border: 0,
    color: "#000",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "14px",
    fontFamily: "Poppins",
    "@media(max-width: 760px)": {
      padding: "20px",
      paddingLeft: "70px",
    },
    "@media(max-width: 580px)": {
      fontSize: "20px",
      paddingLeft: "60px",
    },
    "@media(max-width: 480px)": {

      paddingLeft: "40px",
    }
  },
  subscriptionsPerks: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  topheaderlogo: {
    height: "56px",
    width: "243px",
    "@media(max-width: 520px)": {
      height: "8.615vw",
      width: "37.5vw",
    }
  },
  subscriptionsPerksImg: {
    width: '22px',
    height: '18px',
    marginRight: '30px',
  },
  subscriptionsPerksTxt: {
    fontSize: '17px',
    lineHeight: '55px'
  },
  subscriptionsCardBtn: {
    justifyContent: 'center',
    paddingBottom: '65px',
    '& .MuiButton-contained': {
      backgroundColor: '#6cc9d1',
      padding: '6px 40px',
      borderRadius: '6px',
      textTransform: 'none',
      fontSize: '18px',
      boxShadow: 'none',
      fontFamily: "Poppins",
    },
  },
  landingBanner: {
    backgroundImage: `url(${require('../assets/banner.png')})`,
    backgroundPosition: "top",
    backgroundSize: "cover",
    fontFamily: "Open Sans",
    width: "100%",
    marginTop:"3%",
  },
  marketingJourney: {
    borderRadius: '30px',
    marginBottom: '50px',
    padding: '50px',
    paddingTop: '90px',
    "@media(max-width: 720px)": {
      padding: "20px"
    }
  },
  marketingCardContainer: {
    position: 'relative',
    marginTop: '50px'
  },
  marketingStepOneContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '10px',
    position: "relative"
  },
  landingHeaderRow: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  marketingPointCircle: {
    border: '1px solid #000',
    display: 'inline-block',
    boxShadow: 'inset 0px 0px 0px 4px #fff',
    borderRadius: '50%',
    padding: '20px',
    position: 'absolute',
    left: '-20px',
  },
  marketingStepOneLine: {
    width: '100%'
  },
  aboutContainer: {
    paddingLeft: "40px",
    paddingRight: "40px",
    paddingTop: "17px",
  },
  marketingStepOneCircle: {
    width: '200px',
    height: '200px',
    // border: '11px solid',
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '50px'
  },
  marketingStepOneDetailContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '30px'
  },
  container: {
    paddingLeft: "40px",
    paddingRight: "40px",
    paddingTop: "17px",
    "@media(min-width: 1280px)": {
      maxWidth: "none"
    }
  },
  marketingStepOneTitle: {
    fontWeight: 500,
    fontSize: '24px',
    fontFamily: "Poppins",
  },
  marketingStepOneDescription: {
    color: "#a69b9b",
    fontSize: '20px',
    fontFamily: "Poppins",
  },
  landingHeader: {
    fontFamily: "Poppins",
    maxWidth: "1440PX",
    marginLeft: "auto",
    marginRight: "auto",
  },

  searchIconimg: {
    width: "100%",
    "@media(max-width: 580px)": {
      width: "70%",
    }
  },
  landingAboutFull: {
    background:
      "linear-gradient(0deg, rgba(255,255,255,1) 28%, rgba(255,255,255,0) 100%)",

    width: "100vw"
  },
  illusImgGrid: {
    alignItems: "center",
    display: "flex",
    "@media(max-width: 600px)": {
      marginTop: "50px",
    }
  },
  landingAboutImage: {
    width: "100%",
  },
  perksContainer: {
    width: '100%',
    margin: '0',
    paddingLeft: '0',
    fontSize: '0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  marketingJourneyCircleWrapper: {
    borderLeft: '2px solid',
    padding: '20px 0px'
  },
  marketingCircleTitle: {
    fontWeight: 600,
    fontSize: '24px',
    color: '#2f6fd1',
    fontFamily: "Poppins",
  },
  marketingPointCircleOne: {
    backgroundColor: "#e3308d"
  },
  marketingPointCircleTwo: {
    backgroundColor: "#ff7f02"
  },
  marketingPointCircleThree: {
    backgroundColor: "#00b6bc"
  },
  marketingPointCircleFour: {
    backgroundColor: "#3e5c99"
  },
  marketingStepOneCircleOne: {
    border: "11px solid #e3308d"
  },
  marketingStepOneCircleTwo: {
    border: "11px solid #ff7f02"
  },
  marketingStepOneCircleThree: {
    border: "11px solid #00b6bc"
  },
  marketingStepOneCircleFour: {
    border: "11px solid #3e5c99"
  },
  marketingOptionPaper: {
    padding: '20px',
    backgroundColor: 'transparent'
  },
  subscriptionPaper: {
    marginTop: '20px',
    borderRadius: 'unset',
    boxShadow: 'none'
  },
  marketingJourneyWrapperGrid: {
    position: "relative"
  },
  arrowUpStyle: {
    position: 'absolute',
    left: '-11px',
    top: '-10px'
  },
  arrowDownStyle: {
    position: 'absolute',
    bottom: '-10px',
    left: '-11px'
  },
  subscriptionPricingWrapper: {
    marginTop: '70px'
  },
  subscriptionPricingContainer: {
    padding: "0px"
  }
});

export { VendorLandingPage }

export default withStyles(vendorLandingPageStyle)(VendorLandingPage);
// Customizable Area End
