import React from "react";

// Customizable Area Start
import {
    Box,
    Avatar,
    Tabs,
    Tab,
    Grid,
    Typography,
    Theme,
    Button,
    Dialog,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
// Customizable Area End

// Customizable Area Start
import ServicesCustomFormController, {
    Props,
    configJSON,
} from "./ServicesCustomFormController";
import { CloseICon, camerIconEdit,  downArrow, dummyIcon, edit_icon, errorIcon, logoImage, searchIcon } from "./assets";
import AppBarPage from "../../../components/src/AppBar.web"
import LinearProgressBar from "../../../components/src/LinearProgressBar.web";
import ServicesAboutPage from "./ServicesAboutPage.web";
import ServicesServicePage from "./ServicesServicePage.web";
import CustomDialogBox from "../../../components/src/CustomDialogBox.web";
import ServicesFilePage from "./ServicesFilePage.web";
import ServicesContactPage from "./ServicesContactPage.web"
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export class ServicesCustomForm extends ServicesCustomFormController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    componentDidMount = async () => {
        this.getCatlougeDetails();
        const storedData = await getStorageData("userdetails");
        const accountDetails = await JSON.parse(storedData);
        this.setState({ Username: accountDetails });
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes }: any = this.props
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box className={classes.root}>
                <AppBarPage
                    logoImg={logoImage}
                    serachIcon={searchIcon}
                    username={this.state.Username}
                    dummyicon={dummyIcon}
                    goToHomePage={this.homePageNavigation}
                    handleUserSignout={this.signoutUser}
                    data-testId={"signInBtn"}
                    downarrow={downArrow}
                    handleHeaderNavigation={this.headerNavigation}
                    showDirectoryPopup={this.state.showDirectoryPopup}
                    searchDirectoryData={this.state.searchDirectoryData}
                    handleAdvancedSearch={this.handleAdvancedSearch}
                    handleSubmit={this.handleSubmitSearch}
                    handleSearchInput={this.handleSearchInput}
                    handleDirectoryPopupClose={this.handleDirectoryPopupClose}
                />
                <Grid container className={classes.service_form_mainWapper}>
                    <Grid item xs={12} sm={12} md={12} lg={12} className={classes.custom_subWapperService}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <LinearProgressBar value={this.state.progressVal} />
                        </Grid>
                        <Grid container className={classes.subGridContainer}>
                            <Grid item xs={6} sm={6} md={6} lg={6}>
                                <Box className={classes.avatarBoxStyle} data-testid={"company_name"}>
                                    <Avatar alt="A&J" src={this.state.updateCatelougueDetails?.logo ? this.state.updateCatelougueDetails?.logo : "/static/images/avatar/1.jpg"} className={classes.avatarImage} />
                                    <Typography className={classes.tabBarHeader}>{this.state.updateCatelougueDetails?.name}</Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={6} sm={6} md={6} lg={6} container justifyContent="flex-end">
                                <img
                                    data-testid={"editIcon"}
                                    onClick={this.handleOpenEditModal}
                                    src={edit_icon}
                                    className={classes.editIconStyle}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.tabGrid}>
                                <Tabs
                                    value={this.state.value}
                                    onChange={this.handleChange}
                                    data-test-id="tabs"
                                    TabIndicatorProps={{ style: { height: '2px', backgroundColor: "#B300ED" } }}
                                    className={classes.tabs}
                                >
                                    <Tab value={0} label="About" {...this.a11yProps(0)} className={classes.tab} />
                                    <Tab value={1} label="Services"  {...this.a11yProps(3)} className={classes.tab} />
                                    <Tab value={2} label="Files" {...this.a11yProps(6)} className={classes.tab} />
                                    <Tab value={3} label="Contact"  {...this.a11yProps(7)} className={classes.tab} />
                                </Tabs>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} className={classes.subWapper_2}>
                        {this.state.value == 0 && <ServicesAboutPage  handleDataSaved={this.handleDataSaved} handleDataChanged={this.handleDataChanged}  navigation={this.props.navigation}  id={""} data-test-id="about" incrementProgress={this.incrementProgress} categoryId={this.state.categoryId}/>}
                        {this.state.value == 1 && <ServicesServicePage handleBack={this.handleBack} openSaveModal={this.state.formDataChanged}  handleOpenSaveModal={this.handleOpenSaveModal} handleDataSaved={this.handleDataSaved} handleDataChanged={this.handleDataChanged}  navigation={this.props.navigation}  id={""} data-test-id="services" incrementProgress={this.incrementProgress} />}
                        {this.state.value == 2 && <ServicesFilePage handleBack={this.handleBack}  openSaveModal={this.state.formDataChanged}  handleOpenSaveModal={this.handleOpenSaveModal} handleDataSaved={this.handleDataSaved} handleDataChanged={this.handleDataChanged}  navigation={this.props.navigation}   id={""} data-test-id="servicesFile" incrementProgress={this.incrementProgress} />}
                        {this.state.value == 3 && <ServicesContactPage handleBack={this.handleBack}   openSaveModal={this.state.formDataChanged}  handleOpenSaveModal={this.handleOpenSaveModal} handleDataSaved={this.handleDataSaved} handleDataChanged={this.handleDataChanged} navigation={this.props.navigation}  id={""} data-test-id="servicesContact" incrementProgress={this.incrementProgress} />}
                    </Grid>

                </Grid>
                <CustomDialogBox
                    icon={this.state.updateCatelougueDetails?.logo ? this.state.updateCatelougueDetails?.logo : "/static/images/avatar/1.jpg"}
                    srcImage={camerIconEdit}
                    openModal={this.state.isOpenEditModal}
                    name={this.state.updateCatelougueDetails?.name}
                    handleCloseOpenEditModal={this.handleCloseOpenEditModal}
                    handleChangeName={this.handleChangeName}
                    handleChangeLogo={this.handleLogoChange}
                    updateData={this.updateCatlouguetListingData}
                    data-testid={'open_modal'}
                />

                    <Dialog
                        open={this.state.openSaveModal}
                      
                        onClose={this.handleCloseSaveModal}
                        PaperProps={{ className: classes.dialogStyles }}
                        data-testId={"openErrorModalDialpg"}
                        maxWidth="md"
                        
                    >
                        <Grid container>
                            <Grid item xs={12} className={classes.cancelGridStyleSupplier}>
                                <img
                                    alt="deleteImg2"
                                    data-testid={"closeinstruction"}
                                    onClick={this.handleCloseSaveModal}
                                    src={CloseICon}
                                    className={classes.closeIconStyleSupplier}
                                />
                            </Grid>
                        </Grid>

                        <Box className={classes.dialogBoxStyle_2}>
                            <img
                                src={errorIcon}
                                className={classes.rightTickIcon}
                                alt="deleteIcon1"
                            />
                            <Typography className={classes.welcomeFontStyle}>{configJSON.goBackText}</Typography>
                           
                           
                            <Button
                                variant="contained"
                                onClick={this.handleCloseSaveModal}
                                fullWidth
                                className={classes.buttonStyle}
                            >
                                {configJSON.backText}

                            </Button>
                        </Box>
                    </Dialog>
            </Box>
        );
        // Customizable Area End
    }
}
// Customizable Area Start

export const userStyles = (theme:Theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#f8f2ff',
    },

    mainWapper: {
        background: "#FFFFFF",
        margin: '0 4%',
        width: 'auto',
        borderRadius: '6px',

    },
    subWapper: {
        borderRadius: "6px",
        display: "flex",
        flexDirection: "column" as const,
        padding: '1% 4%',
    },

    service_form_mainWapper: {
        // width: "1309px",
        margin: "0 auto",
        background: "#FFFFFF",
        marginBottom: "83px",
        marginTop: "11%",
        borderRadius: '6px',
        [theme.breakpoints.up('md')]: {
            width: "1309px",
        },
      
        [theme.breakpoints.down('md')]: {
          width: "100%",
        },
      
        [theme.breakpoints.down('sm')]: {
          width: "100%",
        },
    },

    custom_subWapperService: {
        display: "flex",
        flexDirection: "column" as const,
        borderRadius: "6px",
        padding: '1% 4%',
    },
    subGridContainer: {
        padding: '2% 2% 0',
        border: '1px solid  #D9D9D9',
        borderRadius: '6px'
    },

    tabGrid: {
        marginTop: '3%'
    },

    subWapper_2: {
        margin: '3% 4% 3%',
        padding: '1% 4%',
        border: '1px solid #C8C9CA',
        borderRadius: '6px'
    },

    avatarBoxStyle: {
        display: 'flex',
        gap: '20px',
        alignItems: 'center',
        marginLeft: '4%'
    },

    editIconStyle: {
        height: '24px',
        width: '24px'
    },

    tab: {
        textTransform: 'none' as const,
        margin: "0",
        fontFamily: "Poppins",
        fontSize: "18px",
        lineHeight: "26px",
        color: "#000000",
        fontWeight: 400,
        height: "50px",
        minWidth: "unset" as const,
        flex: "1",
    },
    tabs: {
        width: "50%",
        justifyContent: "space-between",

        '& .MuiTabs-flexContainer': {
            justifyContent: "space-between",
        },
        '& .Mui-selected': {
            color: "#B300ED",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "24px",
        },

        [theme.breakpoints.down('md')]: { 
            width: "100%",
            '&  > div':{
              display:'flex',
              overflowX:'scroll !important',
            },
            '&  > div::-webkit-scrollbar': {
              display: "none",
          }
          
        }
    },
    avatarImage: {
        display: "flex",
        width: "110px",
        height: "110px",
        justifyContent: "center" as const,
        alignItems: "center as const",
        border: "4px solid #F0E5FF",
        '& img':{
            objectFit: "fill" as  const 
        }
    },
    tabBarHeader: {
        color: "#000",
        fontFamily: "Poppins",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "24px"
    },
    rightTickIcon: {
        height: '75px',
        width: '75px'
    },

    dialogBoxStyle_2: {
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
        marginBottom: '40px',
        width: '70%'
    },
    dialogStyles: {
        overflow: 'hidden',
        justifyContent: 'center',
        width: '529px',
        borderRadius: '8px 8px 32px 8px',
        backgroundColor: ' #FFFFFF',
        display: 'flex',
        alignItems: 'center',
    },
    cancelGridStyleSupplier: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
    },
    buttonStyle: {
        fontSize: '16px' as const,
        fontWeight: 400,
        fontFamily: 'Poppins',
        color: '#FFFFFF',
        backgroundColor: '#B300ED',
        textTransform: 'initial' as const,
        borderRadius: '8px',
        padding: '8px 32px 8px 32px',
        '&:hover': {
            backgroundColor: "#B300ED",
        }
    },
    closeIconStyleSupplier: {
        height: '32px',
        padding: '5px',
        width: '32px',
        cursor: 'pointer',
    },
    welcomeFontStyle: {
        fontSize: '24px',
        fontWeight: 700,
        fontFamily: 'Inter',
        lineHeight: '32px',
        textAlign: 'center' as const,
        color: '#0F172A'
    },
});

export default withStyles(userStyles)(ServicesCustomForm)
// Customizable Area End