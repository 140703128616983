import React from "react";

import {
    Typography,
    Box,
    Avatar,
    Divider,
    // Customizable Area Start
    Grid,
    TextField,
    Button,
    Dialog,
    Slider,
    Tooltip,
    MenuItem,
    FormControl,
    Select,
    Popover,
    Paper,
    InputAdornment,
    withStyles,
    Theme,
    Backdrop,
    CircularProgress,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import AccountController, {
    Props, locationMockData, AccountDetailsI
} from "./AccountController.web";
import { editIcon, successCheckIcon, closeIcon } from './assets';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import ReactCountryFlag from 'react-country-flag'
import CloseIcon from '@material-ui/icons/Close';



export const styles: any = (theme: Theme) => ({
    background: {
        backgroundColor: '#FFFFFF',
        padding: '0px 40px 40px 0px',
        height:"100%",
        [theme.breakpoints.down('sm')]: {
            backgroundColor: '#FFFFFF', height: "100%",
            padding: '20px'
        },
    },
    dialogStyle: {
        overflow: 'hidden',
        maxWidth: "none", backgroundColor: '#FFFFFF',
        borderRadius: "8px 8px 32px 8px",

    },
    dialogStyleSuccess: {
        width: "529px",  height: "395px",
        borderRadius: "8px 8px 32px 8px",
        boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)",
        display: "flex", flexDirection: "column",
        alignItems: "center", textAlign: "center",
        gap: "24px",
    },
    successimg: {
        margin: "20px 0 21px 0"
    },
    successdesc: {
        textAlign: "center", fontSize: "24px",
        fontWeight: 700,lineHeight: "32px",
        letterSpacing: "-0.12px", marginBottom: "74px",
        fontFamily: "Inter",
    },
    backtoaccount2: {
        height: "56px", padding: "16px 151px",
        display: "flex", justifyContent: "center",
        alignItems: "center", gap: "8px",
        flexShrink: 0, backgroundColor: "#B300ED",
        color: "white",
        textTransform: "inherit",
        '&:hover': {
            backgroundColor: "#B300ED",
        },
        width: "100%",
        fontSize: "18px",
        fontWeight: 500,
    },
    backtoaccount: {
        height: "56px", 
        padding: "16px 151px",
        display: "flex", 
        justifyContent: "center",
        alignItems: "center", 
         gap: "8px",
        flexShrink: 0, 
        backgroundColor: "#B300ED",
        color: "white",
        textTransform: "inherit",
        '&:hover': {
            backgroundColor: "#B300ED",
        }
    },
    selected: {
        border: "1px solid #B300ED",
        color: "#B300ED"
    },
    dialogStyleReset: {
        width: "529px",
        height: "481px",
        borderRadius: "8px 8px 32px 8px",
        boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)",
        display: "flex",
        flexDirection: "column",
        scrollbarColor: "#D6D6D6 transparent",
        overflowY: 'scroll',
        overflowX: 'hidden',

        "&::-webkit-scrollbar": {
            width: 0,
        },
        [theme.breakpoints.down('sm')]: {
            height: "420px",
        }
    },
    resetheading: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: "48px",
        paddingRight: "14px",
    },
    boxreset: {
        margin: '34px 62px 55px 62px',
        [theme.breakpoints.down('sm')]: {
            margin: '20px',
        },

    },
    reset: {
        fontFamily: "Poppins",
        fontSize: "22px",
        fontWeight: 600,
        lineHeight: "80.956px",
    },
    resetButtons: {
        margin: '0 62px',
        display: 'flex',
        gap: "26px",
        [theme.breakpoints.down('sm')]: {
            margin: '0px',
            gap: "10px",
            justifyContent: "center",
        },
    },
    resetBtn: {
        height: "56px",
        padding: "16px 71px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        flexShrink: 0,
        backgroundColor: "#B300ED",
        textTransform: "inherit",
        color: "white",
        borderRadius: "8px",
        '&:hover': {
            backgroundColor: "#B300ED",
        },
        [theme.breakpoints.down('sm')]: {
            padding: "10px 45px",
        }
    },
    resetBtnOutlined: {
        height: "56px",
        padding: "16px 71px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        flexShrink: 0,
        borderColor: "#B300ED",
        textTransform: "inherit",
        color: "#B300ED",
        borderRadius: "8px",
        [theme.breakpoints.down('sm')]: {
            padding: "10px 45px",
        }

    },

    errorBoxStyle: {
        display: 'flex',
        marginTop: '2px',

        '& .MuiFormHelperText-root.Mui-error': {
            backgroundColor: "white",
            margin: 0,
            padding: "0 14px"
        }
    },

    errorIconStyle: {
        fontSize: '20px',
        margin: '3px 10px 0px -10px'
    },
    successTextColor: {
        color: "#D53333",
        fontSize: "16px",
        fontWeight: 400,
        fontFamily: "Poppins"
    },
    boxStyle_main_detail: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    boxStyle2: {
        marginTop: '10px',
        marginBottom: '30px',alignItems: 'center',
        display: 'flex',flexDirection:"row",gap:"10px",width:"100%",padding:"40px",
        [theme.breakpoints.down('sm')]: {
            display: "unset",
        },
        "@media(min-width:960px) and (max-width:1300px)": {
            paddingLeft: "20px",
            // gap: "15px",
        },
    },

    boxStyle3: {
        display: 'flex',
        width: '100%',
        gap: '70px',
        [theme.breakpoints.down('sm')]: {
            display: "unset",
        },
    },

    boxstyle3Textfield: {
        width: "45%",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        },
    },

    labelText: {
        color: '#454545',
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 400,
        whiteSpace: 'nowrap' as const,
        marginTop: '25px',
        marginBottom: "12px",
    },
    textfieldName: {
        width: "100%",
        backgroundColor: '#FAF5FF',
        marginTop: '15px',
        '& .MuiOutlinedInput-root': {
            borderRadius: "8px",
            fontFamily: 'Poppins',
        }

    },
    form: {
        width: "100%",
        borderRadius: '3px',
        backgroundColor: '#FAF5FF',
        '&.Mui-focused': {
            borderColor: '#DE76FF',
            outline: 'none'
        },
        '&.MuiOutlinedInput-notchedOutline': {
            borderColor: 'DDDDDD',
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: "8px",
            fontFamily: 'Poppins',
            color:"#454545",
        }
    },
    formText:{
        fontSize:"14px",fontWeight:400,fontFamily:"Poppins",lineHeight:"22px",
        color:"#0F172A"
    },
    blackText:{
        color:"#000000"
    },
    placeText:{color:"#828589"},
    ImgInputField: {
        display: "none"
    },
    dividerLine: {
        backgroundColor: '#D9D9D9'
    },
    boxStyleMargin: {
        width:"40%", [theme.breakpoints.down('sm')]: { width: "100%" },
    },
    boxStyleMarginSec: {
        width:"50%", [theme.breakpoints.down('sm')]: { width: "100%" },
    },
    roleBtn_Group: {
        display: "flex",
        gap: "1.125rem",
        flexWrap: "wrap" as const,
        marginTop: '15px',
        [theme.breakpoints.down("md")]: {
            gap: "1rem"
        }
    },
    roleBtn: {
        width: "max-content",
        height: '50px',
        padding: "2px",
        borderRadius: "8px",
        backgroundColor: "#FAF5FF",
        cursor: "pointer",
    },
    roleBtnText: {
        color: '#454545',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 400,
    },
    updateDetail: {
        color: "#000", fontFamily: "Poppins",
        fontSize: "22px", fontWeight: 600,
        lineHeight: "80.956px",
    },

    labelTextfeild: {
        width: "100%",
        borderRadius: '1px',
        backgroundColor: '#FAF5FF',
        marginTop: '25px'
    },

    inputPropsStyle: {
        display: 'flex',  alignItems: 'center'
    },

    sidebar_top: {
        display: 'flex', flexDirection: 'column' as const,
        alignItems: 'center' as const,
        padding: '20px',
    },
    sidebar_Avatar: {
        width: '140px', height: '140px',
        border: "4px solid #F0E5FF", background: "#FFF",
        borderRadius: "50%",
    },

    sidebarNav: {
        backgroundColor: '#FFFFFF', width: '100%',
        [theme.breakpoints.down('sm')]: { backgroundColor: '#FFFFFF',},
    },

    uploadBtn: {
        marginTop: '3vw',
        fontSize: '16px' as const,
        fontWeight: 400,
        fontFamily: 'Poppins',
        color: '#FFFFFF',
        backgroundColor: '#B300ED',
        textTransform: 'initial' as const,
        borderRadius: '8px',
        padding: "16px 42px",
        cursor: "pointer",
        whiteSpace:"nowrap" as "nowrap",
        width:"236px",
        height:"56px",
        '&:hover': {
            backgroundColor: "#B300ED",
        },
        [theme.breakpoints.down('md')]: {
            padding: "3px 10px",
        },
        [theme.breakpoints.down('sm')]: {
            padding: "16px 30px",

        },
    },

    actionButtonsDesktop: {
        display: 'flex', alignItems: 'center',
        justifyContent: 'space-between',
        gap:"15px",

        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    actionButtonsMobile: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'flex', alignItems: 'center',
            justifyContent: 'space-between',
        },
    },

    updateBtn: {
        marginTop: '3vw', fontSize: '18px' as const,
        fontWeight: 400,fontFamily: 'Poppins',
        color: '#FFFFFF',
        backgroundColor: '#B300ED',textTransform: 'initial' as const,
        borderRadius: '8px',
        padding: "16px 71px", minWidth: '44%',
        '&:hover': {
            backgroundColor: "#B300ED",
        },
        [theme.breakpoints.down('sm')]: {
            padding: "10px 20px",
        },
    },

    cancel_btn: {
        marginTop: '3vw',fontSize: '18px' as const,
        fontWeight: 400,fontFamily: 'Poppins',
        color: '#B300ED',backgroundColor: '#FFFFFF',
        textTransform: 'initial' as const,
        border: '1px solid #B300ED',
        borderRadius: '8px',minWidth: '44%',
        padding: "16px 71px",

        '&:hover': {
            backgroundColor: "#FFFFFF",
        },
        [theme.breakpoints.down('sm')]: {
            padding: "10px 20px",
        },
    },
    update_new_Btn: {
        marginTop: '45px',fontSize: '16px' as const,
        lineHeight:"24px",
        fontWeight: 400,fontFamily: 'Poppins',
        color: '#FFFFFF', backgroundColor: '#B300ED',
        textTransform: 'initial' as const,
        borderRadius: '8px',
        padding: "16px 71px", maxWidth: '201px',height:"56px",
        whiteSpace:"nowrap", textTranform:"capitalize",
        '&:hover': {
            backgroundColor: "#B300ED",
        },
        [theme.breakpoints.down('sm')]: {
            padding: "10px 20px",
        },
    },

    cancel_new_btn: {
        height:"56px",lineHeight:"50.96px",
        marginTop: '45px',
        fontSize: '18px' as const,
        fontWeight: 400, fontFamily: 'Poppins',
        color: '#B300ED', backgroundColor: '#FFFFFF',
        textTransform: 'initial' as const,
        border: '1px solid #B300ED',
        borderRadius: '6px',  maxWidth: '207px', padding: "16px 71px",
        whiteSpace:"nowrap",  textTranform:"capitalize",
        '&:hover': {
            backgroundColor: "#FFFFFF",
        },
        [theme.breakpoints.down('sm')]: {
            padding: "10px 20px",
        },
    },

    reset_Button: {
        marginTop: '2vw',
        fontSize: '16px' as const,
        fontWeight: 400, fontFamily: 'Poppins',color: '#6200EA',
        backgroundColor: '#F0E5FF', textTransform: 'initial' as const,
        borderRadius: '8px',  padding: "10px 16px",
        lineHeight:"24px", height:"44px",width:"157px",  whiteSpace:"nowrap" as "nowrap",
        boxShadow:"none",
        '&:hover': {  backgroundColor: "#F0E5FF",boxShadow:"none",
        }
    },

    detailsbox: {
        border: '1px solid #D5D5D5',   borderRadius: '16px',
        backgroundColor: '#FFFFFF',maxWidth:"100%",
        marginTop:"25px"
        
    },
    editIcon_Img: {
        height: '24px',
        width: '24px',
        padding: '2px',
        marginRight: '15px',
        cursor: 'pointer'
    },
    personalDetails: {
        fontFamily: 'Poppins', fontSize: '16px',
        fontWeight: 400, color: '#707070',
        padding: '10px', paddingLeft: '40px',
        "@media(min-width:960px) and (max-width:1300px)": {
            paddingLeft: "20px"
        },
    },
    textfield: {
        border: 'none',  borderBottom: 'none',
        fontFamily: 'Poppins',

    },

    TextField_Style: {
        marginTop: '15px', whiteSpace: "nowrap"
    },

    label: {
        color: '#707070', fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 400, letterSpacing: "1px",
        lineHeight: "14px",
    },

    value_text: {
        fontFamily: 'Poppins',  fontSize: '18px',
        fontWeight: 500, color: '#000000'
    },

    boxScroll_bar: {
        height: '100%', overflowX: 'hidden',
        overflowY: 'scroll', scrollbarColor: "#D6D6D6 transparent",
        padding: '40px 84px',
        [theme.breakpoints.down('sm')]: {
            padding: "20px",
        },

        "&::-webkit-scrollbar-thumb": {
            background: "#B300ED",
            borderRadius: 6,
            minWidth: 14,
            minHeight: 24
        },
        "&::-webkit-scrollbar": {
            width: 4,
            height: 6,
            backgroundColor: "#D6D6D6",
            padding: '30px',
        },
        "&::-webkit-scrollbar-thumb:active": {
            background: "#B300ED",
        },
        "&::-webkit-scrollbar-corner": {
            backgroundColor: "#B300ED",
        },

        "&::-webkit-scrollbar-thumb:hover": {
            background: "#B300ED",
        },
        "&::-webkit-scrollbar-thumb:focus": {
            background: "#B300ED",
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "#D6D6D6",
        },
    },

    sliderRange: {
        maxWidth: '39rem',
        display: 'flex',
        color: '#B300ED',
        marginTop: '10vw',
        alignItems: 'center',
        '& .MuiSlider-rail': {
            height: '8px',
            border: 'none',
            borderRadius: 0,
        },
        '& .MuiSlider-track': {
            height: '8px',
            border: 'none',
            borderRadius: '1px',
        },

        '& .MuiSlider-valueLabel': {
            top: '50%',
            fontSize: 12,
            background: 'unset',
            transform: 'translateY(50%)',
            padding: 0,
            width: 32,
            height: 32,
        },
        '& .MuiSlider-thumb': {
            backgroundColor: '#B300ED',
            height: '30px',
            width: '30px',
            border: '5px solid white',
            marginTop: '0',
            '&:before': {
                display: 'none',
            },
            '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                boxShadow: 'inherit',
            },

        },
        '& .MuiSlider-markLabel': {
            fontSize: '14px',
            fontFamily: 'Poppins',
            fontWeight: '400',
            top: '36px',
            color: 'black',
            lineHeight: '21px',
            [theme.breakpoints.down("md")]: {
                transform: 'translateX(-100%)'
            }
        },
    },

    styledTextField: {
        // root: {
        fontSize: "14px", borderRadius: "8px",
        fontFamily: 'Poppins', backgroundColor: "#FAF5FF",
        "& input::placeholder": {
            fontSize: "14px", fontFamily: "Poppins",
            fontWeight: 400,
        },
        "& .MuiInputBase-input-283:focus": {
            outline: 'none',backgroundColor: "transparent",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#DE76FF",  },
        "& .MuiOutlinedInput-notchedOutline": {borderColor: "#DDDDDD", },
        "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "#DE76FF"   },
        "&  .MuiFormHelperText-root.Mui-error": {  paddingLeft: 10, backgroundColor: "#FFFFFF",
            margin: 0,
        },
        // },
    },

    styledTextFieldPassword: {
        "&  .MuiFormHelperText-root.Mui-error": {
            paddingLeft: 10,
            backgroundColor: "#FFFFFF",
            margin: 0,
        },
    },
    backdrop: {
        zIndex: 1200,
        color: '#fff',

    },
    closeIconGridStyle: {
        display: 'flex', alignItems: 'center',
        justifyContent: 'flex-end',  padding: '10px 10px 0px 0px'
    },
    closeIconStyle: {
        height: '33px', width: '33px',
        padding: '10px', cursor: 'pointer'
    },
    textGridStyle: {
        display: 'flex',  alignItems: 'center',
        justifyContent: 'center', paddingTop: "45px"
    },
    cancleBox:{
        display: "flex",flexDirection: "row", 
        justifyContent: "space-between", 
        alignItems: "center"
    },

})

 const CustomTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#FAF5FF", color: 'black', 
        maxWidth: "133px", height: "48px",
        display: "flex", 
         justifyContent: "center",
        alignItems: "center",  fontFamily:"Poppins",fontSize:"14px",lineHeight:"21px",fontWeight:400,
        [theme.breakpoints.down('md')] : {
            maxWidth: "100px" ,
            height: "50px", 
        }, 
    }, 
}))(Tooltip);


export const ValueLabelComponent: React.FC<any> = (props) => {
    const { children, open, value } = props;

    return (
        <CustomTooltip open={open} interactive enterTouchDelay={0} title={`$ ${value}`} placement="top">
            {children}
        </CustomTooltip>
    );
}
// Customizable Area End



class Account extends AccountController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props
        const fullPhoneNumber = this.state.accountDetails?.full_phone_number;
        const formattedPhoneNumber = `+${fullPhoneNumber}`;
        const getValueOrDefault = (property: keyof AccountDetailsI, defaultValue = '') => this.state.accountDetails?.[property] || defaultValue;
        const formattedValue=Number(this.state.accountDetails.annual_revenue)?.toLocaleString();
        const annualRevenuereadonly = this.state.accountDetails.annual_revenue ? `$${formattedValue}` : "";
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                {this.state.isLoader ?
                    <Backdrop className={classes.backdrop} open={this.state.isLoader}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    :
                    <Grid container className={classes.background} >
                        <Grid item xs={12} md={4} lg={4} className={classes.sidebarNav}>
                            <Box className={classes.sidebar_top}>
                                <Avatar
                                    alt="Profile Picture"
                                    src={this.state.accountDetails?.profile_image}
                                    className={classes.sidebar_Avatar}
                                />
                                <input
                                    type="file"
                                    className={classes.ImgInputField}
                                    accept="image/*"
                                    onChange={this.onProfileChange}
                                    data-testid={"inputref"}
                                    id="upload-photo"
                                />
                                <label className={classes.uploadBtn} data-testid={"uploadbtn"} htmlFor="upload-photo">Upload new picture</label>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={8} lg={8}>
                            <Box className={classes.detailsbox}>
                                <Box className={classes.boxStyle_main_detail}>
                                    <Typography className={classes.personalDetails}>Personal Details</Typography>
                                    <img src={editIcon} className={classes.editIcon_Img} onClick={this.handleOpenDetails} data-testid={"editIcon"} />
                                </Box>
                                <Divider className={classes.dividerLine} />
                                <Box className={classes.boxStyle2}>
                                    <Box className={classes.boxStyleMargin}>
                                        <TextField
                                            id="name"
                                            label="Name"
                                            variant="outlined"
                                            InputProps={{
                                                classes: {
                                                    notchedOutline: classes.textfield,
                                                    input: classes.value_text,
                                                },
                                                readOnly: true,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                                style: {
                                                    color: '#707070',
                                                    fontFamily: 'Poppins',
                                                    fontSize: '20px',
                                                    fontWeight: 400,
                                                }
                                            }}
                                            value={getValueOrDefault('full_name')}
                                            className={classes.TextField_Style}
                                        />

                                        <TextField
                                            id="phonenumber"
                                            label="Telephone Number"
                                            variant="outlined"
                                            InputProps={{
                                                classes: {
                                                    notchedOutline: classes.textfield,
                                                    input: classes.value_text,
                                                },
                                                readOnly: true,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                                style: {
                                                    color: '#707070',
                                                    fontFamily: 'Poppins',
                                                    fontSize: '20px',
                                                    fontWeight: 400,
                                                }
                                            }}
                                            value={formattedPhoneNumber.length > 3 ? formattedPhoneNumber : ''}
                                            className={classes.TextField_Style}
                                        />

                                        <TextField
                                            id="role"
                                            label="Role Level"
                                            variant="outlined"
                                            InputProps={{
                                                classes: {
                                                    notchedOutline: classes.textfield,
                                                    input: classes.value_text,
                                                },
                                                readOnly: true,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                                style: {
                                                    color: '#707070',
                                                    fontFamily: 'Poppins',
                                                    fontSize: '20px',
                                                    fontWeight: 400,
                                                }
                                            }}
                                            value={this.state.accountDetails.role}
                                            className={classes.TextField_Style}
                                        />

                                        <TextField
                                            id="compant"
                                            label="Company"
                                            variant="outlined"
                                            InputProps={{
                                                classes: {
                                                    notchedOutline: classes.textfield,
                                                    input: classes.value_text,
                                                },
                                                readOnly: true,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                                style: {
                                                    color: '#707070',
                                                    fontFamily: 'Poppins',
                                                    fontSize: '20px',
                                                    fontWeight: 400,
                                                }
                                            }}
                                            value={getValueOrDefault('company_name')}
                                            className={classes.TextField_Style}

                                        />

                                        <TextField
                                            id="locations"
                                            label="Number Locations"
                                            variant="outlined"
                                            InputProps={{
                                                classes: {
                                                    notchedOutline: classes.textfield,
                                                    input: classes.value_text,
                                                },
                                                readOnly: true,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                                style: {
                                                    color: '#707070',
                                                    fontFamily: 'Poppins',
                                                    fontSize: '20px',
                                                    fontWeight: 400,
                                                }
                                            }}
                                            value={getValueOrDefault('no_of_location')}
                                            className={classes.TextField_Style}

                                        />
                                    </Box>

                                    <Box className={classes.boxStyleMarginSec}>
                                        <TextField
                                            id="email"
                                            label="Email"
                                            variant="outlined"
                                            InputProps={{
                                                classes: {
                                                    notchedOutline: classes.textfield,
                                                    input: classes.value_text,
                                                },
                                                readOnly: true,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                                style: {
                                                    color: '#707070',
                                                    fontFamily: 'Poppins',
                                                    fontSize: '20px',
                                                    fontWeight: 400,
                                                }
                                            }}
                                            value={this.state.accountDetails.email}
                                            className={classes.TextField_Style}
                                        />

                                        <TextField
                                            id="home"
                                            label="Home Location"
                                            variant="outlined"
                                            InputProps={{
                                                classes: {
                                                    notchedOutline: classes.textfield,
                                                    input: classes.value_text,
                                                },
                                                readOnly: true,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                                style: {
                                                    color: '#707070',
                                                    fontFamily: 'Poppins',
                                                    fontSize: '20px',
                                                    fontWeight: 400,
                                                }
                                            }}
                                            value={getValueOrDefault('city')}
                                            className={classes.TextField_Style}
                                        />

                                        <TextField
                                            id="department"
                                            label="Department"
                                            variant="outlined"
                                            InputProps={{
                                                classes: {
                                                    notchedOutline: classes.textfield,
                                                    input: classes.value_text,
                                                },
                                                readOnly: true,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                                style: {
                                                    color: '#707070',
                                                    fontFamily: 'Poppins',
                                                    fontSize: '20px',
                                                    fontWeight: 400,
                                                },
                                            }}
                                            value={getValueOrDefault('department_name')}
                                            className={classes.TextField_Style}
                                        />

                                        <TextField
                                            id="businesstype"
                                            label="Type of Business"
                                            variant="outlined"
                                            InputProps={{
                                                classes: {
                                                    notchedOutline: classes.textfield,
                                                    input: classes.value_text,
                                                },
                                                readOnly: true,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                                style: {
                                                    color: '#707070',
                                                    fontFamily: 'Poppins',
                                                    fontSize: '20px',
                                                    fontWeight: 400,
                                                }
                                            }}
                                            value={this.state.accountDetails.business_name}
                                            className={classes.TextField_Style}
                                        />

                                        <TextField
                                            id="annualrevenue"
                                            label="Average annual revenue per property"
                                            variant="outlined"
                                            InputProps={{
                                                classes: {
                                                    notchedOutline: classes.textfield,
                                                    input: classes.value_text,
                                                },
                                                readOnly: true,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                                style: {
                                                    color: '#707070',
                                                    fontFamily: 'Poppins',
                                                    fontSize: '20px',
                                                    fontWeight: 400,
                                                    whiteSpace: 'nowrap'
                                                }
                                            }}
                                            value={annualRevenuereadonly}
                                            className={classes.TextField_Style}

                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Button variant="contained" data-testid={"resetbutton"} onClick={this.handleOpenResetPassword} className={classes.reset_Button}>Reset Password</Button>
                        </Grid>
                    </Grid>
                }
                <Dialog
                    open={this.state.openDetailsModal}
                    onClose={this.handleCloseDetailsModal}
                    maxWidth="md"
                    PaperProps={{ className: classes.dialogStyle }}
                    data-testid={"openmodal"}
                >
                    <Box className={classes.boxScroll_bar}>
                        <Box className={classes.cancleBox}>
                            <Typography className={classes.updateDetail}>Update Details</Typography>
                            <CloseIcon style={{ alignSelf: "center" }} onClick={this.handleCloseDetailsModal} />
                        </Box>

                        <Box className={classes.boxStyle3}>
                            <Box className={classes.boxstyle3Textfield}>
                                <TextField
                                    variant="outlined"
                                    placeholder={'Name'}
                                    fullWidth
                                    data-testid={"namtextField"}
                                    name="name"
                                    value={this.state.updateDetails.name}
                                    onChange={this.handleUpdateDetailName}
                                    className={`${classes.textfieldName} ${classes.styledTextField}`}
                                    error={this.state.nameError}
                                    helperText={this.state.nameErrorText &&
                                        <Box className={classes.errorBoxStyle}>
                                            {<ErrorOutlineOutlinedIcon className={classes.errorIconStyle} color="error" />}
                                            <Typography style={{
                                                color: "#D53333",
                                                fontFamily: "Poppins",
                                                fontSize: "16px",
                                                fontWeight: 400,
                                            }}>
                                                {this.state.nameErrorText}
                                            </Typography>
                                        </Box>
                                    }
                                />
                                <TextField
                                    variant="outlined"
                                    data-testid={"emailTextfield"}
                                    placeholder={'Email'}
                                    fullWidth
                                    name="email"
                                    value={this.state.updateDetails.email}
                                    onChange={this.handleChangeEmail}
                                    className={`${classes.textfieldName} ${classes.styledTextField}`}
                                    error={this.state.emailErr}
                                    helperText={this.state.emailErrMsg &&
                                        <Box className={classes.errorBoxStyle}>
                                            {<ErrorOutlineOutlinedIcon className={classes.errorIconStyle} color="error" />}
                                            <Typography style={{
                                                color: "#D53333",
                                                fontFamily: "Poppins",
                                                fontSize: "16px",
                                                fontWeight: 400,
                                            }}>
                                                {this.state.emailErrMsg}
                                            </Typography>
                                        </Box>
                                    }
                                />
                                <Box>
                                    <TextField
                                        variant="outlined"
                                        data-testid={"changeNumber"}
                                        placeholder="Phone Number"
                                        fullWidth
                                        name="phonenumber"
                                        value={this.state.updateDetails.phonenumber}
                                        onChange={this.handleOnChangeDetail}
                                        className={classes.textfieldName}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start" onClick={this.handleOpenPopover} data-testid={"openpopover"}>
                                                    <Box className={classes.inputPropsStyle}>
                                                        {this.state.updateDetails.countryCode}<ExpandMoreIcon className={classes.expandIcon} />
                                                    </Box>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <Popover
                                        open={Boolean(this.state.openCountryCodeList)}
                                        anchorEl={this.state.openCountryCodeList}
                                        onClose={this.onClosePopOver}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "center",
                                        }}
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "center",
                                        }}
                                        style={{ left: '10%' }}
                                    >
                                        <Paper className={classes.paperStyle}>
                                            {this.state.counrtyCodeList?.map((countryCode: any, index: number) => (
                                                <MenuItem
                                                    key={index}
                                                    onClick={() => this.handleCountryCodeChange(countryCode?.country_code)}
                                                >
                                                    <ReactCountryFlag svg
                                                        countryCode={countryCode?.short_country_name}
                                                        style={{ marginRight: '15px', height: '16px', width: '25px' }}
                                                    />
                                                    {`${countryCode?.country_name}  (+${countryCode?.country_code})`}
                                                </MenuItem>
                                            ))}
                                        </Paper>

                                    </Popover>
                                </Box>
                                <TextField
                                    variant="outlined"
                                    data-testid={"password-test"}
                                    placeholder={'Home Location'}
                                    fullWidth
                                    name="city"
                                    value={this.state.updateDetails.city}
                                    onChange={this.handleOnChangeDetail}
                                    className={`${classes.textfieldName} ${classes.styledTextField}`}
                                />
                                <Typography className={classes.labelText}>Role Level</Typography>
                                <FormControl variant="outlined" className={classes.form}>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        name="roleLevel"
                                        value={this.state.updateDetails?.roleLevel ?? "none"}
                                        onChange={this.handleOnChangeDetail}
                                        data-testid={"changeRole"}
                                        className={this.handelDepart(classes.placeText, classes.blackText)}
                                        fullWidth
                                    >
                                        <MenuItem value="none" disabled style={{display:"none"}}>Select</MenuItem>
                                        {
                                            this.state.userRole?.map((item: any) => (
                                                <MenuItem value={item.id} key={item.id} className={classes.formText}>{item.name}</MenuItem>
                                            ))
                                        }

                                    </Select>
                                </FormControl>
                                <Typography className={classes.labelText}>
                                    Department
                                </Typography>
                                <FormControl variant="outlined" className={classes.form}>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        name="department"
                                        value={this.state.updateDetails?.department ?? "none"}
                                        onChange={this.handleOnChangeDetail}
                                        data-testid={"changeDepartment"}
                                        className={this.handelDepart(classes.blackText,classes.placeText)}
                                        fullWidth
                                    >
                                        <MenuItem value="none" disabled style={{display:"none"}}>Select</MenuItem>
                                        {
                                            this.state.userDepartment?.map((item: any) => (
                                                <MenuItem value={item.id} key={item.id} className={classes.formText}>{item?.attributes?.name}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                                <Box className={classes.actionButtonsDesktop}>
                                    <Button variant="contained" data-testid={"openupdatesuccess"} onClick={this.handleOpenSuccessUpdated} className={classes.update_new_Btn}>Update</Button>
                                    <Button variant="contained" data-testid={"closeupdatesucess"} onClick={this.handleCloseDetailsModal} className={classes.cancel_new_btn}>Cancel</Button>
                                </Box>
                            </Box>

                            <Box className={classes.boxstyle3Textfield}>
                                <TextField
                                    variant="outlined"
                                    data-testid={"password-test"}
                                    placeholder={'Company Name'}
                                    fullWidth
                                    name="companyName"
                                    value={this.state.updateDetails.companyName}
                                    onChange={this.handleOnChangeDetail}
                                    className={`${classes.textfieldName} ${classes.styledTextField}`}
                                />

                                <Typography className={classes.labelText}>
                                    Type of Business
                                </Typography>
                                <FormControl variant="outlined" className={classes.form}>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={this.state.updateDetails?.typeOfBusiness ?? "none"}
                                        name="typeOfBusiness"
                                        onChange={this.handleOnChangeDetail}
                                        data-testid={"changeBuisness"}
                                        fullWidth
                                        MenuProps={{
                                            PaperProps: {
                                              style: {
                                                maxHeight: 200,
                                                width: 250,
                                              },
                                            },
                                          }}
                                        className={this.handleBusinessRole(classes.placeText,classes.blackText)}
                                        >
                                            <MenuItem value="none" disabled style={{display:"none"}}>Select</MenuItem>
                                        {
                                            this.state.businessData?.map((item: any) => (
                                                <MenuItem value={item.id} key={item.id} className={classes.formText}>{item?.attributes?.name}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>

                                <Typography className={classes.labelText}>
                                    Number of Locations
                                </Typography>
                                <Box className={classes.roleBtn_Group}>
                                    {locationMockData.map((item, index: number) => {
                                        return <Button key={index}
                                            className={`${classes.roleBtn} ${this.state.updateDetails?.numberOfLocation === item && classes.selected}`}
                                            data-testid={`noOflocation-${index}`}
                                            onClick={() => this.handleNumberOfLocation(item)}
                                        >
                                            <Typography className={classes.roleBtnText}>{item}</Typography></Button>
                                    })}
                                </Box>

                                <Slider
                                    data-test-id="annualrange"
                                    className={classes.sliderRange}
                                    aria-label="Always visible"
                                    min={0}
                                    max={20000000}
                                    step={2000}
                                    ValueLabelComponent={ValueLabelComponent}
                                    marks={[
                                        {
                                            value: 0,
                                            label: '$0',
                                        },
                                        {
                                            value: 20000000,
                                            label: '$20,000,000',
                                        },
                                    ]}
                                    value={this.state.annualRevenue}
                                    onChange={this.onChangeAnnualRevenue}
                                    valueLabelDisplay="on"
                                />

                                <Box className={classes.actionButtonsMobile}>
                                    <Button variant="contained" data-testid={"openupdatesuccess1"} onClick={this.handleOpenSuccessUpdated} className={classes.updateBtn}>Update</Button>
                                    <Button variant="contained" data-testid={"closeupdatesucess1"} onClick={this.handleCloseDetailsModal} className={classes.cancel_btn}>Cancel</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Dialog>
                <Dialog
                    open={this.state.successUpdated}
                    onClose={this.handleCloseSuccessUpdated}
                    maxWidth="md"
                    PaperProps={{ className: classes.dialogStyleSuccess }}
                    data-testid={"openmodalsuccess"}
                >
                    <img src={closeIcon} alt="closeIcon" style={{ cursor: "pointer", position: "absolute", right: 0 }} height={32} width={32} onClick={this.handleCloseSuccessUpdated} />
                    <Box>
                        <img className={classes.successimg} src={successCheckIcon} alt="success check" />
                        <Typography className={classes.successdesc}>Your account details have been<br />successfully updated</Typography>
                        <Button onClick={this.handleCloseSuccessUpdated} className={classes.backtoaccount}>Back to accounts</Button>
                    </Box>
                </Dialog>
                <Dialog
                    open={this.state.resetPassword}
                    onClose={this.handleCloseResetPasswordDialog}
                    maxWidth="md"
                    PaperProps={{ className: classes.dialogStyleReset }}
                    data-testid={"openmodalreset"}
                >
                    <Box>
                        <Box className={classes.resetheading}>
                            <Typography className={classes.reset}>Reset Password</Typography>
                            <img src={closeIcon} alt="closeIcon" style={{ cursor: "pointer" }} height={32} width={32} onClick={this.handleCloseResetPasswordDialog} />
                        </Box>
                        <Divider style={{ backgroundColor: '#D9D9D9' }} />
                        <Box className={classes.boxreset}>
                            <TextField
                                variant="outlined"
                                type="password"
                                data-testid={"old-password"}
                                placeholder="Old Password"
                                fullWidth
                                name="oldPassword"
                                value={this.state.passwordReset.oldPassword}
                                onChange={this.handleChangeOldPassword}
                                className={`${classes.textfieldName} ${classes.styledTextField} ${classes.styledTextFieldPassword}`}
                                error={this.state.oldpswdError}
                                helperText={this.state.oldpswdErrorText &&
                                    <Box className={classes.errorBoxStyle}>
                                        {<ErrorOutlineOutlinedIcon className={classes.errorIconStyle} color="error" />}
                                        <Typography style={{
                                            color: "#D53333",
                                            fontFamily: "Poppins",
                                            fontSize: "16px",
                                            fontWeight: 400,
                                        }}>
                                            {this.state.oldpswdErrorText}
                                        </Typography>
                                    </Box>
                                }
                            />
                            <TextField
                                variant="outlined"
                                type="password"
                                data-testid={"new-password"}
                                placeholder="New Password"
                                fullWidth
                                name="newPassword"
                                value={this.state.passwordReset.newPassword}
                                onChange={this.handleChangePassword}
                                className={`${classes.textfieldName} ${classes.styledTextField} ${classes.styledTextFieldPassword}`}
                                error={this.state.pswdError}
                                helperText={this.state.pswdErrorText &&
                                    <Box className={classes.errorBoxStyle}>
                                        {<ErrorOutlineOutlinedIcon className={classes.errorIconStyle} color="error" />}
                                        <Typography style={{
                                            color: "#D53333",
                                            fontFamily: "Poppins",
                                            fontSize: "16px",
                                            fontWeight: 400,
                                        }}>
                                            {this.state.pswdErrorText}
                                        </Typography>
                                    </Box>
                                }
                            />
                            <TextField
                                variant="outlined"
                                type="password"
                                data-testid={"confirm-password"}
                                placeholder="Re-enter new password"
                                fullWidth
                                name="confirmPassword"
                                value={this.state.passwordReset.confirmPassword}
                                onChange={this.handleChangePasswordConfirm}
                                className={`${classes.textfieldName} ${classes.styledTextField} ${classes.styledTextFieldPassword}`}
                                error={this.state.confirmError}
                                helperText={this.state.confirmErrorText &&
                                    <Box className={classes.errorBoxStyle}>
                                        {<ErrorOutlineOutlinedIcon className={classes.errorIconStyle} color="error" />}
                                        <Typography style={{
                                            color: "#D53333",
                                            fontFamily: "Poppins",
                                            fontSize: "16px",
                                            fontWeight: 400,
                                        }}>
                                            {this.state.confirmErrorText}
                                        </Typography>
                                    </Box>
                                }
                            />
                            {this.state.newError && <Typography className={classes.successTextColor} >
                                {this.state.newError}
                            </Typography>}
                        </Box>
                        <Box className={classes.resetButtons}>
                            <Button variant="contained"  disabled={this.state.disbaleUpdateBtn} onClick={this.resetPassword} data-testid={"resetpwdSuccess"} className={classes.resetBtn}>Update</Button>
                            <Button onClick={this.handleCloseResetPasswordDialog} variant="outlined" className={classes.resetBtnOutlined}>Cancel</Button>
                        </Box>
                    </Box>
                </Dialog>
                <Dialog
                    open={this.state.successResetPassword}
                    onClose={this.handleCloseSuccessResetPassword}
                    maxWidth="md"
                    PaperProps={{ className: classes.dialogStyleSuccess }}
                    data-testid={"openmodalsuccessResetPassword"}
                >
                    <Box>
                        <Grid item xs={12} className={classes.textGridStyle}>
                            <img src={successCheckIcon} className={classes.imageStyle} />
                        </Grid>
                        <Typography className={classes.successdesc}>Your account password has been<br /> successfully updated</Typography>
                        <Button className={classes.backtoaccount2}
                            onClick={this.handleCloseIcon}
                        >Login again</Button>
                    </Box>
                </Dialog>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

export { Account }
export default withStyles(styles)(Account)

// Customizable Area End
