// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";


// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isLoading: boolean,
    accordionTab: number,
    loadmore: boolean,
    name: any,
    description: any,
    contactData: any,
    aboutAccordion: boolean,
    categoriesAccordion: boolean,
    brandsAccordion: boolean,
    distributorsAccordion: boolean,
    filesAccordion: boolean
    reviewAccordion: boolean,
    contactAccordion: boolean,
    catalogueId: any,
    token: any,
    logo: any,
    categories: any,
    ratingsData: any,
    brands: any,
    distributors: any,
    supplier_files: any,
    product_file: any,
    tabValue: any,
    hideRatingForm: boolean,
    editReview: boolean,
    userdetails: any,
    anchorEl: any
    reviewComment: string,
    ratingVal: number,
    editReviewId: any,
    filterValue: string,
    about: any,
    markets: any
    bookmarked: boolean,
    bookmark_id: any,
    searchBrands:string,
    searchDistributor:string,
    loadmoreDistrbutor:boolean,
    loadmoreBrand:boolean,
    directory_id:any,
    domain_url:string,
    reviewToast:boolean,
    featured:boolean,
    searchTermProp:string,
    searchDirectoryData:any,
    showDirectoryPopup:boolean,
    showMoreCategories:boolean,
    quearySearch:string

    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class PremiumListingSupplierController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getSupplierCatalogueDataApiId: any;
    supplierbookmarkApiId: any;
    premiumpostReviewApiId: any;
    reviewUpdateApiId: any;
    deleteBookmarkApiId: any;
    getSearchResultApiDataReqId:string="";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            name: "",
            description: "",
            isLoading: false,
            accordionTab: 0,
            loadmore: false,
            contactData: {
                "insta": "",
                "mobile": "",
                "address": "",
                "website": ""
            },
            aboutAccordion: true,
            categoriesAccordion: true,
            brandsAccordion: true,
            distributorsAccordion: true,
            filesAccordion: true,
            reviewAccordion: true,
            contactAccordion: true,
            searchBrands:"",
            searchDistributor:"",
            ratingVal: 0,
            catalogueId: null,
            token: null,
            logo: "",
            categories: [],
            ratingsData: [],
            brands: [],
            distributors: [],
            supplier_files: [],
            product_file: [],
            tabValue: 0,
            bookmarked: false,
            hideRatingForm: false,
            editReview: false,
            userdetails: {},
            anchorEl: null,
            reviewComment: "",
            editReviewId: null,
            filterValue: "recent",
            about: { description: "" },
            markets: [],
            bookmark_id: null,
            loadmoreDistrbutor:false,
            loadmoreBrand:false,
            reviewToast:false,
            directory_id:null,
            domain_url:"",
            featured:false,
            searchTermProp:"",
            searchDirectoryData:[],
            showDirectoryPopup:false,
            showMoreCategories:false,
            quearySearch:""
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start

        // Customizable Area End
    }
    // Customizable Area Start
    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        const messageid = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

        if (this.getSupplierCatalogueDataApiId === messageid) {
            this.handleCatalogueData(responseJson, errorReponse)
        }
        else if (this.reviewUpdateApiId === messageid) {
            this.setState({ editReview: false, hideRatingForm: true })
            this.getSupplierCatalogueData(this.state.catalogueId)
        }
        else if (this.premiumpostReviewApiId === messageid) {
            if(responseJson.data && responseJson.data.type=="review"){
                this.setState({ hideRatingForm: true })
                this.getSupplierCatalogueData(this.state.catalogueId)
            }
            else if(responseJson.data && responseJson.data.type=="error"){
                this.setState({reviewToast: true , isLoading: false})
               
            }
            
        }
        else if (this.supplierbookmarkApiId === messageid) {
            this.setState({ isLoading: false })
            this.getSupplierCatalogueData(this.state.catalogueId)
        }
        else if (this.deleteBookmarkApiId === messageid) {
            this.setState({ isLoading:false })
            this.handleBookmarkDeleteResponse(responseJson, errorReponse)
        }
        else if(messageid==this.getSearchResultApiDataReqId){
            this.handleDirectorySearch(responseJson)
            this.setState({isLoading:false})
          }

    }
    async componentDidMount() {

        const id = this.getCatalogueId();
        this.getSupplierCatalogueData(id, this.state.filterValue);
        const token = await getStorageData("loginToken")
        const storedData = await getStorageData("userdetails");
        const accountDetails = JSON.parse(storedData);
        this.setState({ catalogueId: id, token, userdetails: accountDetails })


    }
    getCatalogueId = () => {
        const currentPath = window.location.pathname;
        const pathParts = currentPath.split('/');
        const id = pathParts[pathParts.length - 1];
        return id
    }

    handleBookmarkDeleteResponse = (responseJson: any, errorReponse: any) => {
        if (responseJson && responseJson.success) {
            this.setState({ bookmarked: false })
        }
        else {
            this.parseApiCatchErrorResponse(errorReponse);
        }
    }
    getSupplierCatalogueData = async (id: any, filter?: any) => {
        this.setState({ isLoading: true })
        const token = await getStorageData("loginToken") || ""
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token
        };

        const endPoints = `${configJSON.catalogueAPiEndPoint}/${id}?review_order=${filter ?? this.state.filterValue}`;

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoints
        );

        this.getSupplierCatalogueDataApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

    };
    handleRating = (event: any, val: any) => {
        this.setState({ ratingVal: val })
    }
    handleBookmarkDelete = () => {
            this.setState({isLoading:true})
            const header = {
                "Content-Type": configJSON.validationApiContentType,
                token: this.state.token
            };
            const requestMessageDeleteSupplier = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.deleteBookmarkApiId = requestMessageDeleteSupplier.messageId;

            requestMessageDeleteSupplier.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.bookmarkDeleteEndpoint + `${this.state.bookmark_id}`
            );
            requestMessageDeleteSupplier.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessageDeleteSupplier.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.httpDeleteMethod
            );

            runEngine.sendMessage(requestMessageDeleteSupplier.id, requestMessageDeleteSupplier);
            return true;
        }
     

    colorDetect = (val: number) => {
        if (this.state.tabValue === val) {
            return "#B300ED"
        } else {
            return '#000'
        }
    };

    handleAccordionToggle = (accordion: any) => {
        if (accordion == "aboutAccordion") {
            this.setState({ aboutAccordion: !this.state.aboutAccordion })
        }
        else if (accordion == "categoriesAccordion") {
            this.setState({ categoriesAccordion: !this.state.categoriesAccordion })
        }
        else if (accordion == "brandsAccordion") {
            this.setState({ brandsAccordion: !this.state.brandsAccordion })
        }
        else if (accordion == "distributorsAccordion") {
            this.setState({ distributorsAccordion: !this.state.distributorsAccordion })
        }
        else if (accordion == "filesAccordion") {
            this.setState({ filesAccordion: !this.state.filesAccordion })
        }
        else if (accordion == "reviewAccordion") {
            this.setState({ reviewAccordion: !this.state.reviewAccordion })
        }
        else if (accordion == "contactAccordion") {
            this.setState({ contactAccordion: !this.state.contactAccordion })
        }

    }
    handleReviewComment = (event: any) => {
        this.setState({ reviewComment: event.target?.value })
    }

    handleReviewUpdate = async () => {
        this.setState({ isLoading: true })
        let formdata = new FormData();
        formdata.append("comment", this.state.reviewComment);
        formdata.append("id", this.state.editReviewId);
        formdata.append("rating", JSON.stringify(this.state.ratingVal));
        const header = {
            token: this.state.token
        };

        const supplierreviewMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.reviewUpdateApiId = supplierreviewMessage.messageId;

        supplierreviewMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.reviewUpdateEndPoint}`
        );
        supplierreviewMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putAPIMethod
        );
        supplierreviewMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        supplierreviewMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata

        );


        runEngine.sendMessage(supplierreviewMessage.id, supplierreviewMessage);
        return true;

    }
    handleEditBtn = (data: any) => {
        const { rating, comment, id } = data.attributes
        this.setState({ editReview: !this.state.editReview, reviewComment: comment, ratingVal: rating, editReviewId: id })
    }
    handleFilterClick = (event: any, filter: any) => {
        this.getSupplierCatalogueData(this.state.catalogueId, filter)
        this.setState({ filterValue: filter, anchorEl: null })

    }


    handleCatalogueData = (responseJson: any, errorReponse: any) => {
        if (responseJson && !responseJson.error) {
            const { reviews } = responseJson.data;
            const { logo, name,featured,directory_id,domain_url ,description, markets, review_posted,bookmark_id, bookmarked, contact, categories, brands, distributors, product_file, files } = responseJson.data.catalogue.data.attributes
            this.setState({ isLoading: false,featured,categoriesAccordion:this.checkForLength(categories),aboutAccordion:this.checkForLength(description), distributorsAccordion:this.checkForLength(distributors),brandsAccordion:this.checkForLength(brands),filesAccordion:this.checkForLength(files), directory_id,domain_url, markets,hideRatingForm:review_posted, logo, name, bookmark_id, bookmarked, description, contactData: contact, ratingsData: reviews.data, categories, brands, distributors, supplier_files: files, product_file })
            this.checkContactToggle(contact);
        } else {
            this.parseApiCatchErrorResponse(errorReponse);
        }
    }
    handlechangeSecrah = () =>{

    }

    checkForLength=(items:any)=>{
        return items?.length
    }

    checkContactToggle=(obj:any)=>{
        if(!obj.phonenumber && !obj.address && !obj.contactus) this.setState({contactAccordion:false})
    }
    handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ anchorEl: event.currentTarget })
    };
    handleClose = () => {
        this.setState({ anchorEl: null });
    };
    goToLoginPage = async  () => {
        this.handleSignUp();
       const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message)
    }
    handleSignUp=async ()=>{
        await  setStorageData("component",JSON.stringify({component:"PremiumListingSupplier",id:this.state.catalogueId}))

    }



    handleAccordionTab = (val: any) => {
        if (val == 0) {
            this.setState({ aboutAccordion: true })
        }
        else if (val == 1) {
            this.setState({ categoriesAccordion: true })
        }
        else if (val == 2) {
            this.setState({ brandsAccordion: true })
        }
        else if (val == 3) {
            this.setState({ distributorsAccordion: true })
        }
        else if (val == 4) {
            this.setState({ filesAccordion: true })
        }
        else if (val == 5) {
            this.setState({ reviewAccordion: true })
        }
        else if (val == 6) {
            this.setState({ contactAccordion: true })
        }

    }

    handleBookmarkClick = async () => {
        if(this.state.token){
        this.setState({ isLoading: true })
        const token = await getStorageData("loginToken")
        let formdata = new FormData();
        formdata.append("catalogue_id", this.state.catalogueId);
        formdata.append("directory_id", this.state.directory_id);


        const header = {
            token
        };

        const bookmarkmessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.supplierbookmarkApiId = bookmarkmessage.messageId;

        bookmarkmessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.bookmarkEndPoint}`
        );

        bookmarkmessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        bookmarkmessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );
        bookmarkmessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata

        );


        runEngine.sendMessage(bookmarkmessage.id, bookmarkmessage);

        return true;
        }
        else{
            this.goToLoginPage();
        }
    }

    handleLoadMore = () => {
        this.setState({ loadmore: true })
    }

    handleReviewSubmit = async () => {

        if (this.state.token) {
            this.setState({ isLoading: true })

            let formdata = new FormData();
            formdata.append("catalogue_id", this.state.catalogueId);
            formdata.append("rating", JSON.stringify(this.state.ratingVal));
            formdata.append("comment", this.state.reviewComment);

            const header = {
                token: this.state.token
            };

            const supplierreviewMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.premiumpostReviewApiId = supplierreviewMessage.messageId;

            supplierreviewMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                formdata

            );
            supplierreviewMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            supplierreviewMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.postReviewEndPoint}`
            );
            supplierreviewMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.exampleAPiMethod
            );


            runEngine.sendMessage(supplierreviewMessage.id, supplierreviewMessage);
            return true;

        }
        else {
            this.goToLoginPage()
        }

    }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
        const token = await getStorageData("loginToken")
        const storedData = await getStorageData("userdetails");
        const accountDetails = JSON.parse(storedData);

        if (token != this.state.token && this.state.token == null) {
            this.setState({ token, userdetails: accountDetails },()=>{
                this.getSupplierCatalogueData(this.state.catalogueId)
            })
        }


    }
    handleTabChange = (event: any, newValue: number) => {
        const element:any = window.document.getElementById(`${newValue}-tab`);
        element?.scrollIntoView();
        this.setState({ tabValue: newValue });
        this.handleAccordionTab(newValue)
    };

    handlefilteredBrands = ()=>{
        const data = this.state.brands && this.state.brands.filter((item:any) =>
            item.name.toLowerCase().includes(this.state.searchBrands.toLowerCase())
          )

      return data
    }
    handleSearchBrands = (e: any) => {
        console.log("rnjnjnjnjjn", e.target.value )
        this.setState({ searchBrands: e.target.value })
    }
    handleBrandLoadmore=()=>{
        this.setState({ loadmoreBrand: !this.state.loadmoreBrand })
    }
    handleUserSignout = async () => {
        await removeStorageData("loginToken")
        this.handleNavigation("Home")
    } 

    handlefilteredDistributor = ()=>{

        const data = this.state.distributors && this.state.distributors?.filter((item:any) =>
            item.name.toLowerCase().includes(this.state.searchDistributor?.toLowerCase())
          )
      return data
    }
    handleSearchDistributor = (e: any) => {
        this.setState({ searchDistributor: e.target.value })
    }
    handleNavigation = (page:any) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), page);
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message)
    } 

    handleReviewToast=()=>{
        this.setState({reviewToast:false})
    }


    handleDistributorLoadmore=()=>{
        this.setState({ loadmoreDistrbutor: !this.state.loadmoreDistrbutor })
    }
    navigateToAccount=(user:any) => {
        let userType=user.toLowerCase();
        if (userType == "vendor") {
            this.handleNavigation("VendorAccountDetails")
        }else if(userType == "buyer" ){
            this.handleNavigation("BuyerAccountDetails")      
          }
    }

    handleSubmit=(event:any)=>{
        event.preventDefault();
        this.handleAdvancedSearch("All")
    }
    handleSearchInput = (event:any) =>{  
        this.setState({searchTermProp: event.target.value,showDirectoryPopup:true},()=>this.getSearchResultDirectory())
        if(!event.target.value) this.setState({showDirectoryPopup:false});
      }

      handleDirectorySearch=(responseJson:any)=>{
        if(responseJson && responseJson.response){
          this.setState({isLoading:false,searchDirectoryData:responseJson.response})
        }
      }
    handleAdvancedSearch = (directory:string) => {
        this.setState({showDirectoryPopup:false})
        const msg: Message = new Message(
          getName(MessageEnum.NavigateToAdvancedSearch)
        );
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        msg.addData(getName(MessageEnum.AdvancedSearchTermData), {directory,searchText:this.state.searchTermProp})
        this.send(msg);
    
      }  

      getSearchResultDirectory = () => {
        
        const header = {
          "Content-Type": configJSON.validationApiContentType,
        };

        this.setState({isLoading:true})
    
      const searchRequestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
        this.getSearchResultApiDataReqId = searchRequestMessage.messageId;
        
        searchRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.getSearchResultEndPoint}?query=${this.state.searchTermProp}`);
        searchRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
          );
          searchRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
      );
      runEngine.sendMessage(searchRequestMessage.id, searchRequestMessage);
    
    
      }

      getListingUrl=(id:number,payment_status:string)=>{
        return payment_status=="success"?`/premiumlistingsupplier/${id}`: `/basicListingsupplier/${id}`
      }
      handleDirectoryPopupClose=()=>{
        this.setState({showDirectoryPopup:false})
      }

    // Customizable Area End





}
// Customizable Area End

