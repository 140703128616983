import React from "react";

import {
  Typography,
  Box,
  Avatar,
  AppBar,
  Toolbar,
  InputBase,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemIcon,
  // Customizable Area Start
  withStyles,
  Theme,
  Drawer,
  Hidden,
  IconButton,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { logoImage, searchIcon, downArrow } from './assets';
import AppBarPage from "../../../components/src/AppBar.web";
import MenuIcon from "@material-ui/icons/Menu";





export const styles: any = (theme: Theme) => ({
  rootProfile: {
    flexGrow: 1,
    backgroundColor: '#F6F0FF',
  },

  searchIcon: {
    padding: '10px',
    color: 'black',
    position: 'absolute' as const,
    display: 'flex',
    alignItems: 'center' as const,
    justifyContent: 'center' as const,
    height: '100%'
  },


  gridContainerStyle: {
    display: 'flex',
    gap: 20,
    margin: '2vw 2vw 2vw 0vw',
    [theme.breakpoints.down('sm')]: {
      margin: '2vw 0vw',

    },
  },

  sidebar: {
    backgroundColor: '#FFFFFF',
    width: '23.4vw',
    height: '814px',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: '#FFFFFF',
      width: '100%',
    },
  },
  mainContent: {
    width: '71vw',
    paddingLeft: '7px',

    [theme.breakpoints.down('md')]: {
      width: "70vw",
      paddingLeft: '0',
    },
    [theme.breakpoints.down('sm')]: {
      width: "64vw",
      paddingLeft: '0',
    },
    [theme.breakpoints.down('xs')]: {
      width: "100vw",
      paddingLeft: '0',
    },

  },

  sidebarTopProfile: {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center' as const,
    padding: '20px',
  },
  sidebarAvatarProfile: {
    width: '140px',
    border: "4px solid #F0E5FF",
    background: "#FFF",
    borderRadius: "50%",
    height: '140px',
  },
  welcomeText1Profile: {
    marginTop: '8px',
    fontSize: '18px' as const,
    fontWeight: 500,
    fontFamily: 'Poppins',
    color: '#262424'
  },

  venderTextProfile: {
    fontSize: '14px' as const,
    fontWeight: 400,
    fontFamily: 'Poppins',
    color: '#707070'
  },

  listConStyleProfile: {
    width: '24px',
    height: '24px',
    marginLeft:"14px"
  },

  listTextStyleProfile: {
    "& .MuiListItemText-primary": {
      fontSize: '16px' as const,
      fontWeight: 400,
      fontFamily: 'Poppins',
      color: '#0F172A',
      cursor:"pointer"
    }

  },

  rootDrawerProfile: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    marginTop:"9%",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      gap: "20px",
      width: "100%",

    },
    "@media(max-width: 1200px)": {
      marginTop:"13%",
    },
    "@media(max-width: 1000px)": {
      marginTop:"15%",
    },
    "@media(max-width: 950px)": {
      marginTop:"11%",
    },          
    "@media(max-width: 820px)": {
      marginTop:"13%",
    },
    "@media(max-width: 760px)": {
      marginTop:"16%",
    },
    "@media(max-width: 630px)": {
      marginTop:"20%",
    },
    "@media(max-width: 480px)": {
      marginTop:"30%",
  },
  },

  drawerProfile: {
    [theme.breakpoints.up("sm")]: {
      flexShrink: 0,
    },
  },
  appBarProfile: {
    position: "absolute",
    boxShadow: "none",
    top: "unset",
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      margin: "0",
      height: "0",
    },
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "transparent",
    },
  },
  menuButtonProfile: {
    color: "black",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },

  drawerPaper: {
    width: drawerWidth,
    border: "none",
    position: "unset",
  },
  drawerPaperDesktop: {
    border: "none",
    position: "unset",
    overflowX:"hidden"
  },
  content: {
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      marginTop: "0",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "55px",
    },
  },
  toolbarProfile: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  }
})
// Customizable Area End

import ProfileController, {
  Props, mockListData, drawerWidth,configJSON
} from "./ProfileController.web";
import Account from "./Account.web";
import Review from "./Review.web";
import Bookmark from "./Bookmark.web";
import Notification from "./Notification.web";



class Profile extends ProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderSidebar = (classes: any, selectedOption: any) => {

    return (
    <Box className={classes.sidebar}>
      <Box className={classes.sidebarTopProfile}>
        <Avatar
          alt="Profile Picture"
          src={this.state.Username?.profile_image}
          className={classes.sidebarAvatarProfile}
        />
        <Typography variant="subtitle1" className={classes.welcomeText1Profile}>
          Welcome {this.state.Username?.full_name || configJSON.welcomeName}
        </Typography>
        <Typography variant="subtitle1" className={classes.venderTextProfile}>
          Buyer account
        </Typography>
      </Box>
      <Divider style={{ backgroundColor: '#D9D9D9',marginTop:"0.7rem",marginBottom:'0.7rem' }} />

      <Box component="nav" style={{backgroundColor:"none"}}>
        {mockListData.map((item) => (
          <Box
            key={item.id}
             data-test-id={`listItem${item.id}`}
            onClick={() => this.handleOptionSelect(item.text)}
            style={{
              backgroundColor: selectedOption === item.id ? '#F6F0FF' : '',
              display: "flex", justifyContent: "center",
              alignItems: "center", padding: "1rem", marginBottom: "-2rem"
            }}
            className={`${styless.listItem} ${selectedOption === item.text ? styles.listItemSelected : ''}`}
          >
            <ListItemIcon
            style={{
              color: 'rgba(0, 0, 0, 0.54)',
              display: 'inline-flex',
              minWidth: '18.99px',
              flexShrink: 0,
          }}
            >
              <img src={item.icon} alt={item.text} className={classes.listConStyleProfile} />
            </ListItemIcon>
            <ListItemText primary={item.text}
             onClick={() => this.handleClickBuyer(item.id)}
              style={{
                flex: '1 1 auto',
                minWidth: '0',
                marginTop: '4px',
                marginBottom: '4px',
                backgroundColor: this.state.selectedIdBuyer === item.id ? '#F6F0FF' :  '',
                position: 'relative',
                left: '18px',
                borderRadius:"8px",
                padding:"8px 12px 8px 11.9px",
                cursor:'pointer'
               
           }}
              className={classes.listTextStyleProfile}
            />
          </Box>
        ))}
      </Box>
    </Box>
    )
}
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props
    const { selectedOption } = this.state;


    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Box className={`${classes.rootProfile} profileComponent`}>
          <AppBarPage
            logoImg={logoImage}
            serachIcon={searchIcon}
            username={this.state.Username}
            dummyicon={this.state.Username?.profile_image}
            downarrow={downArrow}
            goToHomePage={this.navigateToHomeScreen}
            handleSubmit={this.handleSubmitSearch}
            showDirectoryPopup={this.state.showDirectoryPopup}
            searchDirectoryData={this.state.searchDirectoryData}
            handleSearchInput={this.handleSearchInput}  
            handleHeaderNavigation={this.navigateToVendor}
            handleUserSignout={this.handleUserSignout}
            handleAdvancedSearch={this.handleAdvancedSearchNavigation}
            data-testId={"signInBtn"}
            handleDirectoryPopupClose={this.handleDirectoryPopupClose}
          />


          <Box className={classes.gridContainerStyle}>
            <div className={classes.rootDrawerProfile}>
              <AppBar className={classes.appBarProfile}>
                <Toolbar className={classes.toolbarProfile}>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={this.handleDrawerToggle}
                    className={classes.menuButtonProfile}
                  >
                    <MenuIcon />
                  </IconButton>
                </Toolbar>
              </AppBar>
              <nav className={classes.drawerProfile} aria-label="mailbox folders">
                <Hidden smUp implementation="css">
                  <Drawer
                    variant="temporary"
                    open={this.state.mobileOpen}
                    onClose={this.handleDrawerToggle}
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                      keepMounted: true,
                    }}
                  >
                    {this.renderSidebar(classes,selectedOption)}
                  </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                  <Drawer
                    classes={{
                      paper: classes.drawerPaperDesktop,
                    }}
                    variant="permanent"
                    open
                  >
                    {this.renderSidebar(classes,selectedOption)}
                  </Drawer>
                </Hidden>
              </nav>

              <main className={classes.content}>

                <Box className={classes.mainContent}>
                  {selectedOption == "Account Details" && <Account navigation={this.props.navigation} id={""} classes={undefined} />}
                  {selectedOption == "Reviews Submitted" && <Review navigation={this.props.navigation} id={""} classes={undefined} />}
                  {selectedOption == "Bookmarks" && <Bookmark navigation={this.props.navigation} id={""} classes={undefined} />}
                  {selectedOption == "Notification Settings" && <Notification navigation={this.props.navigation} id={""} classes={undefined} />}
                </Box>
              </main>
            </div>

          </Box>
        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styless = {
  listItem: {
    backgroundColor: "none", 
    transition: "background-color 0.3s" 
  },
  listItemHovered: {
    backgroundColor: "none"
  },
  listItemSelected: {
    backgroundColor: "none" 
  },
  listItemIcon: {
    color: "rgba(0, 0, 0, 0.54)",
    display: "inline-flex",
    minWidth: "19px",
    flexShrink: 0
  },
  listItemText: {
    flex: "1 1 auto",
    minWidth: "0",
    marginTop: "4px",
    marginBottom: "4px",
    background: "red",
    padding: "4.2px",
    position: "relative",
    left: "14px"
  }
};
export { Profile }
export default withStyles(styles)(Profile)

// Customizable Area End
