// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
export interface ListDataResponse {
    categories:any[];
 }
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isLoading: boolean,
    loadmore: boolean,
    exhibitors: any,
    name: any,
    description: any,
    contactData: any,
    aboutAccordion: boolean,
    exhibitorsOpen: boolean,
    reviewAccordion: boolean,
    contactAccordion: boolean,
    bookmark_id: any,
    logo: any,
    catalogueId: any,
    token: any,
    reviewData: any
    tabValue: number,
    categories: any,
    from: string,
    to: string,
    location_name: string,
    event_type: string,
    hideRatingForm: boolean,
    editReview: boolean,
    reviewComment: string,
    userdetails: any,
    anchorEl: any,
    ratingVal: number,
    domain_url:string
    editReviewId: any,
    moreCategories: boolean,
    directory_id:any,
    filterValue: string,
    bookmarked: boolean,
    errorToast:boolean,
    searchTermProp:string,
    showDirectoryPopup:boolean,
    searchDirectoryData:any,
    searchText:string,
    categoryId:number,
    categoryListData:ListDataResponse[]
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class PremiumListingEventsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getEventsCatalogueDataApiId: any;
    eventsbookmarkApiId: any;
    premiumpostReviewApiId: any;
    reviewUpdateApiId: any;
    deleteBookmarkApiId:any;
    getSearchResultDataReqId:string="";
    getAllCategoryDataIdexhibitor: string="";;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            name: "",
            anchorEl: null,
            description: "",
            exhibitors: [],
            aboutAccordion: true,
            exhibitorsOpen: true,
            isLoading: false,
            loadmore: false,
            reviewAccordion: true,
            contactAccordion: true,
            contactData: {
                "insta": "",
                "mobile": "",
                "address": "",
                "website": ""
            },
            logo: "",
            catalogueId: null,
            token: null,
            reviewData: [],
            tabValue: 0,
            from: "",
            to: "",
            event_type: "",
            categories: [],
            location_name: "",
            hideRatingForm: false,
            editReview: false,
            reviewComment: "",
            userdetails: {},
            ratingVal: 0,
            editReviewId: null,
            moreCategories: false,
            filterValue: "recent",
            bookmarked: false,
            bookmark_id: null,
            directory_id:null,
            errorToast:false,
            domain_url:"",
            searchTermProp:"",
            showDirectoryPopup:false,
            searchDirectoryData:[],
            searchText:"",
            categoryId:-1,
            categoryListData:[]


            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        const messageid = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

        if (this.getEventsCatalogueDataApiId === messageid) {
            this.handleEventsResponse(responseJson, errorReponse)
        }
        else if (this.reviewUpdateApiId === messageid) {
            this.setState({ editReview: false, hideRatingForm: true })
            this.getEventCatalogueData(this.state.catalogueId)
        }
        else if (this.premiumpostReviewApiId === messageid) {
            if(responseJson.data && responseJson.data.type=="review"){
                this.setState({ hideRatingForm: true })
                this.getEventCatalogueData(this.state.catalogueId)
            }
            else if(responseJson.data && responseJson.data.type=="error"){
                this.setState({errorToast: true , isLoading: false})
            }
           
        }
        else if(this.deleteBookmarkApiId===messageid){
            this.setState({ isLoading:false })
            this.handleBookmarkDeleteResponse(responseJson, errorReponse)
        }
        else if (this.eventsbookmarkApiId === messageid) {
            this.setState({ isLoading: false })
            this.getEventCatalogueData(this.state.catalogueId)
        }
        else if(messageid==this.getSearchResultDataReqId){
            this.handleDirectorySearch(responseJson)
            this.setState({isLoading:false})
          }
          else if(messageid==this.getAllCategoryDataIdexhibitor){
            this.handleCategoryListData(responseJson)
        } 
    }
    

    async componentDidMount() {
     
        const id = this.getCatalogueId();
        this.getEventCatalogueData(id, this.state.filterValue);
        const token = await getStorageData("loginToken")
        const storedData = await getStorageData("userdetails");
        const accountDetails = JSON.parse(storedData);
        this.setState({ catalogueId: id, token, userdetails: accountDetails })
        this.getCategoryListDataevent();
    }
    getCatalogueId = () => {
        const currentPath = window.location.pathname;
        const pathParts = currentPath.split('/');
        const id = pathParts[pathParts.length - 1];
        return id
    }
    getEventCatalogueData = async(id: any, filterValue?: any) => {
        this.setState({ isLoading: true })
        const token = await getStorageData("loginToken") || ""
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token
        };

      
        let  endPoints = `${configJSON.catalogueAPiEndPoint}/${id}?review_order=${filterValue ?? this.state.filterValue}`;
        if( this.state.categoryId!=-1) endPoints+=`&category_id=${this.state.categoryId}`;

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoints
        );

        this.getEventsCatalogueDataApiId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );


        runEngine.sendMessage(requestMessage.id, requestMessage);

    };



    
    getCategoryListDataevent = () => {
        const header = {
          "Content-Type": configJSON.validationApiContentType,
        };
    
      const searchRequestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
        this.getAllCategoryDataIdexhibitor = searchRequestMessage.messageId;
        searchRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
          );
          searchRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.exhibitorCatalogueAPiEndPoint}?catalogue_id=${this.state.catalogueId}` );
    
          searchRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
      );
    
      runEngine.sendMessage(searchRequestMessage.id, searchRequestMessage);
      }


    handleExhibitorCategoryFilter=(event: React.ChangeEvent<{ value: unknown }>)=>{
        this.setState({categoryId:event.target?.value as number},()=>this.getEventCatalogueData(this.state.catalogueId))
      }









    handleBookmarkDeleteResponse=(responseJson:any,errorReponse:any)=>{
        if(responseJson && responseJson.success){
            this.setState({bookmarked:false})
        }
        else {
            this.parseApiCatchErrorResponse(errorReponse);
        }
    }


    handleToggle = (accordion: any) => {
        if (accordion == "aboutAccordion") {
            this.setState({ aboutAccordion: !this.state.aboutAccordion })
        }
        else if (accordion == "exhibitorsOpen") {
            this.setState({ exhibitorsOpen: !this.state.exhibitorsOpen })
        }
        else if (accordion == "reviewAccordion") {
            this.setState({ reviewAccordion: !this.state.reviewAccordion })
        }
        else if (accordion == "contactAccordion") {
            this.setState({ contactAccordion: !this.state.contactAccordion })
        }

    }
    handleEventsResponse = (responseJson: any, errorReponse: any) => {
        if (responseJson && !responseJson.error) {
            const { reviews } = responseJson.data;
            if(responseJson.data.catalogue.data.attributes){
                const { logo,directory_id,domain_url, name, contact, location_name, event_type, bookmarked, bookmark_id,
                    exhibitors, categories, from, to, description ,review_posted} = responseJson.data.catalogue.data.attributes
                this.setState({
                    isLoading: false,directory_id, domain_url,hideRatingForm:review_posted, event_type, bookmarked, bookmark_id,
                    exhibitors, categories, location_name, logo, from, to, description, name, contactData: contact, reviewData: reviews.data
                })
                if(!contact.phonenumber && !contact.address && contact.contactus)  return this.setState({contactAccordion:false})

            }
            

        } else {
            this.parseApiCatchErrorResponse(errorReponse);
        }
    }
    handleTabChange = (event: any, newValue: number) => {
        const element:any = window.document.getElementById(`${newValue}-tab`);
        element?.scrollIntoView();
        this.setState({ tabValue: newValue });
        this.handleAccordionTab(newValue)
    };
    handleFilterClick = (event: any, filter: any) => {
        this.setState({ filterValue: filter, anchorEl: null })
        this.getEventCatalogueData(this.state.catalogueId, filter)
    }
    handleLoadMore = () => {
        this.setState({ loadmore: true })
    }
    handleRating = (event: any, val: any) => {
        this.setState({ ratingVal: val })
    }

    detectColor = (val: number) => {
        if (this.state.tabValue === val) {
            return "#B300ED"
        } else {
            return '#000'
        }
    };
    handleAccordionTab = (val: any) => {
        if (val == 0) {
            this.setState({ aboutAccordion: true })
        }
        else if (val == 1) {
            this.setState({ exhibitorsOpen: true })
        }
        else if (val == 2) {
            this.setState({ reviewAccordion: true })
        }
        else if (val == 3) {
            this.setState({ contactAccordion: true })
        }

    }

    handleBookmarkEvent = async () => {
        if(this.state.token){
        this.setState({ isLoading: true })
        const token = await getStorageData("loginToken")
        let formdata = new FormData();
        formdata.append("catalogue_id", this.state.catalogueId);
        formdata.append("directory_id", this.state.directory_id);


        const header = {
            token
        };

        const bookmarkmessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.eventsbookmarkApiId = bookmarkmessage.messageId;

        bookmarkmessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.bookmarkEndPoint}`
        );
        bookmarkmessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata

        );
        bookmarkmessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        bookmarkmessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );

        runEngine.sendMessage(bookmarkmessage.id, bookmarkmessage);

        return true;
        }
        else{
            this.goToLoginPage()
        }
    }
    handleEditBtn = (data: any) => {
        const { rating, comment, id } = data.attributes
        this.setState({ editReview: !this.state.editReview, reviewComment: comment, ratingVal: rating, editReviewId: id })
    }


    handleReviewSubmit = async () => {

        if (this.state.token) {
            this.setState({ isLoading: true })

            let formdata = new FormData();
            formdata.append("catalogue_id", this.state.catalogueId);
            formdata.append("rating", JSON.stringify(this.state.ratingVal));
            formdata.append("comment", this.state.reviewComment);

            const header = {
                token: this.state.token
            };

            const eventsreviewMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.premiumpostReviewApiId = eventsreviewMessage.messageId;

            eventsreviewMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                formdata

            );
            eventsreviewMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.exampleAPiMethod
            );
            eventsreviewMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.postReviewEndPoint}`
            );

            eventsreviewMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );


            runEngine.sendMessage(eventsreviewMessage.id, eventsreviewMessage);
            return true;

        }
        else {
            this.goToLoginPage()
        }

    }

    goToLoginPage = async  () => {
        this.handleSignUp();
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message)
    }
    handleSignUp=async()=>{
        await setStorageData("component",JSON.stringify({component:"PremiumListingEvents",id:this.state.catalogueId}))

}
    
    handleReviewComment = (event: any) => {
        this.setState({ reviewComment: event.target?.value })
    }
    handleClose = () => {
        this.setState({ anchorEl: null });
    };
    handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ anchorEl: event.currentTarget })
    };
    handleReviewUpdate = async () => {
        this.setState({ isLoading: true })
        let formdata = new FormData();
        formdata.append("id", this.state.editReviewId);
        formdata.append("rating", JSON.stringify(this.state.ratingVal));
        formdata.append("comment", this.state.reviewComment);
        const header = {
            token: this.state.token
        };

        const eventsreviewMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.reviewUpdateApiId = eventsreviewMessage.messageId;

        eventsreviewMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.reviewUpdateEndPoint}`
        );
        eventsreviewMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        eventsreviewMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putAPIMethod
        );
        eventsreviewMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata

        );


        runEngine.sendMessage(eventsreviewMessage.id, eventsreviewMessage);
        return true;

    }
    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
        const token = await getStorageData("loginToken")
        const storedData = await getStorageData("userdetails");
        const accountDetails = JSON.parse(storedData);

           this.handleUpdate(token,accountDetails);
           
        


    }
    handleUpdate=(token:any,accountDetails:any)=>{
        if (token != this.state.token && this.state.token == null) {
            this.setState({ token, userdetails: accountDetails },()=>{
                this.getEventCatalogueData(this.state.catalogueId)
            })
         }
        
    }
    handleNavigation = (page:any) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), page);
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message)
    }  

    navigateToAccount=(userType:any) => {
       let accountData=userType.toLowerCase();
        if (accountData == "vendor") {
            this.handleNavigation("VendorAccountDetails")
        }else if(accountData == "buyer" ){
            this.handleNavigation("BuyerAccountDetails")      
          }
    }
    handleBookmarkDelete= () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
          token: this.state.token
        };
        const requestMessageDeleteApiEvents = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.deleteBookmarkApiId = requestMessageDeleteApiEvents.messageId;
    
        requestMessageDeleteApiEvents.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.bookmarkDeleteEndpoint+ `${this.state.bookmark_id}`
        );
        requestMessageDeleteApiEvents.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessageDeleteApiEvents.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.httpDeleteMethod
        );
    
        runEngine.sendMessage(requestMessageDeleteApiEvents.id, requestMessageDeleteApiEvents);
        return true;
      };
    handleUserSignout = async () => {
        await removeStorageData("loginToken")
        this.handleNavigation("Home")
    }  

    handleErrorAlert=()=>{
        this.setState({errorToast:false})
    }
    handleSubmit=(event:any)=>{
        event.preventDefault();
        this.handleAdvancedSearch("All")
    }
    handleSearchInput = (event:any) =>{  
        this.setState({searchTermProp: event.target.value,showDirectoryPopup:true},()=>this.getSearchResultDirectory())
        if(!event.target.value) this.setState({showDirectoryPopup:false});
      }
    handleAdvancedSearch = (directory:string) => {
        this.setState({showDirectoryPopup:false})
        const msg: Message = new Message(
          getName(MessageEnum.NavigateToAdvancedSearch)
        );
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        msg.addData(getName(MessageEnum.AdvancedSearchTermData), {directory,searchText:this.state.searchTermProp})
        this.send(msg);
    
      }  

      getSearchResultDirectory = () => {
        this.setState({isLoading:true})
        const header = {
          "Content-Type": configJSON.validationApiContentType,
        };
    
      const searchdataRequestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
        this.getSearchResultDataReqId = searchdataRequestMessage.messageId;
          searchdataRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
          );
          searchdataRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.getSearchResultEndPoint}?query=${this.state.searchTermProp}`);
    
      searchdataRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
      );
    
      runEngine.sendMessage(searchdataRequestMessage.id, searchdataRequestMessage);
    
    
      }  

      handleDirectorySearch=(responseJson:any)=>{
        if(responseJson && responseJson.response){
          this.setState({isLoading:false,searchDirectoryData:responseJson.response})
        }
      }
      handleDirectoryPopupClose=()=>{
        this.setState({showDirectoryPopup:false})
      }
      handleSearchTextevent = (e: any) => {
        this.setState({ searchText: e.target?.value })
    }

      handlefilteredData = ()=>{
        
        const data=this.state.exhibitors && this.state.exhibitors.filter((item:any) =>
        item.name.toLowerCase().includes(this.state.searchText?.toLowerCase())
      );
    
      return data
    }
    getListingUrl2=(id:number,payment_status:string)=>{
        return payment_status=="success"?`/premiumlisting/${id}`: `/basicListing/${id}`
      }
      handleCategoryListData=(responseJson:ListDataResponse)=>{
        if(responseJson){
            this.setState({categoryListData:responseJson.categories})
        }
      }
      triggredFeatureList = (event:any) =>{
        this.setState({ anchorEl: event.currentTarget });
      }
    
      
    // Customizable Area End





}
// Customizable Area End

