import React from "react";

// Customizable Area Start
import {
    Box,
    Typography,
    MenuItem,
    Select,
    Chip,
    Button,
    Checkbox,
    Grid,
    TextField,
    FormControl,
    Popper,
    Paper
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CustomSaveButton from '../../../components/src/CustomSaveButton.web';
import CustomBackButton from '../../../components/src/CustomBackButton.web';
import Autocomplete from '@material-ui/lab/Autocomplete';

// Customizable Area End

// Customizable Area Start
import ServicesServicePageController, {
    Props,
    configJSON,
} from "./ServicesServicePageController";
import { deleteIcon, deleteIcon_1 } from "./assets";
import FeedbackForm from "./FeedbackForm.web";

import CustomToast from "../../../components/src/CustomToast.web";
import clsx from 'clsx';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
// Customizable Area End

export class ServicesServicePage extends ServicesServicePageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes }: any = this.props
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box>
                <Box className={`${this.state.categoryListNew.length > 1 && classes.categoryBoxSupplier}`}>
                    <Typography className={classes.serviceTypo}>Categories</Typography>
                    {this.state.categoryListNew.map((item: any, index: number) => {

                        return (
                            <div key={index}>
                                <Box className={classes.servicelabelContainer}>
                                    <Typography className={classes.servicelabel}>Category {index + 1}</Typography>
                                    <Button disableRipple className={classes.serviceRemoveBtn} onClick={() => this.removeCategoryNew(index)} data-test-id={`removeCategory-${index + 1}`}><img src={deleteIcon} alt="deleteicon" /></Button>
                                </Box>
                                <FormControl style={{ width: '100%' }}>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        aria-placeholder="select Category"
                                        id="demo-simple-select"
                                        key={index}
                                        data-test-id={`selectCategory-${index + 1}`}
                                        className={classes.ServiceSelect}
                                        placeholder={"select category"}
                                        value={item.category.category_name}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        disableUnderline
                                        onChange={(event) => this.selectCategoryDynamic(event, index)}

                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 300,
                                                    width: 250,
                                                },
                                            },
                                            getContentAnchorEl: null,
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left",
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left",
                                            },
                                            PopoverClasses: {
                                                root: classes.popoverRoot,
                                                paper: classes.popoverPaper,
                                            },
                                            className: classes.scrollbar,
                                        }}
                                    >
                                        <MenuItem disabled value={""}><span style={{ color: "#737373ad" }}>Select</span></MenuItem>
                                        {this.state.categoryListName.map((item) => {
                                            return <MenuItem
                                                key={item.category_id}
                                                value={item.category_name}
                                            >
                                                {item.category_name}

                                            </MenuItem>
                                        })}

                                    </Select>
                                </FormControl>
                                <Typography className={classes.featureServiceTypo}>Service groups</Typography>
                                <Typography className={classes.paraService}>Add service groups to the box below by clicking in the white area. You can select more than one service groups at the same time. You can also type characters to reduce the list. Please select one or more categories first.</Typography>

                                <Autocomplete
                                    multiple
                                    id="tags-filled"
                                    options={item.option}
                                    getOptionLabel={(option) => option.sub_category_name}
                                    data-test-id={`autocomplete-${index + 1}`}
                                    value={item.feature}
                                    disableClearable={true}
                                    onChange={(event, newValue) => this.onAutoCompleteChange(event, newValue, index)}
                                    className={classes.autocompleteService}
                                    renderOption={(option, { selected }) => (
                                        <React.Fragment>
                                            <Checkbox
                                                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                                                icon={<span className={classes.icon_style_2} />}
                                                style={{
                                                    color: selected ? "#B300ED" : '#64748B',
                                                    marginRight: 8
                                                }}
                                                checked={selected}
                                            />
                                            <span className={classes.placeholder_1}>{option.sub_category_name}</span>
                                        </React.Fragment>
                                    )}
                                    renderTags={(value: readonly string[], getTagProps) =>
                                        value.map((option: any, index: number) => (
                                            <Chip variant="outlined"
                                                key={index} label={option.sub_category_name}
                                                {...getTagProps({ index })}
                                                className={classes.chipService}
                                                style={{ fontSize: '14px', fontFamily: "Poppins" }}
                                                deleteIcon={<img src={deleteIcon_1} style={{ height: '20px', width: '20px' }} />}
                                            />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            placeholder={!item.feature.length ? "Service Groups" : ""}
                                            InputProps={{ ...params.InputProps, disableUnderline: true }}
                                        />
                                    )}

                                />
                            </div>
                        )
                    })}

                    <Box className={classes.serviceBtnWrap}>
                        <Button className={classes.addCategoryBtnService} onClick={this.addCategory} data-test-id="add-category"><AddCircleOutlineOutlinedIcon className={classes.adddListIcon} />Add more Category</Button>
                    </Box>
                </Box>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12} className={classes.buttonBoxMargin}>
                        <Grid item container >
                            <Grid item xs={12} sm={12} md={7} lg={7} className={classes.feedBackText}>
                                <Typography className={classes.fontStyle_2}>{configJSON.needHelp}</Typography>
                                <Typography className={classes.textFontStyle}>{configJSON.no}</Typography>
                                <Typography className={classes.textFontStyle} onClick={this.handleOpenFeedbackFrom}>{configJSON.yes}</Typography>
                            </Grid>

                            <Grid item xs={12} sm={12} md={5} lg={5} className={classes.feedBackText}>
                                <CustomBackButton data-testid="navigatetoaccount" label={configJSON.backBtn} handleClick={this.goToVendorAccount} />
                                <CustomSaveButton label={configJSON.saveBtn} data-testid={"save_button"} handleClick={this.updateCategoryListData} />
                            </Grid>
                        </Grid>
                    </Grid>

                    <CustomToast data-testid="gotoaccount" open={this.state.isSucessModalOpen} onClose={this.handleCloseSuccess} />
                    <FeedbackForm openFeedback={this.state.isFeedbackFormOpen} closeFeedback={this.handleCloseFeedbackForm} navigation={this.props.navigation} id={""} />
                </Grid >
            </Box>
        );
        // Customizable Area End
    }
}
// Customizable Area Start

export const userStyles: any = () => ({
    buttonBoxMargin: {
        marginTop: '10%',
        marginBottom: '5%'
    },

    textFontStyle: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#6200EA',
        fontFamily: 'Poppins',
        cursor: "pointer"

    },
    icon: {
        borderRadius: 6,
        width: 20,
        height: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        border: '1px solid #64748B',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#8833FF',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 20,
            height: 20,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#B300ED',
        },
    },
    font_2: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#737373',
        fontFamily: 'Poppins'
    },

    scrollbar: {
        '&::-webkit-scrollbar': {
            width: '10px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#B300ED',
            borderRadius: '5px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#B300ED',
        },
    },

    icon_style_2: {
        borderRadius: 6,
        width: 20,
        height: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        border: '1px solid #8833FF',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },

    feedBackText: {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        justifyContent: "end",
        marginTop: '5%'
    },

    fontStyle_2: {
        fontSize: '14px',
        fontWeight: 500,
        color: '#2D2E2F',
        fontFamily: 'Poppins'
    },

    outlinedInput_2: {
        '&$focused $notchedOutline': {
            borderColor: 'none',
        },
        '&:hover $notchedOutline': {
            borderColor: 'none',
        },
        backgroundColor: 'transparent',
    },
    focused: {},
    notchedOutline_1: {
        border: 'none',
    },
    placeholder_1: {
        fontWeight: 400,
        fontSize: "14px",
        borderRadius: '8px',
        fontFamily: "Poppins",
    },

    inputText_1: {
        fontWeight: 400,
        fontSize: "16px",
        borderRadius: '8px',
        fontFamily: "Poppins",
        color: '#000000'
    },

    ServiceSelect: {
        width: "100%",
        fontFamily: "Poppins",
        backgroundColor: "#f6f0ff",
        borderRadius: "6px",
        color: "#0F172A",
        fontWeight: 400,
        lineHeight: "20px",
        padding: "10px 8px",
        fontSize: "14px",
        border: "1px solid  #CBD5E1",
        borderBottom: "1px solid  #CBD5E1",

        '& .MuiSelect-icon': {
            marginRight: "10px",
        },
        '&:before': {
            borderBottom: "1px solid  #CBD5E1",
        },
        '&:after': {
            borderBottom: 'none',
            border: "1px solid  #CBD5E1",
        },
    },

    featureServiceTypo: {
        fontFamily: "Poppins",
        marginTop: "28px",
        color: "#262424",
        fontWeight: 600,
        lineHeight: "24px",
        marginBottom: "2px",
        fontSize: "14px",
    },
    paraService: {
        fontFamily: "Poppins",
        color: "#262424",
        fontSize: "14px",
        lineHeight: "24px",
        marginBottom: "6px",
        fontWeight: 400,

    },
    autocompleteService: {
        padding: "6px 0",
        borderRadius: '8px',
        border: "1px solid  #CBD5E1",
        marginBottom: "42px",
        backgroundColor: "#f6f0ff",
        "& .MuiAutocomplete-inputFocused": {
            paddingLeft: "10px"
        },
        '&:before': {
            borderBottom: "none",
            display: "none"
        },
        '& .MuiAutocomplete-inputRoot-251[class*="MuiFilledInput-root"]': {
            padding: "0",
            background: "transparent"
        },
        '& .MuiChip-deleteIcon-309': {
            color: "#2A0066",
        },
        '& .MuiInputBase-fullWidth': {
            flexWrap: "wrap",
        },
        "& input::placeholder": {
            paddingLeft: "10px",
            fontWeight: 400,
            fontSize: "14",
            fontFamily: "Poppins",
        }

    },
    chipService: {
        border: "none",
        margin: "0 3px",
    },
    serviceBtnWrap: {
        display: "flex",
        marginTop: "39px",
        justifyContent: "end",
    },
    addCategoryBtnService: {
        height: "44px",
        padding: "10px 16px",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        borderRadius: "8px",
        background: "#B300ED",
        textTransform: "none",
        color: "white",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 400,
        textAlign: "center",
        lineHeight: "50.956px",
        "&:hover": {
            background: "#B300ED",
        }
    },
    adddListIcon: {
        height: "24px",
        width: "24px",
        marginRight: "5px"
    },
    servicelabel: {
        fontFamily: "Poppins",
        color: "#2D2E2F",
        fontWeight: 600,
        lineHeight: "20px",
        fontSize: "14px",
    },
    servicelabelContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "12px",
    },
    serviceTypo: {
        fontFamily: "Poppins",
        color: "#2D2E2F",
        fontSize: "14px",
        lineHeight: "20px",
        marginBottom: "24.3px",
        fontWeight: 700,
        marginTop: "28px",
    },
    serviceRemoveBtn: {
        cursor: "pointer",
        all: "unset",
    },
    categoryBoxSupplier: {
        overflowX: "hidden",
        height: "400px",
        paddingRight: '30px',
        overflowY: "auto",
        "&::-webkit-scrollbar-thumb:focus": {
            background: "#B300ED",
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "#D6D6D6",
        },
        "&::-webkit-scrollbar-corner": {
            backgroundColor: "#B300ED",
        },
        "&::-webkit-scrollbar-thumb:active": {
            background: "#B300ED",
        },
        "&::-webkit-scrollbar-thumb:hover": {
            background: "#B300ED",
        },

        "&::-webkit-scrollbar": {
            padding: '31px',
            height: 6,
            width: 4,
            backgroundColor: "#D6D6D6",
        },
        "&::-webkit-scrollbar-thumb": {
            minWidth: 14,
            background: "#B300ED",
            minHeight: 24,
            borderRadius: 6,
        },

    },

});

export default withStyles(userStyles)(ServicesServicePage)
// Customizable Area End