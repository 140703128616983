import React from "react";

// Customizable Area Start
import {
    Box, Button, Dialog, Grid, OutlinedInput, TextField, Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
// Customizable Area End

// Customizable Area Start
import ClaimListingPageController, {
    Props, configJSON,
} from "./ClaimListingPageController";
import { logoImage, searchIcon, dummyIcon, downArrow, closeIcon, rightTickIcon } from "./assets";
import AppBarPage from "../../../components/src/AppBar.web";
// Customizable Area End

export class ClaimListingPage extends ClaimListingPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes }: any = this.props
        // Customizable Area End
        return (
            // Customizable Area Start
            // Required for all blocks
            <Box className={classes.root}>
                <AppBarPage
                    logoImg={logoImage}
                    serachIcon={searchIcon}
                    username={this.state.Username}
                    dummyicon={dummyIcon}
                    downarrow={downArrow}
                    showDirectoryPopup={this.state.showDirectoryPopup}
                    searchDirectoryData={this.state.searchDirectoryData}
                    handleSearchInput={this.handleSearchInput}        
                    goToHomePage={this.navigateToHomeScreen}
                    handleHeaderNavigation={this.navigateToVendor}
                    handleAdvancedSearch={this.handleAdvancedSearchNavigation}
                    handleSubmit={this.handleSubmitSearch}
                    handleUserSignout={this.handleUserSignout}
                    data-testId={"signInBtn"}
                    handleDirectoryPopupClose={this.handleDirectoryPopupClose}
                />

                <Box className={classes.mainBoxStyle}>
                    <Box className={classes.headingBoxStyle}>
                        <Typography className={classes.headingStyle1}>{configJSON.welcomeClaimText}</Typography>
                        <Typography className={classes.headingStyle2}>{configJSON.welcomeClaimText2}</Typography>
                    </Box>
                    <Box className={classes.textFeildBox}>
                        <Typography className={classes.fontStyle1}>{configJSON.tobeginWithText}</Typography>
                        <StyledTextField
                            variant="outlined"
                            placeholder={configJSON.emailText}
                            value={this.state.changeDomainEmail}
                            onChange={this.handleChangeEmail}
                            data-testid={"change_email"}
                            fullWidth
                            InputProps={{
                                disableUnderline: true,
                                classes: { input: classes["inputStyle"] },
                            }}
                            className={classes.nameTextfeild}
                        />
                        <Button
                            variant="contained"
                            fullWidth
                            className={classes.validateButton}
                            onClick={this.claimListingAPI}
                            data-testId={"validateEmail"}
                        >
                            {this.state.isLoading && (
                                <Box className={classes.spinnerContainer}>
                                    <span style={{ marginLeft: '8px' }}>{configJSON.validating}</span>
                                    <Box className={classes.spinner} />
                                </Box>
                            )}
                            {!this.state.isLoading && configJSON.validate}
                        </Button>
                    </Box>

                    {
                        this.state.isLoading ?
                            <Box className={classes.textBoxStyle2}>
                                <Typography className={classes.fontStyle3}>{configJSON.verifyEmailText}</Typography>
                            </Box>
                            :
                            <Box className={classes.textBoxStyle}>
                                <Typography className={classes.fontStyle2}>{configJSON.paymentClaimingText}</Typography>
                            </Box>
                    }

                </Box>

                {/* Sucess Dialog Box */}
                <Dialog
                    open={this.state.isSucessModal}
                    data-testid={"openSucess_modal"}
                    maxWidth="md"
                    PaperProps={{ className: classes.dialogStyles }}
                >

                    <Box className={classes.crossIconBox}>
                        <Box className={classes.iconBox}>
                            <img
                                data-testid={"closeSucessModal"}
                                src={closeIcon}
                                onClick={this.handleCloseSucessModal}
                                className={classes.closeIconStyle}
                            />
                        </Box>

                        <Box className={classes.dialogBoxStyle}>
                            <img
                                src={rightTickIcon}
                                className={classes.rightTickIcon}
                            />

                            <Typography className={classes.greatText}>{configJSON.greatNews}</Typography>
                            <Typography className={classes.textStyle2}>{this.state.sucessMessage}</Typography>
                            <Button
                                variant="contained"
                                fullWidth
                                className={classes.buttonStyle}
                                onClick={this.goToCheckOutPage}
                                data-testId={"proceedButton"}
                            >
                                {configJSON.proceed}
                            </Button>
                        </Box>
                    </Box>
                </Dialog>

                {/* Error Dialog Box */}
                <Dialog
                    open={this.state.isErrorModal}
                    data-testid={"errorModal"}
                    maxWidth="md"
                    PaperProps={{ className: classes.dialogStyles }}
                >

                    <Box className={classes.crossIconBox}>
                        <Box className={classes.iconBox}>
                            <img
                                data-testid={"closeErrorModal"}
                                src={closeIcon}
                                onClick={this.handleCloseErrorModal}
                                className={classes.closeIconStyle}
                            />
                        </Box>

                        <Box className={classes.dialogBoxStyle_3}>
                            <Typography className={classes.greatText}>{configJSON.oopsText}</Typography>
                            <Typography className={classes.textStyle2}>{this.state.errorMessage}</Typography>
                            <Button
                                variant="contained"
                                fullWidth
                                className={classes.buttonStyle}
                                onClick={this.handleOpenFeedbackModal}
                                data-testid={"continueBtn"}
                            >
                                {configJSON.continue}
                            </Button>
                        </Box>
                    </Box>
                </Dialog>

                {/* Query Dialog Box */}
                <Dialog
                    open={this.state.isFeedback}
                    data-testid={"openFeedbackModal"}
                    maxWidth="md"
                    PaperProps={{ className: classes.dialogStyles }}
                >

                    <Box className={classes.crossIconBox}>
                        <Box className={classes.iconBox}>
                            <img
                                data-testid={"closeFeedbackModal"}
                                src={closeIcon}
                                onClick={this.handleCloseFeedbackModal}
                                className={classes.closeIconStyle}
                            />
                        </Box>

                        <Box className={classes.dialogBoxStyle_3}>
                            <Typography className={classes.greatText}>{configJSON.noworries}</Typography>
                            <Typography className={classes.textStyle2}>{configJSON.providemoreinfor}</Typography>
                            <OutlinedInput
                                id="outlined-adornment-weight"
                                data-testid={'changeDescription'}
                                rows={4}
                                value={this.state.shortDescription}
                                onChange={this.handleChangeDescription}
                                multiline
                                inputProps={{
                                    placeholder: configJSON.shortDescription,
                                    className: classes.about_placeholder,
                                    'aria-label': 'address',
                                }}
                                classes={{
                                    input: classes.about_inputText,
                                    root: classes.about_outlinedInput,
                                    focused: classes.focused,
                                    notchedOutline: classes.about_notchedOutline,
                                }}
                            />
                            <Button
                                variant="contained"
                                fullWidth
                                className={classes.buttonStyle}
                                onClick={this.submitQueryDescription}
                                data-testid={"submitQuery"}
                            >
                                {configJSON.continue}
                            </Button>
                        </Box>
                    </Box>
                </Dialog>

                <Dialog open={this.state.isModalOpen}
                        maxWidth="md"
                        PaperProps={{ className: classes.dialogStyles }}
                        onClose={()=>this.setState({isModalOpen:false})}
                        data-testId={"openModal"}
                    >
                        <Grid container>
                            <Grid item xs={12} className={classes.cancelGridStyle}>
                                <img
                                    data-testid={"closeModal"}
                                    src={closeIcon}
                                    onClick={()=>this.setState({isModalOpen:false})}
                                    className={classes.closeIconStyle}
                                />
                            </Grid>
                        </Grid>

                        <Box className={classes.dialogBoxStyle}>
                            <img
                                src={rightTickIcon}
                                className={classes.rightTickIcon}
                            />
                            <Typography className={classes.welcomeFontStyle}>{configJSON.congratulation}</Typography>
                            <Typography className={classes.welcomeFontStyle2}>{configJSON.addListingScuccess}</Typography>
                            <Button
                                variant="contained"
                                fullWidth
                                className={classes.buttonStyle}
                                data-testid={"gotoaccount"}
                                onClick={this.navigatevendorAccount}
                            >
                                {configJSON.backtoaccounts}
                            </Button>
                        </Box>
                    </Dialog>
            </Box >
        );
        // Customizable Area End
    }
}
// Customizable Area Start

export const StyledTextField = withStyles({
    root: {
        fontSize: "14",
        "& .MuiOutlinedInput-root": {
            fontSize: "14",
            fontWeight: 400,
            fontFamily: "Poppins",
            borderRadius: '8px'
        },
        "& input::placeholder": {
            fontSize: "14",
            fontWeight: 400,
            fontFamily: "Poppins"
        },
        "& .MuiInputBase-input-283:focus": {
            outline: 'none',
            backgroundColor: "transparent",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#DE76FF",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#DE76FF"
        },
        "&  .MuiFormHelperText-root.Mui-error": {
            paddingLeft: 10,
            backgroundColor: "#FFFFFF",
            margin: 0,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#DDDDDD",
        },
    },
})((props: any) => <TextField {...props} />);

export const userStyles = () => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#f8f2ff',
    },

    mainBoxStyle: {
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#FFFFFF',
        margin: '5%',
        marginTop:"10%",
        gap: '30px'
    },

    headingBoxStyle: {
        marginTop: '2%'
    },

    headingStyle1: {
        fontSize: '26px',
        color: '#262424',
        fontWeight: 700,
        fontFamily: 'Poppins',
        textAlign: 'center' as const,
        lineHeight: '43px'
    },

    headingStyle2: {
        fontSize: '18px',
        color: '#707070',
        fontWeight: 500,
        fontFamily: 'Poppins',
        textAlign: 'center' as const,
        lineHeight: '43px'
    },

    fontStyle1: {
        fontSize: '14px',
        color: '#000000',
        fontWeight: 400,
        fontFamily: 'Poppins',
        textAlign: 'center' as const,
        lineHeight: '43px'
    },

    inputStyle: {
        fontFamily: "Poppins",
        "&::placeholder": {
            fontFamily: "Poppins",
        },
    },

    crossIconBox: {
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center'
    },

    iconBox: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end'
    },

    nameTextfeild: {
        width: "115%",
        borderRadius: '8px',
        backgroundColor: '#FAF5FF',
    },

    textFeildBox: {
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px'
    },

    validateButton: {
        fontSize: "16px",
        fontWeight: 700,
        backgroundColor: "#B300ED",
        fontFamily: "Inter",
        color: "#FFFFFF",
        textTransform: 'initial' as const,
        borderRadius: '8px',
        padding: '16px',
        width: '115%',
        marginTop: '5%',
        '&:hover': {
            backgroundColor: "#B300ED",
        }
    },

    fontStyle2: {
        fontSize: '16px',
        color: '#707070',
        fontWeight: 500,
        fontFamily: 'Poppins',
        textAlign: 'center' as const,
        lineHeight: '35px'
    },

    textBoxStyle: {
        width: '58%',
        border: '1px solid #EEB8FF',
        borderRadius: '8px',
        marginTop: '2%',
        marginBottom: '2.5%',
        padding: '15px 5% 15px 5%',
    },

    textBoxStyle2: {
        width: '58%',
        marginBottom: '2%'
    },

    fontStyle3: {
        fontSize: '16px',
        color: '#000000',
        fontWeight: 400,
        fontFamily: 'Poppins',
        textAlign: 'center' as const,
        lineHeight: '24px'
    },

    dialogStyles: {
        overflow: 'hidden',
        justifyContent: 'center',
        width: '550px',
        borderRadius: '8px 8px 32px 8px',
        backgroundColor: ' #FFFFFF',
        display: 'flex',
    },

    cancelGridStyle: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },

    closeIconStyle: {
        cursor: 'pointer',
        height: '35px',
        width: '35px',
        padding: '8px 5px 0px 0px'
    },

    dialogBoxStyle: {
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
        marginBottom: '40px',
        paddingLeft: '40px',
        paddingRight: '40px'
    },

    dialogBoxStyle_3: {
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        alignItems: 'center',
        gap: '40px',
        marginBottom: '40px',
        paddingLeft: '40px',
        paddingRight: '40px'
    },

    dialogBoxStyle2: {
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
        marginBottom: '40px',
        padding: '27px'
    },

    rightTickIcon: {
        height: '62px',
        width: '62px'
    },

    buttonStyle: {
        fontSize: '16px' as const,
        fontWeight: 400,
        fontFamily: 'Poppins',
        color: '#FFFFFF',
        backgroundColor: '#B300ED',
        textTransform: 'initial' as const,
        borderRadius: '8px',
        padding: '8px 32px 8px 32px',
        width: '368',
        height: '56px',
        '&:hover': {
            backgroundColor: "#B300ED",
        }
    },
    

    buttonStyle2: {
        fontSize: '16px' as const,
        fontWeight: 400,
        fontFamily: 'Poppins',
        color: '#FFFFFF',
        backgroundColor: '#B300ED',
        textTransform: 'initial' as const,
        borderRadius: '8px',
        padding: '8px 32px 8px 32px',
        width: '60%',
        '&:hover': {
            backgroundColor: "#B300ED",
        }
    },

    greatText: {
        fontSize: '24px',
        fontWeight: 700,
        fontFamily: 'Poppins',
        lineHeight: '32px',
        textAlign: 'center' as const,
        color: '#0F172A'
    },

    textStyle2: {
        fontSize: '16px',
        fontWeight: 500,
        fontFamily: 'Poppins',
        lineHeight: '32px',
        textAlign: 'center' as const,
        color: '#707070'
    },

    oopsTextStyle: {
        fontSize: '28px',
        fontWeight: 700,
        fontFamily: 'Poppins',
        lineHeight: '32px',
        textAlign: 'center' as const,
        color: '#0F172A'
    },

    textStyle3: {
        fontSize: '16px',
        fontWeight: 500,
        fontFamily: 'Poppins',
        lineHeight: '32px',
        textAlign: 'center' as const,
        color: '#707070'
    },

    spinner: {
        transform: 'translate(-50%, -50%)',
        width: 24,
        height: 24,
        border: '3px solid transparent',
        borderTop: '3px solid #FFFFFF',
        borderRadius: '50%',
        animation: '$spin 0.8s linear infinite',
    },
    '@keyframes spin': {
        '0%': { transform: 'rotate(0deg)' },
        '100%': { transform: 'rotate(360deg)' },
    },
    spinnerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: "space-between"
    },

    about_outlinedInput: {
        '&:hover $notchedOutline': {
            borderColor: '#DE76FF',
        },
        '&$focused $notchedOutline': {
            borderColor: '#DE76FF',
        },
        backgroundColor: '#FAF5FF',
        width: '100%',
        borderRadius: '8px',
    },
    focused: {},
    about_notchedOutline: {
        borderWidth: '1px',
    },
    about_placeholder: {
        fontWeight: 400,
        borderRadius: '8px',
        fontSize: "14px",
        fontFamily: "Poppins",
    },

    welcomeFontStyle: {
        fontSize: '24px',
        fontWeight: 700,
        fontFamily: 'Inter',
        lineHeight: '32px',
        textAlign: 'center' as const,
        color: '#0F172A'
    },

    welcomeFontStyle2: {
        fontSize: '18px',
        fontWeight: 400,
        fontFamily: 'Inter',
        lineHeight: '26px',
        textAlign: 'center' as const,
        color: '#0F172A'
    },
    about_inputText: {
        fontWeight: 400,
        borderRadius: '8px',
        fontSize: "16px",
        fontFamily: "Poppins",
        color: '#000000'
    },
    
});

export default withStyles(userStyles)(ClaimListingPage)
// Customizable Area End